import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password";

import PasswordReset from "./pages/password-reset";

import Users from './pages/user-list';
import UserRegistration from './pages/user-registration';
import UserImport from './pages/user-import';
import UserEdit from './pages/user-edit';
import UserEditClient from './pages/user-edit-client';

import Hirers from './pages/hirer-list';
import HirerRegistration from './pages/hirer-registration';
import HirerEdit from './pages/hirer-edit';
import HirersConfigs from './pages/hirer-configs-list'
import HirerConfigRegistration from './pages/hirer-config-registration'
import HirerConfigEdit from './pages/hirer-config-edit'

import EnvironmentRegistration from './pages/enviroment'

import ContractEdit from './pages/contract-edit'
import ContractRegistration from './pages/contract-registration';

import Customers from './pages/customer-list';
import CustomerRegistration from './pages/customer-registration';
import CustomerEdit from './pages/customer-edit';

import Roles from './pages/role-list';
import RoleRegistration from './pages/role-registration';
import RoleEdit from './pages/role-edit';
import Permissions from './pages/permission-list';
import PermissionRegistration from './pages/permission-registration';
import PermissionEdit from './pages/permission-edit';

import accountConfirmation from './pages/account-confirmation';

import Configs from './pages/config-list';
import ConfigRegistration from './pages/config-registration';
import ConfigEdit from './pages/config-edit';

import Dashboard from './pages/dashboard'
import ActiveCustomer from './pages/active-customer'

import Servers from './pages/server-list';
import ServerRegistration from './pages/server-registration';
import ServerEdit from './pages/server-edit';


import predictTraining from './pages/predict-training-list';
import predictEquipment from './pages/predict-equipment-list';
import predictImage from './pages/predict-image-list';
import predictUrl from './pages/predict-url-list';


import predictRetraining from './pages/predict-retraining-list';
import predictRetrainingEdit from './pages/predict-retraining-edit';

import Page404 from './pages/page404/page404';
import { LANGUAGES } from './_i18n/languages';

import RequestNumberMessaging from './pages/request-number-messaging';
import RequestNumberMessagingEdit from './pages/request-number-messaging-edit';
import HeaderMenu from './components/header-menu';


const MultiLanguageRoute = (props) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const hasLang = props.computedMatch.params.lang;
  const url = props.computedMatch.url;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === '/';

  var currentUrlLang = window.location.pathname.split('/')[1];
  var currentLanguage = LANGUAGES[currentUrlLang];
  if (!currentLanguage) {
    currentLanguage = LANGUAGES[LANGUAGES.default];
  }


  if (isBasePathWithoutLang) return <Redirect to={`/${defaultLanguage}/login`} />
  if (!hasLang && !is404Page) //return <Redirect to={`/${defaultLanguage}/login`} />

    var path = `${url}`;
  if (hasLang == url || '/' + hasLang == url) {
    path = `/${currentLanguage.urlLang}${url}`;
  }
  return <Route language={currentLanguage.urlLang} {...props} />
}

export default function Routes() {
  return (
    <BrowserRouter forceRefresh={false}>
      <Switch>
        <MultiLanguageRoute path="/" exact component={Login} />
        <MultiLanguageRoute path="/:lang/" exact component={Login} />
        <MultiLanguageRoute path="/:lang/login" exact component={Login} />
        <MultiLanguageRoute path="/:lang/forgot-password" component={ForgotPassword} />
        <MultiLanguageRoute path="/:lang/user-registration" component={UserRegistration} />
        <MultiLanguageRoute path="/:lang/user-import" component={UserImport} />
        <MultiLanguageRoute path="/:lang/password-reset/:token?" component={PasswordReset} />
        <MultiLanguageRoute path="/:lang/users" component={Users} />
        <MultiLanguageRoute path="/:lang/home" component={Dashboard} />

        <MultiLanguageRoute path="/:lang/active-customer" component={ActiveCustomer} />

        <MultiLanguageRoute path='/:lang/servers' component={Servers} />
        <MultiLanguageRoute path='/:lang/server-registration' component={ServerRegistration} />
        <MultiLanguageRoute path='/:lang/server-edit' component={ServerEdit} />

        <MultiLanguageRoute path='/:lang/configs' component={Configs} />
        <MultiLanguageRoute path='/:lang/config-registration' component={ConfigRegistration} />
        <MultiLanguageRoute path='/:lang/config-edit' component={ConfigEdit} />

        <MultiLanguageRoute path='/:lang/users' component={Users} />
        <MultiLanguageRoute path='/:lang/user-registration' component={UserRegistration} />
        <MultiLanguageRoute path='/:lang/user-import' component={UserImport} />
        <MultiLanguageRoute path='/:lang/user-edit' component={UserEdit} />
        <MultiLanguageRoute path='/:lang/user-edit-client' component={UserEditClient} />

        <MultiLanguageRoute path='/:lang/hirers' component={Hirers} />
        <MultiLanguageRoute path='/:lang/hirer-registration' component={HirerRegistration} />
        <MultiLanguageRoute path='/:lang/hirer-edit' component={HirerEdit} />

        <MultiLanguageRoute path='/:lang/hirers-config' component={HirersConfigs} />
        <MultiLanguageRoute path='/:lang/hirer-config-registration' component={HirerConfigRegistration} />
        <MultiLanguageRoute path='/:lang/hirer-config-edit' component={HirerConfigEdit} />

        <MultiLanguageRoute path='/:lang/contract-edit' component={ContractEdit} />
        <MultiLanguageRoute path='/:lang/contract-registration' component={ContractRegistration} />

        <MultiLanguageRoute path='/:lang/environment-registration' component={EnvironmentRegistration} />

        <MultiLanguageRoute path='/:lang/customers' component={Customers} />
        <MultiLanguageRoute path='/:lang/customer-registration' component={CustomerRegistration} />
        <MultiLanguageRoute path='/:lang/customer-edit' component={CustomerEdit} />


        <MultiLanguageRoute path='/:lang/roles' component={Roles} />
        <MultiLanguageRoute path='/:lang/role-registration' component={RoleRegistration} />
        <MultiLanguageRoute path='/:lang/role-edit' component={RoleEdit} />
        <MultiLanguageRoute path='/:lang/permissions' component={Permissions} />
        <MultiLanguageRoute path='/:lang/permission-registration' component={PermissionRegistration} />
        <MultiLanguageRoute path='/:lang/permission-edit' component={PermissionEdit} />

        <MultiLanguageRoute path='/:lang/predict-training' component={predictTraining} />
        <MultiLanguageRoute path='/:lang/predict-equipment' component={predictEquipment} />
        <MultiLanguageRoute path='/:lang/predict-image' component={predictImage} />
        <MultiLanguageRoute path='/:lang/predict-retraining' component={predictRetraining} />
        <MultiLanguageRoute path='/:lang/predict-retraining-edit' component={predictRetrainingEdit} />

        <MultiLanguageRoute path='/:lang/requestnumbermessaging' component={RequestNumberMessaging} />
        <MultiLanguageRoute path='/:lang/requestnumbermessaging-edit' component={RequestNumberMessagingEdit} />

        <MultiLanguageRoute path='/:lang/predict-url' component={predictUrl} />


        <MultiLanguageRoute path='/:lang/account-confirmation/:token' component={accountConfirmation} />

        <MultiLanguageRoute path="*" component={Page404} />

      </Switch>
    </BrowserRouter>
  );
}
