import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CheckCircle, Error, Info, Close, Warning } from "@material-ui/icons";
import { amber, green } from "@material-ui/core/colors";
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const {
    handleRedirection,
    toMove,
    message,
    onClose,
    variant,
    ...other
  } = props;
  const Icon = variantIcon[variant];

  if (toMove != "" && toMove != undefined) {
    const handleRedirectionConst = () => {
      handleRedirection();
    };

    return (
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <div>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => handleRedirectionConst()}
            >
              Verificar
            </Button>
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <Close className={classes.icon} />
            </IconButton>
          </div>,
        ]}
        {...other}
      />
    );
  } else {
    return (
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <Close className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
}

MySnackbarContentWrapper.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
  toMove: PropTypes.string,
  handleRedirection: PropTypes.func,
};

export default function CustomizedSnackbars(props) {
  const {
    variant,
    message,
    isOpen,
    toClose,
    toMove,
    handleRedirection,
  } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    toClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
          toMove={toMove}
          handleRedirection={handleRedirection}
        />
      </Snackbar>
    </div>
  );
}

CustomizedSnackbars.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  toClose: PropTypes.func,
  toMove: PropTypes.string,
  handleRedirection: PropTypes.func,
};
