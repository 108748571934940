import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  CssBaseline,
  Grid,
  CircularProgress,
  Container,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import * as Service from "../../services/hirerConfig.service"
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Steps from "./components/steps"
import FormDialog from "../../components/form-dialog";

class EnvironmentRegistration extends Component {
  constructor(props) {
    super(props)

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    })
    this.state = {
      hirerData: {},
      contractData: {},
      hirerConfigData: {},
      contractid: 0,
      hirerid: 0,
      rootPassword: "",
      rootName: "postgres",
      domain: {},
      cloudProviderConfig: {},
      domain: {},
      openDialog: false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      daysexpire: 30,
      finishmsg: "Aguarde...",
    };

    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.setError = this.setError.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  changeValues(stateName, value) {
    this.setState({
      [stateName]: value,
    })
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  formatDate(date) {
    const parts = date.split('/')
    const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`
    return newDate
  }

  handleSubmit = async (e) => {
    
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: "success",
      notificationMessage: this.props.intl.formatMessage({ id: "add.success" })
    })
    //window.location.reload()
    let contract = JSON.parse(JSON.stringify(this.state.contractData))
    contract.contractplanperiodicities = contract.contractplanperiodicities.filter(cpp => cpp.planperiodicity.checked == true)
    contract.startdate = this.formatDate(contract.startdate)
    contract.enddate = this.formatDate(contract.enddate)
    contract.billingstartdate = this.formatDate(contract.billingstartdate)

    this.setState({ loading: true })
    const result = await Service.createEnvironment({
      hirer: this.state.hirerData,
      contract: contract,
      hirerConfig: this.state.hirerConfigData,
      rootUserName: this.state.rootName,
      rootPassword: this.state.rootPassword,
      domain: this.state.domain,
      cloudProviderConfig: this.state.cloudProviderConfig
    })
    this.setState({loading: false})
    if (result.success) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "success",
        notificationMessage: this.props.intl.formatMessage({ id: "add.success" })
      })
      window.location.reload()
    } else {
      this.setError(result.errors ? result.errors[0] : this.props.intl.formatMessage({ id: "process.error" }))
    }
  }

  setError(error) {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: "error",
      notificationMessage: error
    })
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  render() {
    const { classes, headerMenu, intl } = this.props

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}

        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
              </Grid>
              <Steps state={this.state} intl={intl} classes={classes}
                changeValues={this.changeValues} setError={this.setError}
              />
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
            <FormDialog
              title={intl.formatMessage({ id: "finnish" })} text={intl.formatMessage({ id: "root.password" })}
              handleClose={() => this.changeValues("openDialog", false)}
              handleSubmit={this.handleSubmit} open={this.state.openDialog}
            >
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <TextField
                    label="Root DB Name"
                    name="rootName"
                    fullWidth
                    variant="outlined"
                    value={this.state.rootName}
                    onChange={(e) => this.changeValues(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    label="Root DB Password"
                    type="password"
                    name="rootPassword"
                    fullWidth
                    variant="outlined"
                    value={this.state.rootPassword}
                    onChange={(e) => this.changeValues(e.target.name, e.target.value)}
                  />
                </Grid>
              </Grid>
            </FormDialog>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
EnvironmentRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(EnvironmentRegistration))
)