import Api from './api';

export const getTimeZones = async () => { 
    let data = [];
    await Api.get('/config/timezone')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  export const getTimeZone = async (id) => { 
    let data = [];
    await Api.get('/config/timezone/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getFormatDates = async () => { 
    let data = [];
    await Api.get('/config/formatdate')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const testMail = async (email) => { 
    let data = [];
    await Api.get('/config/testmail/' + email)
        .then(result => {
            data = result
         })
         .catch(err => {
            data = err;
    });
    return data;
  };