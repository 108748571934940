import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core/';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box variant="fullWidth" p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function Panels(props) {
  return (
    props.components.map((component, i) => {
      return (<TabPanel key={i} value={props.value} index={i}>{component}</TabPanel>)
    })
  );
}

function LinkTabs(props) {
  const classes = useStyles()
  var rows = [];
  var numrows = props.components.length;
  for (var i = 0; i < numrows; i++) {
    if (props.components[i].props.displayname) {
      var name = props.components[i].props.displayname;
    }
    else if (props.components[i].type.Naked) {
      var name = props.components[i].type.Naked.displayname;
    }
    else {
      var name = props.components[i].type.displayname.replace("withRouter(Connect(WithStyles(", "").replace(")", "").replace(")", "").replace(")", "");
    }
    if (props.icons) {
      rows.push((<LinkTab className={props.value == i ? classes.selectedPanel : classes.tabPanel} icon={props.icons[i]} key={i} label={name} href={"/" + i} {...a11yProps(i)} />));
    }
    else {
      rows.push((<LinkTab className={props.value == i ? classes.selectedPanel : classes.tabPanel} key={i} label={name} href={"/" + i} {...a11yProps(i)} />));
    }
  }
  return (
    <Tabs
      variant='fullWidth'
      TabIndicatorProps={{
        style: { backgroundColor: "white", height: 2 }
      }}
      value={props.value}
      onChange={props.handleChange}
      aria-label={props.label}
    >
      {rows}
    </Tabs>
  );

}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'theme.palette.background.paper',
  },
  tabs: {
    background: "none",
    boxShadow: "none",
    alignItems:"flex-start",
    // marginLeft: 24,
    border: "solid",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderWidth: 1,
    borderColor: theme.palette.tabs.border,
  },
  selectedPanel: {
    color: theme.palette.tabs.text,
    border: "solid",
    borderBottom: "none",
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.tabs.border,
  },
  tabPanel: {
    color: "black",
  }
}));

export default function NavTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const { label, components, icons, changeSelect } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.tabs} style={{marginLeft: props.full ? 0 : 24}}>
        <LinkTabs handleChange={handleChange} label={label} value={value} components={components} icons={icons} />
      </AppBar>
      <Panels value={value} components={components} />
    </div>
  );
}
NavTabs.propTypes = {
  label: PropTypes.string,
  components: PropTypes.any.isRequired,
};
