import React from 'react';
import LogoMarca from '../../images/logo-cmtech-small-2.png';

const LogoSmall2 = props => (
    <div>
        <img src={LogoMarca} title="Logomarca" />
    </div>
);

export default LogoSmall2;
