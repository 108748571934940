import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { QueuePlayNext, DeleteForever } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import SimpleCheckBox from "../../components/checkbox/check";
import MyCheckList from "../../components/checklist/checklist";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as Service from "../../services/hirer.service";
import * as componentActions from "../../redux/actions/server";
import { FormattedMessage, injectIntl } from "react-intl";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
class ServerEdit extends Component {
  constructor(props) {
    super(props);
    const { userSession, servers } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    console.log(this.props)

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });
    

    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: servers.server.id,
      name: servers.server.name,
      port: servers.server.port,
      serverip: servers.server.serverip,
      user: servers.server.user,
      applicationid: servers.server.serverapplications ? servers.server.serverapplications.application : [],
      servertypeid: servers.server.servertype ? servers.server.servertype : [],
    };

    this.state = {
      item: this.initialState,
      hirerList: [],
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      error: false,
      errorMessage: {},
      applicationList: [],
      servertypeList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.changeSimpleAutocompleteValues = this.changeSimpleAutocompleteValues.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    
    await this.getApplication();
    await this.getServerType();
  }

  async getApplication() {
    const { userSession } = this.props;

    this.setState({ loading: true });
    await Api.get('/servers/application')
      .then(result => {
          this.setState({
            loading: false,
            applicationList: result.data.data
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "erro",
            notificationMessage: "Erro ao processar solicitação",
          });
      });
  }

  async getServerType() {
    const { userSession } = this.props;

    this.setState({ loading: true });
    await Api.get('/servers/servertypes')
      .then(result => {
        this.setState({
          servertypeList: result.data.data,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "erro",
          notificationMessage: "Erro ao processar solicitação",
        });
    });
  }
  handleInputChange(e) {
    const intl = this.props.intl;
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("applicationid") > -1 || 
          key
          .toString()
          .toLowerCase()
          .indexOf("servertypeid") > -1
      ) {
        data.append(key, this.state.item[key].id);
      }else {
        data.append(key, this.state.item[key]);
      }
      
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    Api.put("/servers", data, config)

      .then((result) => {
        if (result.data.success) {
          const formattedList = result.data.data;
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
          });
          //this.componentDidMount();
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  handleClick = async (e) => {
    this.setState({ loading: true });
    const intl = this.props.intl;

    const { userSession, customers } = this.props;

    let data = new FormData();
    data.append("customeremail", customers.customer.email);

    Api.put("customers/enviaremail", data)
      .then((response) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Email Enviado",
          item: this.initialState,
          files: [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : "Erro ao processar a solicitação.",
        });
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }



  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value.id,
      },
    }));
    this.setState({ loading: false });
  }
  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  async changeSimpleAutocompleteValues(e, v) {
    this.setState({ loading: true });
    const name = e;
    const value = v.id;

    this.setState(prevState => ({
      customer: {
        ...prevState.customer,
        [name]: value
      }
    }));

    this.setState({ loading: false });
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;

    const intl = this.props.intl;   
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="IP"
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="serverip"
                      onChange={this.handleInputChange}
                      value={item.serverip}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Usuário"
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="user"
                      onChange={this.handleInputChange}
                      value={item.user}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Porta"
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="port"
                      onChange={this.handleInputChange}
                      value={item.port}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label="Aplicação"
                      options={this.state.applicationList}
                      name='applicationid' 
                      stateName='applicationid'
                      changeSelect={this.changeValues}
                      selected={this.state.item.applicationid}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label="Tipo"
                      options={this.state.servertypeList}
                      name='servertypeid'
                      stateName='servertypeid'
                      changeSelect={this.changeValues}
                      selected={this.state.item.servertypeid}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4} justify="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
ServerEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  servers: state.servers
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ServerEdit))
);
