export default function hirerconfig(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT_HIRERCONFIG':
            return {
                ...state,
                hirerconfig: action.hirerconfig,
            }
        default:
            return state;
    }
}