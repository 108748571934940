import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MyMaterialTable from '../../components/table';
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/users';
import Footer from '../../components/footer';
import { FormattedMessage,injectIntl } from 'react-intl';

import Pagination from '@material-ui/lab/Pagination';  

import { getImagensCountPredict } from '../../services/predict';
import Api from '../../services/api';

class PredictImageList extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;


        const { userSession } = this.props;
        const intl = this.props.intl;

     
        
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            resultList: [],
            file : null,

            totalPages:1,
            page:1,
            pageStart:1,
            pageEnd:1,
            count:0,
            searchValue: '',
            filterSelected: 0,
        };
        var globalAdm = false;
        this.AdmOrganization = false;
        this.userId = userSession.user.id;
        userSession.user.userrole.map((item, key) => {
            if(item.role.roletype ==0){
                globalAdm =true;
            }
            if(item.role.roletype ==1 && globalAdm == false){
                this.AdmOrganization =true;
            }
        });


        this.closeNotification = this.closeNotification.bind(this);

        
        this.columns = [
            { title: 'Quantidade de Marcas/Modelos', field: 'labels' },
            { title: 'Descrição', field: 'message' },
            { title: 'Total de Imagens Testadas', field: 'test_images' },
            { title: 'Total de Imagens Treinadas', field: 'train_images' },
            { title: 'Total de Imagens', field: 'total_images' },
          ];
  
    }

    componentDidMount(){
        this.getItens();
    }

    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
        this.getItens();
    }
   
    setPagination(count,page){
                            
                            let totalPage = Math.ceil (count/10);
                            this.setState({ totalPages:totalPage })
                            if(count > 0){
                                if(page == 1){
                                    if(count < 10){
                                        this.setState({pageStart:1,pageEnd:count});
                                    } else{
                                        this.setState({pageStart:1,pageEnd:10});
                                    }
                                } else{
                                    let pageStart = ((page - 1) * 10) + 1;
                                    
                                    
                                    
                                    if(count >= page * 10){
                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                    } else{
                                        this.setState({pageStart:pageStart,pageEnd:count});
                                    }
                                }
                            } else {
                                this.setState({totalPages:0,page:1,count:0});
                            }
                            
    }
    async getItens(page = 1) {
        this.setState({ loading: true });
        document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: auto !important');
  
        const result = await getImagensCountPredict();
        console.log(result)
        if(result && result.data && result.data.success){
          if(result.data.data){
            let itens = [];
            itens.push(result.data.data);
  
            let count = itens.length;
            this.setState({count:count});
            this.setPagination(count,page);

            this.setState({
              loading: false,
              resultList: itens,
            });
          }else{
            this.setState({
              loading: false
            });
          }
          
        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "Erro ao processar solicitação",
          });
          Api.kickoff(result);
        }
  
    };

    

    closeNotification() {
        this.setState({ openNotification: false });
    }


    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        return (
            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                    
                                >
                                    <div className={classes.drawerHeader} />

                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                        <CssBaseline />
                                        <Grid container className={classes.mb20}  spacing={0}>
                            
                        </Grid>
                        
                        <MyMaterialTable
                            title=""
                            columns={this.columns}
                            data={this.state.resultList}
                            rowClick={(event, rowData) => {
                                
                            }}
                        />
                     

                        
                                            
                                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                                {/*
                                                <Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>
                                                */}
                                                <Grid item xs={12} sm={3} style={{marginTop:6, marginLeft:10}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.count} {<FormattedMessage id="of" />}  {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                                            </Grid>

                                            
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
               <Footer /> 
            </div>
        );
    }
}

PredictImageList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(PredictImageList)));

PredictImageList.contextType = AbilityContext;
