export default function servers(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT_SERVER':
            return {
                ...state,
                server: action.server,
            }
        default:
            return state;
    }
}
