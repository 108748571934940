import Api from './api';

export const getFile = async (filename,filerepositorymethod,hirerid) => {
	let data = [];
	await Api.get(`/registrationphonenumber/file/${hirerid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };