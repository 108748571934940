import React from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { Link, withRouter } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import { AbilityContext } from './config/ability-context'
import ability from './config/ability'
import { Chart } from 'react-chartjs-2';
import chartjs from './helpers/chartjs';
import theme from './styles/theme';
import { browserHistory } from './helpers/history';
import IntlProviderConfigured from './_i18n/intlproviderconfigured';
import './paginate.css';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});


function App() {

    return (
        <AbilityContext.Provider value={ability}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <Router history={browserHistory}>
                            <IntlProviderConfigured>
                                <Routes />
                            </IntlProviderConfigured>
                        </Router>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </AbilityContext.Provider>

    );
}

export default App;
//export default withRouter(App);
