import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@material-ui/core';
import { QueuePlayNext, DeleteForever, Label } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import MyTransferList from '../../components/transfer-list/transferlist';
import MyCheckList from '../../components/checklist/checklist';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';

import SimpleAutoComplete from '../../components/auto-complete/autocomplete-group';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import SimpleCheckBox from '../../components/checkbox/check';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { saveAs } from 'file-saver';
import { Input } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

                    import * as Service from '../../services/user.service';
class UserImport extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      Organizations:  0,
     Groups: 0,
     OrganizationText: '',
     GroupText: '',
     name : '', 
     email : '', 
     active : false, 
     phone : '', 
     UserRoles : [],
     WorkHourId: 0,
     WorkHourText: '',
     OrganizationDepartamentId: 0
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      GroupList: [],
      RoleList: [],
      WorkHoursList: [],
      DepartamentList: [],
      departamentName: '',
      file: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.importModelFile = this.importModelFile.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  componentDidMount() {
    //this.getOrganizations();
    this.getRoles();
    this.getWorkHours();
    this.getAllGrous();
    

  
  }

  async getAllGrous(){

    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });

  }

  async getAllOrganizations(ids){

    if(ids.length > 0){
      this.setState({ loading: true });
      let urlids = ids.map(item => item.id);
      //this.setState({ loading: true });
      
      var orgs = await Service.getOrganizationsByGroup(urlids);
      orgs = orgs.map((org) =>{

        return {
          ...org,
          name: `( ${org.group.name} ) ${org.name}`
        }

      })
      this.setState({ loading: false, OrganizationList: orgs });
      //this.setState({ loading: false, GroupList: groups });
    }
    }

    async getAllDepartaments(ids){

      if(ids.length > 0){
        this.setState({ loading: true });
        let urlids = ids.map(item => item.id);
        //this.setState({ loading: true });
        
        var depts = await Service.getDepartaments(urlids);
        depts = depts.map((dept) =>{
  
          return {
            ...dept,
            parentname: dept.name,
            order: dept.organization.name
          }
  
        })

        this.setState({ loading: false, DepartamentList: depts });
      }
    }
  

    


  async getWorkHours(){
    this.setState({ loading: true });
    

    var result = await Service.getWorkHoursList();
      if (result.success) {
        this.setState({ loading: false, WorkHoursList: result.data });
                      
      } else {
        
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        
        Api.kickoff(result);

      }
  };


  onFormSubmit = async e => {

    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();


      //data.append(key, this.state.item[key]);
    if(this.state.file != null){

        data.append('file',this.state.file)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

        Api.post('/users/planilhas', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                resultList: []
              });

          }
          else{

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }          
        })
        .catch(err => {
          if(err.response && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response
            });
          }
        });

    } else {

        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"excel"})
            });

    }
      
        
  };

  importModelFile(){
        
    this.setState({ loading: true });
        var url = '/users/importfile';

        Api.get(url,{
            responseType: 'blob'
        })
    .then((response) => {

        let url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, 'modelo.xlsx');
      this.setState({ loading: false });
   });
}

  async getDepartament(orgId){
    this.setState({ loading: true });
    var result = await Service.getDepartamentList(orgId);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
                    
    } else {
      
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      
      Api.kickoff(result);

    }
  };

  async getOrganizations(){
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
      if (result.success) {
        this.setState({ loading: false, OrganizationList: result.data });
                      
      } else {
        
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        
        Api.kickoff(result);

      }
};

async getRoles(){
  this.setState({ loading: true });
  var result = await Service.getRoles();
    if (result.success) {
      this.setState({ loading: false, RoleList: result.data });
                    
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
};

handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    
    if(e.target.name === 'phone'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['phone']: this.mtel(value),
        }
      }));
    }

  };

  onChangeFile(e) {
    this.setState({file:e.target.files[0]})
  }

  handleSubmit = async e => {

    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }
      


      //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.item['email'])) { 
      //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
      //  return;
      //}
      if(this.state.item.UserRoles == null || this.state.item.UserRoles == ''){

        const intl = this.props.intl;
        this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:intl.formatMessage({id:"selected.group"})});
        return;
      }
      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        }
        else if (key.toString().toLowerCase().indexOf('userroles') > -1) {
          //console.log(this.state.item[key]); 
          /*
          var UserRoles = [];
          this.state.item[key].map(item => {

            var Role = {
              Id : item
             };
             var UserRole = {
              Role : Role
             };
             UserRoles.push(UserRole);
            });
            var datapost = {
              UserRoles
            };
            console.log(datapost); 
            */ 
            data.append(key, this.state.item[key]);
          }else if (key.toString().toLowerCase().indexOf('phone') > -1){
            data.append(key, this.state.item[key].replace(/\D/gim, ''));
          } else {
            if(key === 'Organizations'){
              let idsString = "";
              if(this.state.item.Organizations.length > 0){
                this.state.item.Organizations.forEach( organization => idsString += organization.id + ","); 
              }
              

            var newStr = idsString.slice(0, -1)
            data.append(key,newStr);
            } else {
              data.append(key, this.state.item[key]);
            }
          
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'dataType': 'json'
        }
      };
        Api.post('/users', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });
            this.getOrganizations();
            this.getRoles();
            this.getWorkHours();

          }
          else{

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }          
        })
        .catch(err => {
          if(err.response && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response
            });
          }
        });
  };
  
  mtel(v){
    v=v.replace(/\D/g,"");
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2");
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        


    if(stateName === 'OrganizationId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationText']: text,
          Organizations:value,
          OrganizationDepartamentId: 0
        },
        departamentName: ''
      }));
      this.setState({ DepartamentList:[] });
      await this.getAllDepartaments(value);
      
      //this.getDepartament(value);
    }

    if(stateName === 'GroupId'){
      this.setState({ DepartamentList:[] });
      this.setState({ OrganizationList:[] });
      await this.getAllOrganizations(value);
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['GroupText']: text,
          Groups:value,
          Organizations:[],
          OrganizationId:[],
          OrganizationDepartamentId: 0
        },
        departamentName: ''
      }));
    }

    if(stateName === 'WorkHourId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['WorkHourText']: text,
        }
    }));
    }

    this.setState({ loading: false });
 

  };
  getRolesIds(){
        const idList = this.state.RoleList.map(item => {
          return item.id
      });
      return idList;
     
  }
  getRolesNames(){
    const idList = this.state.RoleList.map(item => {
      return item.name
  });
  return idList;
 
  }  
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    
  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4} >
                
                
                </Grid>
              </Grid>
            
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="create.lote" />}
              </Typography>

              <form name='myForm' className={classes.form} style={{maxWidth:500}} onSubmit={this.onFormSubmit}>
                                 <Button
                                        variant="contained"
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        
                                        className={classes.submit}
                                        onClick={this.importModelFile}
                                        style={{marginBottom:150}}
                                    >
                                        {<FormattedMessage id="import.plan" />}
                                    </Button> 


                                        
                                        <Input type="file" id="files" onChange={this.onChangeFile} />
                                        <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        
                                        className={classes.submit}
                                        >
                                        {<FormattedMessage id="export.plan" />}
                                        </Button>

                                        
                                    </form>
                                    

              
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
UserImport.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(UserImport))); 
