import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
    TextField,
    Grid,
    Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../../../styles/material-styles";
import { connect } from "react-redux";
import * as componentActions from "../../../../redux/actions/requestnumber";
import { injectIntl } from "react-intl";
//import AttachFileIcon from '@mui/icons-material/AttachFile';
import {  AttachFile } from '@mui/icons-material';
import Link from "@mui/material/Link";

import { QueuePlayNext } from "@material-ui/icons";






class CompanyData extends Component {

    constructor(props) {
        super(props);

        const { requestnumber } = this.props;
        console.log("props provider")
        console.log(this.props)
        this.state = {
            requestnumber: requestnumber.requestnumber,


        };


    }


    componentDidMount() {
    }

    handleFile(fileName, filerepositorymethod, hirerid) {
        this.props.getFile(fileName, filerepositorymethod, hirerid);
    }




    render() {


        const { classes, headerMenu } = this.props;

        const { requestnumber } = this.state;

        return (

            <div>
                <Grid container spacing={2} style={{ marginTop: 15 }}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Nome da empresa"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanyname}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Número de identificação do gerenciador de negócios"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanyaccountid}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Endereço"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanyaddress}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Website"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanywebsite}
                            disabled
                        />
                    </Grid>



                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Atividade principal da empresa"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanymainactivity}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Telefone da empresa"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancecompanyphone}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <label>Logotipo da empresa</label>
                        <ul>
                            <li key={requestnumber.instancecompanyattachmentpath}>
                                <AttachFile /> <Button onClick={() => this.handleFile(requestnumber.instancecompanyattachmentpath, requestnumber.instancecompanyattachmentfilerepositorymethod, requestnumber.instancehirerid)}>{requestnumber.instancecompanyattachmentdescription}</Button>
                            </li>
                        </ul>
                    </Grid>

                 


                </Grid>
            </div>


        );
    }
}
CompanyData.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    requestnumber: state.requestnumber
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CompanyData))
);