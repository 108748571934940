import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { QueuePlayNext, DeleteForever, Label } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import MyTransferList from "../../components/transfer-list/transferlist";
import MyCheckList from "../../components/checklist/checklist";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";

import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import SimpleCheckBox from "../../components/checkbox/check";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { saveAs } from "file-saver";
import { Input } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import * as Service from "../../services/hirer.service";
class CustomerRegistration extends Component {
  constructor(props) {
    super(props);
    
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      name: "",
      active: false,
      document: "",
      website: "",
      responsiblename: "",
      responsiblephone: "",
      responsiblemail: "",
      phone: "",
      blocked: false,
      email: "",
      hirerid: 0,
      hirer: null
    };
    this.state = {
      item: this.initialState,
      hirerList: [],
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      file: null,
      error: false,
      errorMessage: {}
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.getHirersByUser = this.getHirersByUser.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.changeSimpleAutocompleteValues = this.changeSimpleAutocompleteValues.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getHirersByUser();
  }

  async getHirersByUser() {
    const { userSession } = this.props;

    this.setState({ loading: true });
    var hirers = this.globalAdm ? await Service.getAllHirer() : await Service.getHirersByUser(userSession.user.id);
    if(hirers.data.datalength == 1 && this.isAdmGlobal != true){
      this.setState({ disableClient: true });
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['hirerid']: hirers.data.data[0]
        }
      }));
    }
    this.setState({ hirerList: hirers.data.data, loading: false });
  }

  handleInputChange(e) {
    const intl = this.props.intl;
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));

    if (e.target.name === "phone") {
      let pVal = this.mtel(value)
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["phone"]: this.mtel(value),
        },
      }));
      let isError = false;
      if(pVal.length == 0) {
        isError = false;
      }else if(pVal.length < 14) {
        isError = true;
        this.setState({
          error: true,
          errorMessage: { phone: intl.formatMessage({ id: "phone.error.min" }) }
        })
      }else if(pVal.length > 15) {
        isError = true;
        this.setState({
          error: true,
          errorMessage: { phone: intl.formatMessage({ id: "phone.error.max" }) }
        })
      }else {
        isError = false;
      }
      if(!isError) {
        this.setState({
          error: false,
          errorMessage: {}
        })
      }
    }

    if (e.target.name === "responsiblephone") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["responsiblephone"]: this.mtel(value),
        },
      }));
    }
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    }
    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("name") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("active") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("document") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("website") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("responsiblename") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("responsiblephone") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("responsiblemail") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.item[key].replace(/\D/gim, ""));
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("blocked") > -1
      ) {
        data.append(key, this.state.item[key]);
      }  else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("email") > -1
      ) {
        data.append(key, this.state.item[key]);
      }else {
        data.append(key, this.state.item[key]);
      }
      
    }

  this.state.files.forEach((file) => data.append("filesUpload", file));
  let config = {
    headers: {
      'Content-Type': "multipart/form-data",
      dataType: "json"
    },
  };

    Api.post("/customers", data, config)
      .then((result) => {

        if (result.data.success) {
          const intl = this.props.intl;
          
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            files: [],
          });
        } else {
          const intl = this.props.intl;
         
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });
  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    this.setState({ loading: false });
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  async changeSimpleAutocompleteValues(e, v) {
    this.setState({ loading: true });

    const name = e;
    const value = v.id;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    this.setState({ loading: false });
  };

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                 <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "hirer.name" })}
                      options={this.state.hirerList}
                      name='hirerid' 
                      stateName='hirerid'
                      changeSelect={this.changeSimpleAutocompleteValues}
                      selected={this.state.item.hirer}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "hirer.document" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="document"
                            onChange={this.handleInputChange}
                            value={item.document}
                            required
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "phone" })}
                            autoComplete="fname"
                            variant="outlined"
                            name="phone"
                            onChange={this.handleInputChange}
                            value={item.phone}
                            inputProps={{ maxLength: 15, minLength:15 }}
                            error={!!this.state.errorMessage.phone}
                            helperText={
                              this.state.errorMessage.phone &&
                              this.state.errorMessage.phone
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "website" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="website"
                            onChange={this.handleInputChange}
                            value={item.website}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "email" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="email"
                            onChange={this.handleInputChange}
                            value={item.email}
                            source="email"
                            type="email"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "hirer.responsible.name" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="responsiblename"
                            onChange={this.handleInputChange}
                            value={item.responsiblename}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "hirer.responsible.phone" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="responsiblephone"
                            onChange={this.handleInputChange}
                            value={item.responsiblephone}
                            // inputProps={{ maxLength: 15, minLength:15 }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "hirer.responsible.mail" })}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            name="responsiblemail"
                            onChange={this.handleInputChange}
                            value={item.responsiblemail}
                            source="email"
                            type="email"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6} sm={6}>
                    <SimpleCheckBox
                        label={intl.formatMessage({ id: "blocked" })}
                        name="blocked"
                        stateName="blocked"
                        changeSelect={this.changeValues}
                        selected={item.blocked}
                        />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SimpleCheckBox
                        label={intl.formatMessage({ id: "active" })}
                        name="active"
                        stateName="active"
                        changeSelect={this.changeValues}
                        selected={item.active}
                        />
                  </Grid>
                </Grid>
                <Grid container spacing={4} justify="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
CustomerRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(CustomerRegistration))
);