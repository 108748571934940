import React,{useState} from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import * as headerMenuActions from '../../redux/actions/header-menu';
import { removeUserSession } from '../../redux/actions/user-session';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import './menu.css'
import UserInfoMobile from '../user-info-mobile';
function UserMenuMobile(props) {

  let history = useHistory();
  //console.log(props);
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleClose();
    removeUserSession();
    history.push("/");
  };

  //{ resetSession },
  return (
  
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Person style={{ color: '#FFF' }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      > <MenuItem className="menu-item">{props.organizationName}</MenuItem>
        <MenuItem className="menu-item">Usuário: {props.userSession.user.name}</MenuItem>
        <MenuItem className="menu-item"  onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/user-edit-client` }} >
          <AccountBox /> 
          <span style={{ marginLeft: '10px' }}><FormattedMessage id="my.data"/></span>
        </MenuItem>

        <MenuItem className="menu-item" onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/password-reset` }}>
          <CompareArrows /> 
          <span style={{ marginLeft: '10px' }}><FormattedMessage id="change.password"/></span>
        </MenuItem>

        <UserInfoMobile/>

        <MenuItem className="menu-item" onClick={logOut}>
          <ExitToApp /> 
          <span style={{ marginLeft: '10px' }}>Logout</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = state => ({
  userSession: state.userSession,
});

//const mapDispatchToProps = dispatch => ({
//  resetSession: () => dispatch(userSessionActions.removeUserSession())
//});

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions,headerMenuActions, dispatch);

//export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserMenuMobile))))
