import React from 'react';
import LogoMarca from '../../images/new-logo-cmtech.png';

const Logo = props => (
    <div>
        <img src={LogoMarca} title="Logomarca" width="230px !important"/>
    </div>
);

export default Logo;
