import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';

const ApiCallCenter = axios.create({
    baseURL: process.env.REACT_APP_API_CALLCENTER,
    
});

ApiCallCenter.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
ApiCallCenter.defaults.headers.post['Accept']='application/json';
ApiCallCenter.defaults.headers.post['Content-Type'] ='application/json';

let token = localStorage.getItem('accesstokencallcenter');

ApiCallCenter.logoff = function (){
  userSessionActions.removeUserSession();
  document.location.href='/?sessão expirada';
}

ApiCallCenter.kickoff = function (result){
    if((result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
      if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
        userSessionActions.removeUserSession();
        document.location.href='/?sessão expirada';
      }
    } 
    if((result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
      if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
        userSessionActions.removeUserSession();
        document.location.href='/?sessão expirada';
      }
    } 

}
//console.log(token);
ApiCallCenter.defaults.headers.common['Authorization']='Bearer '+token;

export default ApiCallCenter;
