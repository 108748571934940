import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function FormDialog(props) {
    const { open, children, handleSubmit, handleClose, title, text } = props

    return (
        <Dialog open={open} onClose={handleClose} sx={{ '&::-webkit-scrollbar': { display: "none" } }}>
            <Grid container>
                <Grid container item sm={12}>
                    <DialogTitle>{title}</DialogTitle>
                </Grid>
                <Grid item sm={12}>
                    <DialogContent>
                        <Grid container spacing={2} style={{ padding: 0 }}>
                            <Grid item sm={12}>
                                <DialogContentText>{text}</DialogContentText>
                            </Grid>
                            <Grid item sm={12}>
                                {children}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Grid>
                <Grid item sm={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <DialogActions>
                        <Grid container justify="space-evenly">
                            <Button
                                onClick={handleClose}
                                variant="outlined"
                            >
                                <FormattedMessage id="cancel" />
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                            >
                                <FormattedMessage id="submit" />
                            </Button>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    )

}