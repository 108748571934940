import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {Switch,  Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@material-ui/core';
import { QueuePlayNext, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/retrain.service';
import * as componentActions from '../../redux/actions/retrains';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import AlertDialog from '../../components/dialog';

class PredictRetrainingEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession,retrains } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    

    const defaultvalue = {id:0, name:''};
    
    this.initialState = {
        UpdatedUser: userSession.user.id,
        id: retrains.retrain.id,
        urlinstance : retrains.retrain.urlinstance == null ? '' : retrains.retrain.urlinstance , 
        response : retrains.retrain.response == null ? '' : retrains.retrain.response , 
        authorized : retrains.retrain.authorized == null ? false : retrains.retrain.authorized , 
        dateexecuted : retrains.retrain.dateexecuted == null ? '' : retrains.retrain.dateexecuted , 
        executed : retrains.retrain.executed == null ? '' : retrains.retrain.executed , 
        success : retrains.retrain.success == null ? '' : retrains.retrain.success , 
        _executed : retrains.retrain._executed == null ? '' : retrains.retrain._executed , 
        _success : retrains.retrain._success == null ? '' : retrains.retrain._success , 

        requestdate : retrains.retrain.requestdate == null ? '' : retrains.retrain.requestdate , 
        userid : retrains.retrain.userid == null ? '' : retrains.retrain.userid , 


     };         
    this.state = {
        retrain: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      openDialog : false,

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.toggleAuthorized = this.toggleAuthorized.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.goAccept = this.goAccept.bind(this);
    this.goDecline = this.goDecline.bind(this);

  }

  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  async componentDidMount() {
   
  };
 

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
        retrain: {
        ...prevState.retrain,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    //e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
            retrain: {
            ...prevState.retrain,
          }
        }));
      } else {
        this.setState(prevState => ({
            retrain: {
            ...prevState.retrain,
          }
        }));
      }


      for (let key in this.state.retrain) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 
        || key.toString().toLowerCase().indexOf('date_') > -1
        ) {
          data.append(key, moment(this.state.retrain[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
        else if (key.toString().toLowerCase().indexOf('requestdate') > -1){
        }
        else {
          data.append(key, this.state.retrain[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/retrain/authorize', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
            //console.log(formattedList);
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  toggleAuthorized = (event,value) => {
    console.log(value);
    //this.setState({ authorized: value })
    this.setState(prevState => ({
        retrain: {
        ...prevState.retrain,
        authorized: value
      }
    }));

      if (value === true) {
    //user wants to be remembered.
    } else {
    }
  }
  saveForm = () => {
    this.setState({ openDialog: true });

     
  }  
  goAccept = () => {

    this.setState({ openDialog: false });
    this.handleSubmit();
     
  } 
  goDecline = () => {
     
    this.setState({ openDialog: false });
  
  } 

  render() {
    const { classes, headerMenu, retrains } = this.props;
    const { retrain } = this.state;
    const intl = this.props.intl;
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              {/*onSubmit=this.handleSubmit*/}
              <form name='myForm' className={classes.form}  encType='multipart/form-data'>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"urlinstance"})} autoComplete='fname' variant='outlined' name='urlinstance' value={retrain.urlinstance} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"requestdate"})} autoComplete='fname' variant='outlined' name='requestdate' value={retrain.requestdate} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"response"})} autoComplete='fname' variant='outlined' name='response' value={retrain.response} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"executed"})} autoComplete='fname' variant='outlined' name='_executed' value={retrain._executed} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"dateexecuted"})} autoComplete='fname' variant='outlined' name='dateexecuted' value={retrain.dateexecuted} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <TextField disabled fullWidth label={intl.formatMessage({id:"userid"})} autoComplete='fname' variant='outlined' name='userid' value={retrain.userid} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <Switch
                            name='authorized'                         
                            value={retrain.authorized}
                            checked={this.state.retrain.authorized}
                            onChange={this.toggleAuthorized}
                            />{intl.formatMessage({id:"authorized"})}

                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                    <Button
                      type='button'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={this.saveForm}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                <AlertDialog opened={this.state.openDialog} title={"Deseja autorizar a solicitação?"} 
                message={"Após a autorização, a solictação entrará na fila para execução e não poderá mais ser cancelada."} 
                faccept={this.goAccept} fdecline={this.goDecline}
                />
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
PredictRetrainingEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    retrains: state.retrains
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(PredictRetrainingEdit)));
