import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Styles } from "../../styles/material-styles";
import { bindActionCreators } from "redux";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Button, withStyles, Container, Grid, CssBaseline, Typography, CircularProgress, Avatar } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import * as componentActions from "../../redux/actions/contract";
import Api from "../../services/api";
import * as Service from "../../services/contract"
import { browserHistory } from "../../helpers/history";
import SimpleCheckBox from "../../components/checkbox/check";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import { ListAltRounded } from '@material-ui/icons';
import ContractForm from "../../components/contract-form";

class ContractEdit extends Component {
    constructor(props) {
        super(props)

        const { userSession, contract, intl } = this.props

        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        });

        if (!contract.contract) {
            this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirers` })
        }

        this.initialState = contract.contract ? {
            id: contract.contract.id,
            hirerid: contract.contract.hirerid,
            startdate: intl.formatDate(contract.contract.startdate),
            billingstartdate: intl.formatDate(contract.contract.billingstartdate),
            enddate: intl.formatDate(contract.contract.enddate),
            contractplanperiodicities: contract.contract.contractplanperiodicities,
            contractstatusid: contract.contract.contractstatusid,
            inactive: contract.contract.inactive,
        } : {
            id: 0,
            hirerid: 0,
            startdate: "01/01/2023",
            billingstartdate: "01/01/2023",
            enddate: "01/01/2023",
            contractplanperiodicities: [],
            contractstatusid: 0,
            inactive: true,
        }

        this.state = {
            item: this.initialState,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            applications: [],
            plans: [],
            periodicities: [],
            contractstatuses: [],
            daysexpire: 30,
        }
        this.changeDate = this.changeDate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.stringToDate = this.stringToDate.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
        this.handleInactive = this.handleInactive.bind(this)
    }

    async fetchData() {
        this.setState({ loading: true })
        let applications, plans, periodicities, contractstatuses = []

        await Api.get("/applications/available")
            .then(result => {
                applications = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/plans")
            .then(result => {
                plans = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/periodicities")
            .then(result => {
                periodicities = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/contractstatuses")
            .then(result => {
                contractstatuses = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })

        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractplanperiodicities: applications.map(app => {
                    const checkedCpp = this.state.item?.contractplanperiodicities.find(cpp => cpp.planperiodicity.applicationid == app.id)
                    return checkedCpp ? {
                        ...checkedCpp,
                        planperiodicity: {
                            ...checkedCpp.planperiodicity,
                            checked: true,
                        }
                    } : {
                        agentLimitquantity: "",
                        additionalvalueagent: "",
                        diskLimitusage: "",
                        planvalue: "",
                        attendancelimitpermonth: "",
                        attendancevalue: "",
                        planperiodicity: {
                            applicationid: app.id,
                            periodicityid: periodicities[0].id,
                            planid: plans[0].id,
                            checked: false
                        }
                    }
                })
            }
        }))

        this.setState({
            loading: false,
            applications: applications,
            plans: plans,
            periodicities: periodicities,
            contractstatuses: contractstatuses
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    setError(error) {
        this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: error.errors? error.errors[0] : this.props.intl.formatMessage({ id: "process.error"}),
        })
        Api.kickoff(error)
    }

    changeDate(key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [key]: this.props.intl.formatDate(value)
            }
        }))
        if (key == "startdate") {
            this.updateEndDate(value, this.state.daysexpire)
        }
    }

    updateEndDate(startdate, daysexpire) {
        let newEndDate = new Date(startdate)
        newEndDate.setDate(newEndDate.getDate() + daysexpire)
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                enddate: this.props.intl.formatDate(newEndDate)
            }
        }))
    }

    handleChangeStatus(value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractstatusid: value
            }
        }))
    }

    handleChange(i, key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractplanperiodicities: [
                    ...prevState.item.contractplanperiodicities.map((cpp, index) => {
                        if (i == index) {
                            return {
                                ...prevState.item.contractplanperiodicities[i],
                                [key]: value
                            }
                        } else {
                            return cpp
                        }
                    })
                ]
            }
        }))
    }

    handleInactive() {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                inactive: !prevState.item.inactive
            }
        }))
    }

    handleChangeSelect(i, key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractplanperiodicities: [
                    ...prevState.item.contractplanperiodicities.map((cpp, index) => {
                        if (i == index) {
                            return {
                                ...prevState.item.contractplanperiodicities[i],
                                planperiodicity: {
                                    ...prevState.item.contractplanperiodicities[i].planperiodicity,
                                    [key]: value
                                }
                            }
                        } else {
                            return cpp
                        }
                    })
                ]
            }
        }))
        if (key == "periodicityid") {
            let daysexpire = this.state.periodicities.find(p => p.id == value).daysexpire
            const periodicitiesDaysExpire = []
            for (var cppid in this.state.item.contractplanperiodicities) {
                const cpp = this.state.item.contractplanperiodicities[cppid]
                if (cpp.planperiodicity.checked && cppid != i) {
                    periodicitiesDaysExpire.push(this.state.periodicities.find(p => p.id == cpp.planperiodicity.periodicityid).daysexpire)
                }
            }

            const startdate = this.stringToDate(this.state.item.startdate).toDateString()

            if (periodicitiesDaysExpire.every((dexp) => daysexpire > dexp)) {
                this.setState({
                    daysexpire: daysexpire
                })
                this.updateEndDate(startdate, daysexpire)
            } else {
                daysexpire = Math.max(...periodicitiesDaysExpire)
                this.updateEndDate(startdate, daysexpire)
            }
        }
    }

    stringToDate(dataString) {
        var parts = dataString.split('/')
        var day = parseInt(parts[0], 10)
        var month = parseInt(parts[1], 10) - 1 // No objeto Date, os meses vão de 0 a 11
        var year = parseInt(parts[2], 10)
        const date = new Date(year, month, day)
        return date
    }

    formatDate(date) {
        const parts = date.split('/')
        const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`
        return newDate
    }

    async handleSubmit() {
        const intl = this.props.intl
        this.setState({ loading: true })
        let contract = JSON.parse(JSON.stringify(this.state.item))
        contract.contractplanperiodicities = contract.contractplanperiodicities.filter(cpp => cpp.planperiodicity.checked)
        contract.startdate = this.formatDate(contract.startdate)
        contract.enddate = this.formatDate(contract.enddate)
        contract.billingstartdate = this.formatDate(contract.billingstartdate)
        const result = await Service.updateContract(contract)
        if (result.success) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "edit.success" })
            })
        } else {
            this.setError(result)
        }
    }

    render() {

        if (!this.state.item) {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "Não foi possível carregar o contrato",
            })
            this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirers` })
            return
        }

        const { classes, headerMenu, intl } = this.props
        const { applications, contractstatuses, plans, periodicities, item } = this.state

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container component="main" maxWidth="xl">
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={browserHistory.goBack}
                                    >
                                        <FormattedMessage id={"back"} />
                                    </Button>
                                </Grid>
                                <Grid container item alignItems="center" direction="column" style={{ paddingTop: 0 }}>
                                    <Avatar className={classes.avatar}>
                                        <ListAltRounded />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        {<FormattedMessage id="edit.item" />} - {item.id}
                                    </Typography>
                                </Grid>
                                <ContractForm
                                    contract={item} intl={intl} edit
                                    applications={applications} contractstatuses={contractstatuses} plans={plans} periodicities={periodicities}
                                    changeDate={this.changeDate} handleChangeSelect={this.handleChangeSelect} handleChange={this.handleChange}
                                    handleChangeStatus={this.handleChangeStatus}
                                />
                                <Grid container item sm={12} justify="space-between">
                                    <Grid item container sm={2} justify="center">
                                        <SimpleCheckBox
                                            label={intl.formatMessage({ id: "active" })}
                                            selected={!item.inactive}
                                            changeSelect={this.handleInactive}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                            onClick={this.handleSubmit}
                                        >
                                            <FormattedMessage id="save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            isOpen={this.state.openNotification}
                            message={this.state.notificationMessage}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}

ContractEdit.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    contract: state.contracts,
    hirers: state.hirers
})


const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ContractEdit)))