import React from 'react';
import LogoMarca from '../../images/logo-cmtech-small.png';

const LogoSmall = props => (
    <div>
        <img src={LogoMarca} title="Logomarca" />
    </div>
);

export default LogoSmall;
