import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@material-ui/core';
import { QueuePlayNext, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import MyCheckList from '../../components/checklist/checklist';
import Footer from '../../components/footer';

import * as Service from '../../services/user.service';
import * as componentActions from '../../redux/actions/users';
class UserEdit extends Component {

  
  constructor(props) {
    super(props);
            const { userSession,users } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
    
    this.state = {
      user: {
        UpdatedUser: "",
        id: "",
        name : "", 
        email : "",  
        active : false,  
        phone : "",
      },
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      RoleList: [],
      RoleListUser: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.getItens = this.getItens.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload"){
        this.componentDidMount();
    }
  }    

  componentDidMount() {
    this.getItens();
  };

  async getOrganizations(){
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
      if (result.success) {
        this.setState({ loading: false, OrganizationList: result.data });
                      
      } else {
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : 'Erro ao processar a solicitação.'
        });
        Api.kickoff(result);
      }
  };

  getItens() {
    this.setState({ loading: true });
    const { userSession } = this.props;

    Api.get(`/users/${userSession.user.id}`)
        .then(result => {
            if (result.data.success) {
                const formattedList = {
                  UpdatedUser: result.data.data.id,
                  id: result.data.data.id,
                  name : result.data.data.name ? result.data.data.name : "", 
                  email : result.data.data.email ? result.data.data.email : "",  
                  active : result.data.data.active ? result.data.data.active : false,  
                  phone : result.data.data.phone ? result.data.data.phone : ""
                }
                
                this.setState({ loading: false, user: formattedList });
            }
            else{
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : "Erro ao processar a solicitação."
                });
                Api.kickoff(result);
            }
        })
        .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : "Erro ao processar a solicitação."
                });
            Api.kickoff(err);
        });

};
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };
  
  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          user: {
            ...prevState.user,
          }
        }));
      } else {
        this.setState(prevState => ({
          user: {
            ...prevState.user,
          }
        }));
      }

      //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.user['email'])) { 
      //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
      //  return;
      //}
      for (let key in this.state.user) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.user[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.user[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/users/client', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        formattedList.passwordhash= null;
        formattedList.passwordrecoveryguid= null;
        formattedList.passwordsalt= null;


            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: 'Atualizado com sucesso!',
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : 'Erro ao processar a solicitação.'
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : 'Erro ao processar a solicitação.'
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : 'Erro ao processar a solicitação.'

            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [stateName]: value
      }
    }));
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            user: {
              ...prevState.user,
              ['OrganizationText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };


  
    render() {
    const { classes, headerMenu, users } = this.props;
    const { user } = this.state;
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Edição de Item
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label='Nome:' autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={user.name} required />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField disabled fullWidth label='E-mail:' autoComplete='fname' variant='outlined' name='email' onChange={this.handleInputChange} value={user.email} required />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label='Celular:' autoComplete='fname' variant='outlined' name='phone' onChange={this.handleInputChange} value={user.phone} required/>
                    </Grid>
                </Grid>
                    
                
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
UserEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    users: state.users
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(UserEdit));
