import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { QueuePlayNext, DeleteForever } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import SimpleCheckBox from "../../components/checkbox/check";
import MyCheckList from "../../components/checklist/checklist";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as Service from "../../services/user.service";
import * as ServiceHirer from "../../services/hirer.service";
import * as componentActions from "../../redux/actions/users";
import { FormattedMessage, injectIntl } from "react-intl";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete-group";
import Autocomplete from "@material-ui/lab/Autocomplete";
class UserEdit extends Component {
  constructor(props) {
    super(props);
    const { userSession, users } = this.props;
    console.log(users);
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    //var arr = users.user.userroles.map(item => `${item.role.id}`).join(',');
    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: users.user.id,
      name: users.user.name,
      email: users.user.email,
      active: users.user.active,
      UserRoles: users.user.userroles,
      hirers: users.user.hirers,
      hirerText: users.user.hirertext,
      UserHirer: users.user.userhirer,
      hirersIds: []
    };

    this.state = {
      user: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      RoleList: [],
      HirerList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    // if(this.state.user.OrganizationId.length > 0){
    //   console.log(this.state.user.OrganizationId)
    //   let x = this.state.user.OrganizationId.map(
    //     (element) =>{
    //       element.name = `( ${element.group.name} ) ${element.name}`;
    //       return element;
    //     }
    //   )

    //   console.log(x);
    // }

    this.getRoles();
    this.getHirers();

    // if(this.state.user.OrganizationDepartamentId != 0){
    //   this.getDepartament(this.state.user.OrganizationId);
    // }
  }

  async getHirers() {
    this.setState({ loading: true });
    var result = await ServiceHirer.getHirer();
    if (result.success) {
      
      this.setState({ loading: false, HirerList: result.data });
      let userHirer = this.state.user.UserHirer;
      let hirers = [];
      userHirer.forEach((v) => {
        hirers.push(v.hirer);
      });
      console.log(hirers);
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          UserHirer: hirers
        },
      }));
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getRoles() {
    this.setState({ loading: true });
    var result = await Service.getRoles();
    if (result.success) {
      this.setState({ loading: false, RoleList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getRolesByUser() {
    this.setState({ loading: true });
    var result = await Service.getRolesByUser(this.state.user.id);
    if (result.success) {
      this.setState({ loading: false, RoleListUser: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  }

  getObjectRolesIds(list) {
    if(typeof list !== 'undefined') {
      const idList = list.map((item) => {
        return item.role.id;
      });
      return idList;
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
        },
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
        },
      }));
    }

    //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.user['email'])) {
    //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
    //  return;
    //}
    if (this.state.user.UserRoles == null || this.state.user.UserRoles == "") {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "selected.group" }),
      });
      return;
    }

    for (let key in this.state.user) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.user[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.user[key].replace(/\D/gim, ""));
      } else {
        if (key === "UserHirer") {
          let idsString = "";
          if (this.state.user.UserHirer.length > 0) {
            this.state.user.UserHirer.forEach(
              (hirer) => (idsString += hirer.id + ",")
            );
          }
          var newStr = idsString.slice(0, -1);
          console.log("========++ New STR [HIRER] ++========");
          console.log(newStr);
          data.append("clientids", newStr);
        }else if(key === "UserRoles") {
          let idsString = "";
          if (this.state.user.UserRoles.length > 0) {
            this.state.user.UserRoles.forEach(
              (role) => (idsString += role + ",")
            );
          }
          var newStr = idsString.slice(0, -1);
          console.log("========++ New STR [ROLES] ++========");
          console.log(newStr);
          data.append("rolesids", newStr);
        } else {
          data.append(key, this.state.user[key]);
        }
      }
    }

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    Api.put("/users", data, config)
      .then((result) => {
        if (result.data.success) {
          const formattedList = result.data.data;
          formattedList.userroles = this.getObjectRolesIds(
            formattedList.userroles
          );
          formattedList.passwordhash = null;
          formattedList.passwordrecoveryguid = null;
          formattedList.passwordsalt = null;

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: [],
          });
          this.props.addCurrent(formattedList);
          //this.componentDidMount();
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  handleClick = async (e) => {
    this.setState({ loading: true });
    const intl = this.props.intl;
    console.log(this.props.intl);
    const { userSession, users } = this.props;

    console.log(users.user.email);
    let data = new FormData();
    data.append("userEmail", users.user.email);

    Api.put("users/enviaremail", data)
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Email Enviado",
          item: this.initialState,
          files: [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : "Erro ao processar a solicitação.",
        });
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }



  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [stateName]: value,
      },
    }));

    if (stateName === "UserHirer") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["hirerText"]: text,
          UserHirer: value
        }
      }));
    }

    this.setState({ loading: false });
  }
  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  getRolesIds(list) {
    const idList = list.map((item) => {
      return item.id;
    });
    return idList;
  }
  getRolesNames(list) {
    const idList = list.map((item) => {
      return item.name;
    });
    return idList;
  }

  render() {
    const { classes, headerMenu, users } = this.props;
    //console.log
    const { user } = this.state;

    //console.log(user)
    //console.log(this.state.user)
    //console.log(user)
    const intl = this.props.intl;   
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "hirer.group" })}
                      options={this.state.HirerList}
                      stateName="UserHirer"
                      changeSelect={this.changeValues}
                      selected={user.UserHirer}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={user.name}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      autoComplete="fname"
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      value={user.email}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name={intl.formatMessage({ id: "active" })}
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={user.active}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Typography component="h5" variant="h5">
                    {<FormattedMessage id="grupos.usuarios" />}
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <MyCheckList
                      label={intl.formatMessage({ id: "org.group" })}
                      name="UserRoles"
                      optionsText={this.getRolesNames(this.state.RoleList)}
                      options={this.getRolesIds(this.state.RoleList)}
                      stateName="UserRoles"
                      changeSelect={this.changeValues}
                      selected={user.UserRoles}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} justify="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
UserEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  users: state.users,
  addresses:state.addresses,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserEdit))
);
