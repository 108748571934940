import React, { Component } from "react";
import MyMaterialTable from "../../../components/table"
import PropTypes from "prop-types";
import * as Service from "../../../services/contract"
import * as componentActions from "../../../redux/actions/contract"
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, CircularProgress, Grid, withStyles } from "@material-ui/core";
import { Styles } from "../../../styles/material-styles";
import { bindActionCreators } from "redux";
import { Overlay } from "../../../styles/global";
import CustomizedSnackbars from "../../../components/material-snackbars";

class Contracts extends Component {
    constructor(props) {
        super(props)
        const { intl } = this.props

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            contracts: []
        }
        this.goToEdit = this.goToEdit.bind(this)
        this.goToCreate = this.goToCreate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
    }

    componentDidMount() {
        this.fetchData()
    }

    async fetchData() {
        const { intl } = this.props
        this.setState({ loading: true })
        const result = await Service.getContractsFromHirer(this.props.hirer.id)
        if (result.success) {
            const contracts = result.data.map(contract => {
                if (contract.inactive) {
                    contract.inactive = intl.formatMessage({ id: "inactive" })
                } else {
                    contract.inactive = intl.formatMessage({ id: "active" })
                }
                contract.startdate = intl.formatDate(contract.startdate)
                contract.startbillingdate = intl.formatDate(contract.startbillingdate)
                contract.enddate = intl.formatDate(contract.enddate)
                return contract
            })
            this.setState({
                loading: false,
                contracts: contracts,
            })
        }
    }

    goToEdit = async (id) => {
        this.props.setLoading()
        let contract = await Service.getContractById(id)
        if (contract.success) {
            this.props.finishLoading()
            this.props.addCurrent(contract.data)
        } else {
            this.props.setError(contract)
        }
        this.props.history.push({ pathname: `/${this.props.match.params.lang}/contract-edit` })
    }

    goToCreate() {
        this.props.history.push({ pathname: `/${this.props.match.params.lang}/contract-registration` })
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    async handleDelete(data, resolve) {
        const intl = this.props.intl
        this.setState({ loading: true })
        const result = await Service.deleteContract(data.id)

        if (result.success) {
            this.setState((prevState) => ({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                contracts: prevState.contracts.filter(contract => contract.id != data.id)
            }))
            resolve()
        } else {
            console.log(result)
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            resolve()
        }
    }
    render() {
        const { intl } = this.props
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.goToCreate}
                        >
                            <FormattedMessage id={"add.item"} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <MyMaterialTable
                            title="ContractsTable"
                            columns={[
                                { title: "ID", field: "id" },
                                { title: "Status", field: "inactive" },
                                { title: "Situação", field: "contractstatus.name" },
                                { title: intl.formatMessage({ id: "start.date" }), field: "startdate" },
                                { title: intl.formatMessage({ id: "end.date" }), field: "enddate" },
                            ]}
                            data={this.state.contracts}
                            deleteItem={this.handleDelete}
                            rowClick={(event, rowData) => this.goToEdit(rowData.id)}
                        />
                    </Grid>
                </Grid>
                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}
                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    isOpen={this.state.openNotification}
                    message={this.state.notificationMessage}
                    toClose={this.closeNotification}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    contract: state.contract,
    hirers: state.hirers,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Contracts)))