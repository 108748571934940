import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as componentActions from "../../redux/actions/contract";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, CircularProgress, Container, CssBaseline, Grid, withStyles } from "@material-ui/core";
import { Styles } from "../../styles/material-styles";
import HeaderMenu from "../../components/header-menu";
import clsx from "clsx";
import { browserHistory } from "../../helpers/history";
import Api from "../../services/api";
import * as Service from "../../services/contract"
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import ContractForm from "../../components/contract-form";

class ContractRegistration extends Component {
    constructor(props) {
        super(props)

        this.initialState = {
            hirerid: this.props.hirer.id,
            startdate: "01/01/2023",
            billingstartdate: "01/01/2023",
            enddate: "01/01/2023",
            contractplanperiodicities: [],
            contractstatusid: 0,
            inactive: true,
        }
        this.state = {
            item: this.initialState,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            applications: [],
            plans: [],
            periodicities: [],
            contractstatuses: [],
            daysexpire: 30,
        }
        this.changeDate = this.changeDate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.stringToDate = this.stringToDate.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
    }

    async fetchData() {
        this.setState({ loading: true })
        let applications, plans, periodicities, contractstatuses = []

        await Api.get("/applications/available")
            .then(result => {
                applications = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/plans")
            .then(result => {
                plans = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/periodicities")
            .then(result => {
                periodicities = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        await Api.get("/contractstatuses")
            .then(result => {
                contractstatuses = result.data.data
            })
            .catch(err => {
                this.setError(err)
            })
        
        const defaultStatusId = contractstatuses.find(c => c.name == "SOLICITADO").id
        applications.map(app => {
            this.setState((prevState) => ({
                item: {
                    ...prevState.item,
                    contractstatusid: defaultStatusId,
                    contractplanperiodicities: [
                        ...prevState.item.contractplanperiodicities,
                        {
                            agentlimitquantity: "",
                            additionalvalueagent: "",
                            disklimitusage: "",
                            planvalue: "",
                            attendancelimitpermonth: "",
                            attendancevalue: "",
                            planperiodicity: {
                                applicationid: app.id,
                                periodicityid: periodicities[0].id,
                                planid: plans[0].id,
                                checked: false,
                            }
                        }
                    ]
                }
            }))
        })

        this.setState({
            loading: false,
            applications: applications,
            plans: plans,
            periodicities: periodicities,
            contractstatuses: contractstatuses
        })
    }

    setError(error) {
        this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: error,
            notificationVariant: "error",
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    changeDate(key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [key]: this.props.intl.formatDate(value)
            }
        }))
        if (key == "startdate") {
            this.updateEndDate(value, this.state.daysexpire)
        }
    }

    updateEndDate(startdate, daysexpire) {
        let newEndDate = new Date(startdate)
        newEndDate.setDate(newEndDate.getDate() + daysexpire)
            this.setState((prevState) => ({
                item: {
                    ...prevState.item,
                    enddate: this.props.intl.formatDate(newEndDate)
                }
            }))
    }

    formatDate(date) {
        const parts = date.split('/')
        const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`
        return newDate
    }

    handleChange(i, key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractplanperiodicities: [
                    ...prevState.item.contractplanperiodicities.map((cpp, index) => {
                        if (i == index) {
                            return {
                                ...prevState.item.contractplanperiodicities[i],
                                [key]: value
                            }
                        } else {
                            return cpp
                        }
                    })
                ]
            }
        }))
    }

    handleChangeSelect(i, key, value) {

        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                contractplanperiodicities: [
                    ...prevState.item.contractplanperiodicities.map((cpp, index) => {
                        if (i == index) {
                            return {
                                ...prevState.item.contractplanperiodicities[i],
                                planperiodicity: {
                                    ...prevState.item.contractplanperiodicities[i].planperiodicity,
                                    [key]: value,
                                }
                            }
                        } else {
                            return cpp
                        }
                    })
                ]
            }
        }))
        if (key == "periodicityid") {
            let daysexpire = this.state.periodicities.find(p => p.id == value).daysexpire
            const periodicitiesDaysExpire = []
            for (var cppid in this.state.item.contractplanperiodicities) {
                const cpp = this.state.item.contractplanperiodicities[cppid]
                if (cpp.planperiodicity.checked && cppid != i) {
                    periodicitiesDaysExpire.push(this.state.periodicities.find(p => p.id == cpp.planperiodicity.periodicityid).daysexpire)
                }
            }
            
            const startdate = this.stringToDate(this.state.item.startdate).toDateString()

            if (periodicitiesDaysExpire.every((dexp) => daysexpire > dexp)) {
                this.setState({
                    daysexpire: daysexpire
                })
                this.updateEndDate(startdate, daysexpire)
            } else {
                daysexpire = Math.max(...periodicitiesDaysExpire)
                this.updateEndDate(startdate, daysexpire)
            }
        }
    }

    stringToDate(dataString) {
        var parts = dataString.split('/')
        var day = parseInt(parts[0], 10)
        var month = parseInt(parts[1], 10) - 1 // No objeto Date, os meses vão de 0 a 11
        var year = parseInt(parts[2], 10)
        const date = new Date(year, month, day)
        return date
    }

    async handleSubmit(e) {
        e.preventDefault()
        this.setState({ loading: true })

        const intl = this.props.intl
        let item = JSON.parse(JSON.stringify(this.state.item))
        item.contractplanperiodicities = item.contractplanperiodicities.filter(cpp => cpp.planperiodicity.checked == true)
        item.startdate = this.formatDate(item.startdate)
        item.enddate = this.formatDate(item.enddate)
        item.billingstartdate = this.formatDate(item.billingstartdate)

        const result = await Service.createContract(item)

        if (result.success) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "add.success" })
            })
            window.location.reload()
        } else {
            this.setError(result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
    }

    render() {
        const { classes, headerMenu, intl } = this.props
        const { applications, contractstatuses, plans, periodicities, item } = this.state
        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container component="main" maxWidth="xl">
                        <CssBaseline />
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item sm={3}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={browserHistory.goBack}
                                    >
                                        <FormattedMessage id={"back"} />
                                    </Button>
                                </Grid>
                                <ContractForm
                                    intl={intl} contract={item}
                                    applications={applications} contractstatuses={contractstatuses} plans={plans} periodicities={periodicities}
                                    changeDate={this.changeDate} handleChangeSelect={this.handleChangeSelect} handleChange={this.handleChange}
                                />
                                <Grid container item sm={12} justify="flex-end">
                                    <Grid item sm={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                        >
                                            <FormattedMessage id="save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            isOpen={this.state.openNotification}
                            message={this.state.notificationMessage}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userSession: state.userSession,
    headerMenu: state.headerMenu,
    hirer: state.hirers.hirer
})

const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ContractRegistration)))