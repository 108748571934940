import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { QueuePlayNext, DeleteForever, Label } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import MyTransferList from "../../components/transfer-list/transferlist";
import MyCheckList from "../../components/checklist/checklist";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";

import SimpleAutoComplete from "../../components/auto-complete/autocomplete-group";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import SimpleCheckBox from "../../components/checkbox/check";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { saveAs } from "file-saver";
import { Input } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import * as Service from "../../services/user.service";
import * as ServiceHirer from "../../services/hirer.service";
class UserRegistration extends Component {
  constructor(props) {
    super(props);
    
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    console.log(this.globalAdm)

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      name: "",
      email: "",
      active: false,
      hirers: 0,
      hirerText: "",
      UserHirer: [],
      UserRoles: []
    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      RoleList: [],
      HirerList: [],
      file: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.importModelFile = this.importModelFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    //this.getOrganizations();
    this.getRoles();
    this.getHirers();
  }

  /*onFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    
    if (this.state.file != null) {
      data.append("file", this.state.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      Api.post("/users/planilhas", data, config)

        .then((result) => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              resultList: [],
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                result.data && result.data.errors
                  ? result.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(result);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }) + err.response,
            });
          }
        });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "excel" }),
      });
    }
  };*/

  importModelFile() {
    this.setState({ loading: true });
    var url = "/users/importfile";

    Api.get(url, {
      responseType: "blob",
    }).then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      
      saveAs(url, "modelo.xlsx");
      this.setState({ loading: false });
    });
  }

  async getRoles() {
    this.setState({ loading: true });
    var result = await Service.getRoles();
    if (result.success) {
      
      this.setState({ loading: false, RoleList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getHirers() {
    this.setState({ loading: true });
    var result = await ServiceHirer.getHirer();
    
    if (result.success) {
      
      this.setState({ loading: false, HirerList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));



    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["phone"]: this.mtel(value),
        },
      }));
    }
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();
    

    if (this.isClient) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
      console.log(this.state.item);
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
      
    }

    //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.item['email'])) {
    //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
    //  return;
    //}
    if (this.state.item.UserRoles == null || this.state.item.UserRoles == "") {
      const intl = this.props.intl;
      
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "selected.group" }),
      });
      return;
    }
    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else {
        if (key === "UserHirer") {
          let idsString = "";
          if (this.state.item.UserHirer.length > 0) {
            this.state.item.UserHirer.forEach(
              (hirer) => (idsString += hirer.id + ",")
            );
          }
          var newStr = idsString.slice(0, -1);
          console.log("========++ New STR [HIRER] ++========");
          console.log(newStr);
          data.append("clientids", newStr);
        }else if(key === "UserRoles") {
          let idsString = "";
          if (this.state.item.UserRoles.length > 0) {
            this.state.item.UserRoles.forEach(
              (role) => (idsString += role + ",")
            );
          }
          var newStr = idsString.slice(0, -1);
          console.log("========++ New STR [ROLES] ++========");
          console.log(newStr);
          data.append("rolesids", newStr);
        } else {
          data.append(key, this.state.item[key]);
        }
      }
      
    }
    console.log(this.state.item);

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        dataType: "json",
      },
    };
    console.log(data, config);
    console.log(this.props.intl);
    Api.post("/users", data, config)

      .then((result) => {
        console.log(result.data);
        if (result.data.success) {
          const intl = this.props.intl;
          
          console.log(intl);
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            files: [],
          });
          this.getHirers();
          this.getRoles();
        } else {
          const intl = this.props.intl;
         
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });


  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    console.log(value)

    
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    if (stateName === "UserHirer") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["hirerText"]: text,
          UserHirer: value
        }
      }));
    }

    this.setState({ loading: false });
  }
  getRolesIds() {
    const idList = this.state.RoleList.map((item) => {
      return item.id;
    });
    return idList;
  }
  getRolesNames() {
    const idList = this.state.RoleList.map((item) => {
      return item.name;
    });
    return idList;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "hirer.group" })}
                      options={this.state.HirerList}
                      stateName="UserHirer"
                      changeSelect={this.changeValues}
                      selected={item.UserHirer}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      type="email"
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      value={item.email}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={item.active}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Typography component="h5" variant="h5">
                    {<FormattedMessage id="grupos.usuarios" />}
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <MyCheckList
                      label={intl.formatMessage({ id: "menu.groups" })}
                      name="UserRoles"
                      optionsText={this.getRolesNames()}
                      options={this.getRolesIds()}
                      stateName="UserRoles"
                      changeSelect={this.changeValues}
                      selected={item.UserRoles}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
UserRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(UserRegistration))
);
