import React from "react"
import { Button, Grid, TextField, Typography } from "@material-ui/core"
import SimpleAutoComplete from "../../../components/auto-complete/autocomplete"
import SimpleCheckBox from "../../../components/checkbox/check"

export default function GeneralEdit(props) {
    const { config, handleInputChange, changeValues, timezonelist, formatdatelist, intl } = props

    return (
        <Grid container justify="center">
            <Grid item sm={4}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <SimpleAutoComplete label={intl.formatMessage({ id: "timezone" })} options={timezonelist} stateName='timezone' changeSelect={changeValues} selected={config.timezone} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <SimpleAutoComplete label={intl.formatMessage({ id: "date.format" })} options={formatdatelist} stateName='formatdate' changeSelect={changeValues} selected={config.formatdate} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth label={intl.formatMessage({ id: "decimal.separator" })} autoComplete='fname' variant='outlined' name='decimalseparator' onChange={handleInputChange} value={config.decimalseparator} required
                                onInput={(e) => {
                                    e.target.value = e.target.value.toString().slice(0, 1)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth label={intl.formatMessage({ id: "thousand.separator" })} autoComplete='fname' variant='outlined' name='thousandseparator' onChange={handleInputChange} value={config.thousandseparator} required
                                onInput={(e) => {
                                    e.target.value = e.target.value.toString().slice(0, 1)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="passwordrecognition"
                                variant="outlined"
                                fullWidth
                                id="passwordrecognition"
                                label="Senha"
                                type='password'
                                placeholder='Deixe em branco para não alterar'
                                value={config.passwordrecognition}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>

                </div>

                <Grid container spacing={4}>
                </Grid>
            </Grid>
        </Grid>
    )
}