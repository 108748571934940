import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { FormattedMessage,injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function SimpleSelect(props) {

  const { label, options, stateName, changeSelect, selected, required, disabled } = props;

  const classes = useStyles();
  const [option, setOption] = React.useState(selected ? selected : 0);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    setOption(selected ? selected : 0);
  }, [selected]);

  const handleChange = event => {
    setOption(event.target.value);
    changeSelect(stateName, event.target.value);
  };

  const intl = props.intl;

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}           
        disabled={disabled}>
        <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={option}
          onChange={handleChange}
          labelWidth={labelWidth}
          required={required}

        >
          <MenuItem value={0}>
            <em> {<FormattedMessage id="label.search" />} </em>
          </MenuItem>

          {options.map(item => {
            return <MenuItem key={item.id} value={item.id}>{item.title ? item.title :  item.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
}
export default injectIntl(SimpleSelect);
SimpleSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.number,
  disabled : PropTypes.bool
};
