import { Grid, TextField } from "@material-ui/core";
import React from "react";
import SimpleCheckBox from "../checkbox/check";

export default function HirerForm(props) {

    const { intl, hirer } = props
    const handleInputChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        props.handleInputChange(key, value)
    }

    const handleCheck = (statename, value) => {
        props.handleInputChange(statename, value)
    }

    return (
        <Grid container item spacing={3}>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "name" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="name"
                    onChange={handleInputChange}
                    value={hirer.name ?? ""}
                    required
                />
            </Grid>
            <Grid container spacing={4} item>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "hirer.document" })}
                        inputProps={{ maxLength: 255 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="document"
                        onChange={handleInputChange}
                        value={hirer.document ?? ""}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "phone" })}
                        autoComplete="fname"
                        variant="outlined"
                        name="phone"
                        onChange={handleInputChange}
                        value={hirer.phone ?? ""}
                        inputProps={{ maxLength: 10, minLength: 10 }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "email" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="email"
                    onChange={handleInputChange}
                    value={hirer.email ?? ""}
                    source="email"
                    type="email"
                    required
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "access.key" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="accesskey"
                    onChange={handleInputChange}
                    value={hirer.accesskey ?? ""}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "hirer.responsible.name" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="responsiblename"
                    onChange={handleInputChange}
                    value={hirer.responsiblename ?? ""}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "hirer.responsible.phone" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="responsiblephone"
                    onChange={handleInputChange}
                    value={hirer.responsiblephone ?? ""}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "hirer.responsible.mail" })}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="fname"
                    variant="outlined"
                    name="responsiblemail"
                    onChange={handleInputChange}
                    value={hirer.responsiblemail ?? ""}
                    source="email"
                    type="email"
                />
            </Grid>
            <Grid style={{ textAlign: "left" }} item xs={2} sm={6}>
                <SimpleCheckBox
                    label={intl.formatMessage({ id: "active" })}
                    name="active"
                    stateName="active"
                    changeSelect={handleCheck}
                    selected={hirer.active ?? false}
                />
            </Grid>
            <Grid style={{ textAlign: "left" }} item xs={2} sm={6}>
                <SimpleCheckBox
                    label={intl.formatMessage({ id: "blocked" })}
                    name="blocked"
                    stateName="blocked"
                    changeSelect={handleCheck}
                    selected={hirer.blocked ?? false}
                />
            </Grid>
        </Grid>
    )
}