import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';

const ApiFieldService = axios.create({
    baseURL: process.env.REACT_APP_API_FIELDSERVICE,
});

ApiFieldService.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
ApiFieldService.defaults.headers.post['Accept']='application/json';
ApiFieldService.defaults.headers.post['Content-Type'] ='application/json';

let token = localStorage.getItem('accesstoken');

ApiFieldService.logoff = function (){
  userSessionActions.removeUserSession();
  document.location.href='/?sessão expirada';
}

ApiFieldService.kickoff = function (result){
    if((result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
      if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
        userSessionActions.removeUserSession();
        document.location.href='/?sessão expirada';
      }
    } 
    if((result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
      if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
        userSessionActions.removeUserSession();
        document.location.href='/?sessão expirada';
      }
    } 

}
//console.log(token);
ApiFieldService.defaults.headers.common['Authorization']='Bearer '+token;

export default ApiFieldService;
