

import React, { Component } from 'react';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@material-ui/core/styles';
                import { Styles } from '../../styles/material-styles';
                import { AbilityContext } from '../../config/ability-context'
                import Base from '../../components/layout-base/base';
                import Typography from '@material-ui/core/Typography';
                
                import { Link } from 'react-router-dom';
                import PageNotFound from '../../images/logo-cmtech.png';
                import LogoMarca from '../../images/logo-cmtech-small-2.png';
                

class Page404 extends Component {
    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps){
    }    

    componentDidMount() {
    }
    
    render() {
        return (
                <center>

                        <Typography variant="h1" className={this.props.class}>
                            <div className={this.props.class} style={{ color:'grey' }}>
                                404
                            </div>
                        </Typography>

                        <Typography variant="h3" className={this.props.class}>
                        <div className={this.props.class} style={{ color:'grey' }}>
                            Página não encontrada! :(
                        </div>
                        </Typography>

                </center>
            )
    }
}

Page404.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

export default connect(mapStateToProps)(withStyles(Styles)(Page404));
Page404.contextType = AbilityContext;