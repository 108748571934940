import Api from './api';
const url = `/hirer/`

export const getHirer = async (id = 0) => {
  let data = [];
  if (id != 0) {
    await Api.get('/hirer/' + id)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err.response;
      });
  } else {
    await Api.get('/hirer')
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err;
      });
  }
  Api.kickoff(data);
  return data;
};

export const getAllHirer = async (id = 0) => {
  let data = [];
  if (id != 0) {
    await Api.get('/hirer/' + id)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err.response.data;
      });
  } else {
    await Api.get('/hirer')
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err.response ? err.response.data : err;
      });
  }
  Api.kickoff(data);
  return data;
};

export const getHirersByUser = async (userId) => {
  let data = [];
  if (userId != 0) {
    await Api.get('/hirer/user/' + userId)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err;
      });
  }
  Api.kickoff(data);
  return data;
};

export const createHirer = async (hirer) => {
  let data = []
  await Api.post(url + `new`, hirer)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response.data
    })
  Api.kickoff(data)
  return data
}

