import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { ListAlt, QueuePlayNext, Settings } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as Service from "../../services/hirer.service";
import * as componentActions from "../../redux/actions/hirers";
import { FormattedMessage, injectIntl } from "react-intl";
import NavTabs from "../../components/tab/tab";
import Contracts from "./components/contracts-list";
import HirerConfigsComponent from "./components/hirer-configs-list";
import { Computer } from "@material-ui/icons"
import HirerForm from "../../components/hirer-form";

class HirerEdit extends Component {
  constructor(props) {
    super(props);

    const { userSession, hirers } = this.props;

    if (!hirers.hirer) {
      this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirers` })
    }

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });
    
    this.initialState = {
      updateduser: 0,
      id: 0,
      name: "",
      email: "",
      active: false,
      blocked: false,
      document: "",
      accesskey: "",
      phone: "",
      responsiblename: "",
      responsiblemail: "",
      responsiblephone: ""
    }

    this.state = {
      hirer: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      error: false,
      errorMessage: {}
    };

    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.finishLoading = this.finishLoading.bind(this)
    this.setError = this.setError.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const { intl } = this.props
    this.setLoading()

    const result = await Service.getHirer(this.props.hirers.hirer.id)
    if (result.success) {
      this.setState((prevState) => ({
        hirer: {
          id: result.data.id,
          name: result.data.name ?? "",
          email: result.data.email ?? "",
          active: result.data.active,
          blocked: result.data.blocked,
          document:result.data.document,
          accesskey: result.data.accesskey,
          phone: result.data.phone ?? "",
          responsiblename: result.data.responsiblename ?? "",
          responsiblemail: result.data.responsiblemail ?? "",
          responsiblephone: result.data.responsiblephone ?? ""
        },
        loading: false,
      }))
    } else {
      this.setError(result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  setLoading() {
    this.setState({ loading: true })
  }

  finishLoading() {
    this.setState({ loading: false })
  }

  setError(err) {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: "error",
      notificationMessage: err
    })
  }
  handleInputChange(name, value) {
    const intl = this.props.intl;

    this.setState((prevState) => ({
      hirer: {
        ...prevState.hirer,
        [name]: value,
      },
    }));

    if (name === "phone") {
      let pVal = this.mtel(value)
      this.setState((prevState) => ({
        hirer: {
          ...prevState.hirer,
          ["phone"]: this.mtel(value),
        },
      }));
      let isError = false;
      if (pVal.length == 0) {
        isError = false;
      } else if (pVal.length < 14) {
        isError = true;
        this.setState({
          error: true,
          errorMessage: { phone: intl.formatMessage({ id: "phone.error.min" }) }
        })
      } else if (pVal.length > 15) {
        isError = true;
        this.setState({
          error: true,
          errorMessage: { phone: intl.formatMessage({ id: "phone.error.max" }) }
        })
      } else {
        isError = false;
      }
      if (!isError) {
        this.setState({
          error: false,
          errorMessage: {}
        })
      }
    }
    if (name === "responsiblephone") {
      this.setState((prevState) => ({
        hirer: {
          ...prevState.hirer,
          ["responsiblephone"]: this.mtel(value),
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        hirer: {
          ...prevState.hirer,
        },
      }));
    } else {
      this.setState((prevState) => ({
        hirer: {
          ...prevState.hirer,
        },
      }));
    }

    for (let key in this.state.hirer) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.hirer[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else {
        data.append(key, this.state.hirer[key]);
      }
    }

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    Api.put("/hirer", data, config)

      .then((result) => {
        if (result.data.success) {
          const formattedList = result.data.data;

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: [],
          });
          this.props.addCurrent(formattedList);
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  handleClick = async (e) => {
    this.setState({ loading: true });
    const intl = this.props.intl;
    const { hirers } = this.props;

    let data = new FormData();
    data.append("hirerEmail", hirers.hirer.email);

    Api.put("hirers/enviaremail", data)
      .then((response) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Email Enviado",
          item: this.initialState,
          files: [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : "Erro ao processar a solicitação.",
        });
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      hirer: {
        ...prevState.hirer,
        [stateName]: value,
      },
    }));
    this.setState({ loading: false });
  }
  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, intl } = this.props;
    const { hirer } = this.state;

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="xl" style={{ padding: 0 }}>
            <CssBaseline />
            <div style={{ marginTop: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid container item sm={12}>
                  <Grid container item sm={12} justify="center">
                    <Avatar className={classes.avatar}>
                      <QueuePlayNext />
                    </Avatar>
                  </Grid>
                  <Grid container item sm={12} justify="center">
                    <Typography component="h1" variant="h5">
                      {<FormattedMessage id="edit.item" />} {hirer.name}
                      <Title />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <NavTabs
                    icons={[
                      <Computer />,
                      <ListAlt />,
                      <Settings />
                    ]}
                    components={[
                      <form
                        displayname={intl.formatMessage({ id: "general.info" })}
                        name="myForm"
                        className={classes.form}
                        onSubmit={this.handleSubmit}
                        encType="multipart/form-data"
                      >
                        <Grid container spacing={4} alignContent="center" direction="column">
                          <Grid container item xs={12} sm={6}>
                            <HirerForm hirer={hirer} intl={intl} classes={classes} handleInputChange={this.handleInputChange} />
                          </Grid>
                          <Grid container item xs={12} sm={6} justify="flex-end" style={{ padding: 0 }}>
                            <Grid item sm={3}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                              >
                                {<FormattedMessage id="save" />}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>,
                      <div style={{ padding: 0 }} displayname={intl.formatMessage({ id: "contracts" })}>
                        <Contracts
                          hirer={hirer}
                          history={this.props.history}
                          match={this.props.match}
                          intl={intl}
                          applications={this.state.applications}
                          plans={this.state.plans}
                          periods={this.state.periods}
                          setLoading={this.setLoading} finishLoading={this.finishLoading} setError={this.setError}
                        />
                      </div>,
                      <div displayname={intl.formatMessage({ id: "menu.configs" })}>
                        <HirerConfigsComponent
                          history={this.props.history}
                          match={this.props.match}
                          intl={intl}
                          setLoading={this.setLoading} finishLoading={this.finishLoading} setError={this.setError}
                        />
                      </div>
                    ]}
                  />
                </Grid>
              </Grid>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div >
    );
  }
}
HirerEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  hirers: state.hirers
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HirerEdit))
);
