import React, { Component } from "react";
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import Api from "../../services/api";
import { Overlay } from "../../styles/global";

import { bindActionCreators } from 'redux';

import CardContent from '@material-ui/core/CardContent';

import Footer from '../../components/footer';
import { FormattedMessage, injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

class Dashboard extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.isClient = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.onlyAdmOrganization = false;
    this.percentSlasisCoordenator = false;
    this.isTechinical = false;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true;
      }
    });
    if (this.AdmOrganization == true && this.globalAdm == false) {
      this.onlyAdmOrganization = true;
    }

    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    const listMonthts = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    this.state = {
      
      grid: 4,
      grid12: 12,
      WindowSize: window.innerWidth * 0.9,
      maxWidth: '100%',
      width: '100%',
      loading: false,
    };

    this.handleResize = this.handleResize.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth * 0.9 })

  }


  async componentDidMount() {
    //window.addEventListener("resize", this.handleResize);

    //this.handleResize = this.handleResize.bind(this);

  }

  render() {
    const { classes, headerMenu, userSession } = this.props;
    const intl = this.props.intl;
    const ref = React.createRef();

    // const styles = StyleSheet.create({
    //   page: {
    //     flexDirection: 'row',
    //     fontSize:15
    //   },
    //   section: {
    //     margin: 10,
    //     padding: 10,
    //     flexGrow: 1
    //   },
    //   width:{ width :"100%",height:"20px" }
    // });
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} style={{ minWidth: '300px' }} />
          
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

        </main>
        <Footer />

      </div>

    );
  }
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Dashboard)));
