import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { InputLabel, TextField , FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const LISTBOX_PADDING = 8; // px

const toggleItemActive = ({ doSomething, value }) => {
  //console.log(doSomething);
  //console.log(value);
};

function renderRow(props) {
  const { data, index, style } = props;
  //console.log(data);
  //const { toggleItemActive } = props;
  const item = data[index];
  //console.log(item);
  //item.props.children.props.children=item.props.children.props.children.name;
  //const el = React.createElement('', {noWrap:true}, item.props.children.props.children.name);
  //console.log(el);
  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
    //onClick: (index) => toggleItemActive(index),
    children: item.props.children.props.children.parentname ?  item.props.children.props.children.parentname : item.props.children.props.children.name,
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  //console.log(children);
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('xs'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 70 : 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };
  //console.log(itemData);
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}


const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];


const useStyles = makeStyles(theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },  
}));


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SimpleAutoComplete(props) {


  const { label, options, optionsText, stateName, changeSelect, selected, required, disabled, created = false } = props;
 
  //console.log(options);
  const defaultvalue = {id:0, name:''};
  const classes = useStyles();
  
  const [opt, setOption] = React.useState(selected ? selected : defaultvalue);
  
  
  React.useEffect(() => {
    setOption(selected ? selected : defaultvalue);
  }, [selected]);

  
  const handleChange = (event,value) => {
    //console.log(value);
    if(value == null){
      value= {id:0, name:''};
    }
    setOption(value);
    changeSelect(stateName, value);
  };
  function getIds(){
    const idList = options.map(item => {
      return item.id
    });
    return idList;
 
  };
  function getNames(){
    const idList = options.map(item => {
      return item.parentname ?  item.parentname : item.name
    });
    return idList;

  }  

  function Result(){

      return (<Autocomplete
          id="combo-box"
          options={options.sort((a, b) => -b.order.localeCompare(a.order))}
          disableListWrap
          classes={classes}
          ListboxComponent={ListboxComponent}
          value={opt}
          groupBy={(option) => option.order}
          getOptionLabel={option =>  option.parentname}
          getOptionDisabled={!created ? option => option.cancreatecalled === false : option => option }
          onChange={handleChange}
          style={{ fullWidth: true }}
          disabled={disabled}
          renderInput={params => (
            <TextField {...params} label={label} fullWidth />
          )}
          renderOption={option => <Typography noWrap>{option}</Typography>}
        />     
      );
    
  }

  return (
        <Result />
      
  );
}

SimpleAutoComplete.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.object,
    checkbox: PropTypes.bool,
    created: PropTypes.bool
  };