import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hirer from './hirer';
import Contract from './contract';
import HirerConfig from './hirer-config';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Confirmation from './confirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(intl) {
  return [
    intl.formatMessage({ id: "hirer.name" }),
    intl.formatMessage({ id: "contract" }),
    intl.formatMessage({ id: "menu.configs" }),
    intl.formatMessage({ id: "confirmation" }),
  ]
}

function getStepContentTitle(step) {
  switch (step) {
    case 0:
      return 'Informações sobre o contratante';
    case 1:
      return 'Definição do contrato';
    case 2:
      return 'Configuração';
    default:
      return 'Resumo';
  }
}

function getStepContent(step, props,) {
  switch (step) {
    case 0:
      return <Hirer props={props} />
    case 1:
      return <Contract props={props} />
    case 2:
      return <HirerConfig props={props} />
    case 3:
      return <Confirmation props={props} />
    default:
      return 'Finalização'
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps(props.intl)

  const handleNext = () => {
    if (activeStep + 1 == steps.length) {
      props.changeValues("openDialog", true)
    } else {
      props.changeValues("step", activeStep + 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    props.changeValues("step", activeStep - 1)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const newProps = { ...props, handleBack, handleNext }

  const handleReset = () => {
    setActiveStep(0)
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center" }}>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {props.state.finishmsg}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReset}
              className={classes.button}
            >
              Início
            </Button>
          </div>
        ) : (
          <div>
            <Typography>{getStepContentTitle(activeStep)}</Typography>
            <Grid container spacing={4}>
              <Grid item>
                {getStepContent(activeStep, newProps)}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}