import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import HeaderMenu from "../../components/header-menu";
import * as componentActions from '../../redux/actions/hirer-config';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { CssBaseline, Grid, Container, withStyles, Button, Typography, CircularProgress } from "@material-ui/core";
import { Styles } from "../../styles/material-styles";
import { FormattedMessage, injectIntl } from "react-intl";
import { Settings } from "@material-ui/icons";
import * as HirerService from "../../services/hirer.service";
import * as ConfigService from "../../services/hirerConfig.service";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import { browserHistory } from "../../helpers/history";
import HirerConfigForm from "../../components/hirer-config-form";
import Api from "../../services/api";

class HirerConfigRegistration extends Component {
    constructor(props) {
        super(props)

        let pwd = ""
        const size = 8
        for (let i = 0; i < size; i++) {
            let char = ''
            do {
                const charValue = Math.floor(Math.random() * (126 - 33 + 1)) + 33
                char = String.fromCharCode(charValue)
            } while (char === '"' || char === "'" || char === ';' || char === '`')
            pwd += char
        }

        const domain = { id: 1, name: "mexx.ai" }
        const dbPort = "5432"

        this.initialState = {
            hirer: props.hirer,
            email: "",
            workspace: "",
            urlApiCube: ".2do.api." + domain.name,
            urlFrontCube: ".2do." + domain.name,
            urlApiVertex: ".2see.api." + domain.name,
            urlFrontVertex: ".2see." + domain.name,
            urlApiCone: ".2talk.api." + domain.name,
            urlFrontCone: ".2talk." + domain.name,
            urlApiCubeCalledService: ".calledservice.api." + domain.name,
            urlFrontCubeCalledService: ".calledservice." + domain.name,
            urlApiCubeReports: ".reports.api." + domain.name,
            urlApi2TalkService: ".2talk.api." + domain.name,
            domainid: 0,
            cloudProviderConfigId: 0,
            containerConfig: [],
            dbUserName: "",
            dbName: "",
            dbPassword: pwd,
            dbPort: dbPort,
            dbHost: "",
            stringConnection: `Server=;Port=${dbPort}DataBase=;Uid=;Pwd=${pwd};Integrated Security=true;Pooling=false;CommandTimeout=120;`,
        }

        this.state = {
            item: this.initialState,
            domainOptions: [],
            applications: [],
            cloudProviders: [],
            containerImages: [],
            domain: {},
            cloudProvider: {},
            cloudProviderConfig: {},
            checkedApplications: [1, 2, 3],
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            file: null,
            optionsList: [],
            selected: props.hirer == null ? false : true,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.changeSimpleAutocompleteValues = this.changeSimpleAutocompleteValues.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
        this.handleChangeWorkspace = this.handleChangeWorkspace.bind(this)
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    async getItens() {
        this.setState({ loading: true })
        let hirers = await HirerService.getAllHirer()
        hirers = hirers.data.map(hirer => ({
            id: hirer.id,
            name: hirer.name,
        }))
        let applications, domains, cloudProviders, containerImages, containerConfigs, errors = []

        await Api.get("/applications")
            .then(result => {
                if (result.data.success) {
                    applications = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/domain")
            .then(result => {
                if (result.data.success) {
                    domains = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/cloudprovider/withconfigs")
            .then(result => {
                if (result.data.success) {
                    cloudProviders = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/containerimage")
            .then(result => {
                if (result.data.success) {
                    containerImages = result.data.data
                }
                else {
                    console.log(result.data.errors[0])
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })

        await Api.get("/defaultcontainerconfig/withimages")
            .then(result => {
                if (result.data.success) {
                    containerConfigs = result.data.data
                }
                else {
                    console.log(result.data.errors[0])
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        if (errors.length <= 0) {
            let containerConfig = []
            this.state.checkedApplications.map(app => {
                let container = {}
                switch (app) {
                    case 1:
                        container = containerConfigs.find(c => c.containerimage.applicationid == 1)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => c.containerimage.applicationid == 6)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => c.containerimage.applicationid == 9)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break
                    case 2:
                        container = containerConfigs.find(c => c.containerimage.applicationid == 2)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break
                    case 3:
                        container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 1))
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 2))
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break;
                }
            })
            this.setState((prevState) => ({
                loading: false,
                applications: applications,
                domainOptions: domains,
                cloudProviders: cloudProviders,
                containerImages: containerImages,
                cloudProvider: cloudProviders[0],
                domain: domains[0],
                cloudProviderConfig: cloudProviders[0].cloudproviderconfigs[0],
                optionsList: hirers,
                item: {
                    ...prevState.item,
                    domainid: domains[0].id,
                    cloudProviderConfigId: cloudProviders[0].cloudproviderconfigs[0].id,
                    containerConfig: containerConfig
                }
            }))
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: errors[0]
            })
        }
    }

    handleInputChange(e) {
        const name = e.target.name
        const value = e.target.value

        switch (name) {
            case "workspace":
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        urlApiCube: value + ".2do.api." + prevState.domain.name,
                        urlFrontCube: value + ".2do." + prevState.domain.name,
                        urlApiVertex: value + ".2see." + prevState.domain.name,
                        urlFrontVertex: value + ".2see." + prevState.domain.name,
                        urlApiCone: value + ".2talk.api." + prevState.domain.name,
                        urlFrontCone: value + ".2talk." + prevState.domain.name,
                        urlApiCubeCalledService: value + ".calledservice.api." + prevState.domain.name,
                        urlFrontCubeCalledService: value + ".calledservice." + prevState.domain.name,
                        urlApiCubeReports: value + ".reports.api." + prevState.domain.name,
                        urlApi2TalkService: value + ".2talk.api." + prevState.domain.name,
                    }
                }))
                break

            case "dbUserName":
                var stringConnection = this.state.item.stringConnection.split(";")
                const i = stringConnection.findIndex(s => s.includes("DataBase"))
                const l = stringConnection.findIndex(s => s.includes("Uid"))
                stringConnection[i] = "DataBase=" + value
                stringConnection[l] = "Uid=" + value
                stringConnection = stringConnection.join(";")
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        dbName: value,
                        stringConnection: stringConnection,
                    }
                }))
                break

            case "dbName":
                var stringConnection = this.state.item.stringConnection.split(";")
                const k = stringConnection.findIndex(s => s.includes("DataBase"))
                stringConnection[k] = "DataBase=" + value
                stringConnection = stringConnection.join(";")
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        stringConnection: stringConnection,
                    }
                }))
                break

            case "dbPassword":
                var stringConnection = this.state.item.stringConnection.split(";")
                const j = stringConnection.findIndex(s => s.includes("Pwd"))
                stringConnection[j] = "Pwd=" + value
                stringConnection = stringConnection.join(";")
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        stringConnection: stringConnection,
                    }
                }))
                break

            case "dbPort":
                var stringConnection = this.state.item.stringConnection.split(";")
                const m = stringConnection.findIndex(s => s.includes("Port"))
                stringConnection[m] = "Port=" + value
                stringConnection = stringConnection.join(";")
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        stringConnection: stringConnection,
                    }
                }))
                break

            case "dbHost":
                var stringConnection = this.state.item.stringConnection.split(";")
                const n = stringConnection.findIndex(s => s.includes("Server"))
                stringConnection[n] = "Server=" + value
                stringConnection = stringConnection.join(";")
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value,
                        stringConnection: stringConnection,
                    }
                }))
                break

            default:
                this.setState((prevState) => ({
                    item: {
                        ...prevState.item,
                        [name]: value
                    }
                }))
                break
        }
    }

    handleContainerConfigChange(key, value, index) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                containerConfig: prevState.item.containerConfig.map((c, i) => {
                    if (i == index) {
                        return {
                            ...prevState.item.containerConfig[i],
                            [key]: value
                        }
                    } else {
                        return c
                    }
                })
            }
        }))
    }

    handleChangeSelect(name, value) {
        switch (name) {
            case "domain":
                const domain = this.state.domainOptions.find(sd => sd.id == value)
                this.setState((prevState) => ({
                    domain: domain,
                    item: {
                        ...prevState.item,
                        urlApiCube: prevState.item.workspace + ".2do.api." + domain.name,
                        urlFrontCube: prevState.item.workspace + ".2do." + domain.name,
                        urlApiVertex: prevState.item.workspace + ".2see." + domain.name,
                        urlFrontVertex: prevState.item.workspace + ".2see." + domain.name,
                        urlApiCone: prevState.item.workspace + ".2talk.api." + domain.name,
                        urlFrontCone: prevState.item.workspace + ".2talk." + domain.name,
                        urlApiCubeCalledService: prevState.item.workspace + ".calledservice.api." + domain.name,
                        urlFrontCubeCalledService: prevState.item.workspace + ".calledservice." + domain.name,
                        urlApiCubeReports: prevState.item.workspace + ".reports.api." + domain.name,
                        urlApi2TalkService: prevState.item.workspace + ".2talk.api." + domain.name,
                        domainid: domain.id,
                    }
                }))
                break

            case "cloudProvider":
                const provider = this.state.cloudProviders.find(p => p.id == value)

                this.setState((prevState) => ({
                    cloudProvider: provider,
                    item: {
                        ...prevState.item,
                        cloudProviderConfig: provider.cloudproviderconfigs[0] ?? {},
                        cloudProviderConfigId: provider.cloudproviderconfigs[0] ? provider.cloudproviderconfigs[0].id : "",
                    }
                }))
                break

            case "cloudProviderConfig":
                const providerConfig = this.state.cloudProvider.cloudproviderconfigs.find(pc => pc.id == value)

                this.setState((prevState) => ({
                    cloudProviderConfig: providerConfig,
                    item: {
                        ...prevState.item,
                        cloudProviderConfigId: providerConfig.id
                    }
                }))
        }
    }

    changeSimpleAutocompleteValues(name, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }))

        const workspace = value.name.split(" ")[0].toLowerCase()
        this.handleChangeWorkspace(workspace)
    }

    handleChangeWorkspace(workspace) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                workspace: workspace,
                urlApiCube: workspace + ".2do.api.mexx.ai",
                urlFrontCube: workspace + ".2do.mexx.ai",
                urlApiVertex: workspace + ".2ee.api.mexx.ai",
                urlFrontVertex: workspace + ".2ee.mexx.ai",
                urlApiCone: workspace + ".2talk.api.mexx.ai",
                urlFrontCone: workspace + ".2talk.mexx.ai",
            }
        }))
    }

    async handleSubmit(e) {
        e.preventDefault()
        const intl = this.props.intl
        let data = new FormData()
        for (let key in this.state.item) {
            if (key.toString().toLowerCase().indexOf("hirer") > -1) {
                data.append("hirerId", this.state.item[key].id)
                data.append("name", this.state.item[key].name)
            } else {
                data.append(key, this.state.item[key])
            }
        }
        try {
            const result = await ConfigService.createHirerConfig(data)
            if (result.success) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "add.success" })
                })
                window.location.reload()
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.errors && result.errors[0]
                            ? result.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
            });
        }
    }

    componentDidMount() {
        this.getItens()
    }

    render() {
        const { classes, headerMenu, intl } = this.props
        const { item, domainOptions, applications, checkedApplications, cloudProviders, cloudProvider, containerImages, optionsList, selected } = this.state

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={browserHistory.goBack}
                                    >
                                        {<FormattedMessage id="back" />}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Settings />
                            <Typography component="h1" variant="h5">
                                {<FormattedMessage id="add.item" />}
                            </Typography>
                            <form name="hirerConfigForm" className={classes.form} onSubmit={this.handleSubmit}>
                                <HirerConfigForm
                                    hirerConfig={item} optionsList={optionsList} selected={selected} intl={intl} applications={applications}
                                    domainOptions={domainOptions} cloudProvider={cloudProvider} checkedApplications={checkedApplications} cloudProviders={cloudProviders}
                                    handleInputChange={this.handleInputChange} changeSimpleAutocompleteValues={this.changeSimpleAutocompleteValues}
                                    handleChangeSelect={this.handleChangeSelect} handleContainerConfigChange={this.handleContainerConfigChange}
                                    containerImages={containerImages}
                                />
                                <Grid container spacing={4} justify="flex-end">
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            <FormattedMessage id="save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                            message={this.state.notificationMessage}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}
HirerConfigRegistration.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    hirer: state.hirerconfig.hirerconfig.hirer,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HirerConfigRegistration)))