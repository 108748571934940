import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import SimpleCheckBox from "../../../components/checkbox/check";

export default function Email(props) {
    const { classes, config, handleInputChange, newchangeValues, handleClick } = props

    return (
        <Grid container justify="center">
            <Grid item sm={4}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="frommail"
                                variant="outlined"
                                type="email"
                                fullWidth
                                id="frommail"
                                label="Email De"
                                value={config.frommail}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="hostmail"
                                variant="outlined"
                                fullWidth
                                id="hostmail"
                                label="Host"
                                value={config.hostmail}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="portmail"
                                variant="outlined"
                                type="number"
                                fullWidth
                                id="portmail"
                                label="Porta"
                                value={config.portmail}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="usermail"
                                variant="outlined"
                                type="email"
                                fullWidth
                                id="usermail"
                                label="Host"
                                value={config.usermail}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="passwordmail"
                                variant="outlined"
                                fullWidth
                                id="passwordmail"
                                label="Senha"
                                type='password'
                                placeholder='Deixe em branco para não alterar'
                                value={config.passwordmail}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <SimpleCheckBox
                                label="Ativar SSL"
                                name='enablessl'
                                stateName='enablessl'
                                changeSelect={newchangeValues}
                                selected={config.enablessl} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="mailtest"
                                variant="outlined"
                                fullWidth
                                id="mailtest"
                                label="Enviar email teste para"
                                value={config.mailtest}
                                onChange={handleInputChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} justify="flex-start">
                        <Grid item xs={12} sm={8} align-items-xs-center={'true'}>
                            <Button
                                onClick={handleClick}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit} >
                                Testar Envio de Email
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={4}>
                </Grid>
            </Grid>
        </Grid>
    )
}