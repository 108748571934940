import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../../styles/material-styles";
import Api from "../../../services/api";
import * as Service from "../../../services/contract"
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ContractForm from "../../../components/contract-form";
import SimpleSelect from "../../../components/select/simple";

class Contract extends Component {

  constructor(props) {
    super(props)

    const { userSession, intl } = this.props

    this.initialState = Object.entries(props.props.state.contractData).length > 0 ?
      this.props.props.state.contractData
      :
      {
        id: 0,
        hirerid: this.props.props.state.hirerid,
        startdate: "01/01/2023",
        billingstartdate: "01/01/2023",
        enddate: "01/01/2023",
        contractplanperiodicities: [],
        contractstatusid: 0,
        inactive: true,
      }

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      file: null,
      error: false,
      errorMessage: {},
      applications: [],
      plans: [],
      periodicities: [],
      contractstatuses: [],
      daysexpire: 30,
      selectedContract: props.props.state.contractid,
      hirerContracts: [{ id: 0, name: intl.formatMessage({ id: "new.contract" }) }],
    }

    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.handleSelectContract = this.handleSelectContract.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }
  componentDidMount() {
    this.getData()
  }

  handleNext() {
    let contract = JSON.parse(JSON.stringify(this.state.item))
    let isOk = true
    if (!contract.id > 0) {
      contract.contractplanperiodicities.map(cpp => {
        if (
          cpp.planperiodicity.checked && (cpp.agentlimitquantity == "" || cpp.additionalvalueagent == "" ||
          cpp.disklimitusage == "" || cpp.planvalue == "" ||
          cpp.attendancevalue == "" || cpp.attendancelimitpermonth == "")) {
            isOk = false
          }
      })
    }
    if (isOk) {
      this.props.props.changeValues("contractData", this.state.item)
      this.props.props.handleNext()
    } else {
      this.props.props.setError(this.props.intl.formatMessage({ id: "error.required"}))
    }
  }

  async getData() {
    this.setState({ loading: true })

    let applications, plans, periodicities, contractstatuses, hirerContracts, errors = []

    applications = [
      {
          "id": 1,
          "name": "Mexx 2DO"
      },
      {
          "id": 2,
          "name": "Mexx 2TALK"
      },
      {
          "id": 3,
          "name": "Mexx 2SEE"
      },
    ]
    await Api.get("/plans")
      .then(result => {
        if (result.data.success) {
          plans = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        this.setError(err.response.data.errors[0])
        errors.push(err)
      })

    await Api.get("/periodicities")
      .then(result => {
        if (result.data.success) {
          periodicities = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        this.setError(err.response.data.errors[0])
        errors.push(err)
      })

    await Api.get("/contractstatuses")
      .then(result => {
        if (result.data.success) {
          contractstatuses = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        this.setError(err.response.data.errors[0])
        errors.push(err)
      })

    hirerContracts = await Service.getContractsFromHirer(this.props.props.state.hirerid)
    hirerContracts = hirerContracts.data.map(hc => {
      return ({
        ...hc,
        name: hc.id,
      })
    })

    if (errors.length <= 0) {
      if (this.props.props.state.contractid == 0 && this.state.item.contractplanperiodicities.length < 1) {
        const defaultStatusId = contractstatuses.find(c => c.name == "SOLICITADO").id
        applications.map(app => {
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              contractstatusid: defaultStatusId,
              contractplanperiodicities: [
                ...prevState.item.contractplanperiodicities,
                {
                  agentlimitquantity: "",
                  additionalvalueagent: "",
                  disklimitusage: "",
                  planvalue: "",
                  attendancelimitpermonth: "",
                  attendancevalue: "",
                  planperiodicity: {
                    applicationid: app.id,
                    periodicityid: periodicities[0].id,
                    planid: plans[0].id,
                    checked: false,
                  }
                }
              ]
            }
          }))
        })
      }
  
      this.props.props.changeValues("plans", plans)
      this.props.props.changeValues("periodicities", periodicities)
      this.props.props.changeValues("applications", applications)
  
      this.setState((prevState) => ({
        loading: false,
        applications: applications,
        plans: plans,
        periodicities: periodicities,
        contractstatuses: contractstatuses,
        hirerContracts: [...prevState.hirerContracts, ...hirerContracts],
      }))
    } else {
      console.log(errors)
      this.setError(errors[0])
    }
  }

  setError(error) {
    this.props.props.setError(error)
  }

  changeDate(key, value) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [key]: this.props.intl.formatDate(value)
      }
    }))
    if (key == "startdate") {
      this.updateEndDate(value, this.state.daysexpire)
    }
  }

  updateEndDate(startdate, daysexpire) {
    let newEndDate = new Date(startdate)
    newEndDate.setDate(newEndDate.getDate() + daysexpire)
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        enddate: this.props.intl.formatDate(newEndDate)
      }
    }))
  }

  formatDate(date) {
    const parts = date.split('/')
    const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`
    return newDate
  }

  handleChange(i, key, value) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        contractplanperiodicities: [
          ...prevState.item.contractplanperiodicities.map((cpp, index) => {
            if (i == index) {
              return {
                ...prevState.item.contractplanperiodicities[i],
                [key]: value
              }
            } else {
              return cpp
            }
          })
        ]
      }
    }))
  }

  handleChangeSelect(i, key, value) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        contractplanperiodicities: [
          ...prevState.item.contractplanperiodicities.map((cpp, index) => {
            if (i == index) {
              return {
                ...prevState.item.contractplanperiodicities[i],
                planperiodicity: {
                  ...prevState.item.contractplanperiodicities[i].planperiodicity,
                  [key]: value,
                }
              }
            } else {
              return cpp
            }
          })
        ]
      }
    }))
    if (key == "periodicityid") {
      let daysexpire = this.state.periodicities.find(p => p.id == value).daysexpire
      const periodicitiesDaysExpire = []
      for (var cppid in this.state.item.contractplanperiodicities) {
        const cpp = this.state.item.contractplanperiodicities[cppid]
        if (cpp.planperiodicity.checked && cppid != i) {
          periodicitiesDaysExpire.push(this.state.periodicities.find(p => p.id == cpp.planperiodicity.periodicityid).daysexpire)
        }
      }

      const startdate = this.stringToDate(this.state.item.startdate).toDateString()

      if (periodicitiesDaysExpire.every((dexp) => daysexpire > dexp)) {
        this.setState({
          daysexpire: daysexpire
        })
        this.updateEndDate(startdate, daysexpire)
      } else {
        daysexpire = Math.max(...periodicitiesDaysExpire)
        this.updateEndDate(startdate, daysexpire)
      }
    }
  }

  stringToDate(dataString) {
    var parts = dataString.split('/')
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10) - 1 // No objeto Date, os meses vão de 0 a 11
    var year = parseInt(parts[2], 10)
    const date = new Date(year, month, day)
    return date
  }

  handleSelectContract = async (stateName, value) => {
    let newContract
    if (value == 0) {
      newContract = {
        id: 0,
        hirerid: this.props.props.state.hirerid,
        startdate: "01/01/2023",
        billingstartdate: "01/01/2023",
        enddate: "01/01/2023",
        contractplanperiodicities: [],
        contractstatusid: this.state.contractstatuses[0].id,
      }
    } else {
      this.setState({ loading: true })
      newContract = await Service.getContractById(value)
      if (newContract.success) {
        newContract = newContract.data
        this.setState({ loading: false })
      } else {
        this.setError(newContract)
      }
    }
    newContract.contractplanperiodicities = this.state.applications.map(app => {
      const checkedCpp = newContract.contractplanperiodicities.find(cpp => cpp.planperiodicity.applicationid == app.id)
      return checkedCpp ? {
        ...checkedCpp,
        planperiodicity: {
          ...checkedCpp.planperiodicity,
          checked: true,
        }
      } : {
        agentlimitquantity: "",
        additionalvalueagent: "",
        disklimitusage: "",
        planvalue: "",
        attendancelimitpermonth: "",
        attendancevalue: "",
        planperiodicity: {
          applicationid: app.id,
          periodicityid: this.state.periodicities[0].id,
          planid: this.state.plans[0].id,
          checked: false
        }
      }
    })
    newContract.startdate = this.props.intl.formatDate(newContract.startdate)
    newContract.enddate = this.props.intl.formatDate(newContract.enddate)
    newContract.billingstartdate = this.props.intl.formatDate(newContract.billingstartdate)

    this.setState({
      item: newContract,
      selectedContract: value
    })
    this.props.props.changeValues("contractid", value)
  }

  render() {
    const { intl, classes, props } = this.props
    const { item, applications, plans, periodicities, contractstatuses, hirerContracts, selectedContract } = this.state

    return (

      <Grid container spacing={4}>
        <Grid item container spacing={3} style={{ marginTop: "20px" }} justify="center">
          <Grid item>
            <SimpleSelect
              options={hirerContracts}
              selected={selectedContract}
              changeSelect={this.handleSelectContract}
              stateName="Contract"
              label={intl.formatMessage({ id: "contract" })}
            />
            <FormHelperText>{intl.formatMessage({ id: "contract.select" })}</FormHelperText>
          </Grid>
          <Grid item>
            <ContractForm
              contract={item} intl={intl}
              applications={applications} contractstatuses={contractstatuses} plans={plans} periodicities={periodicities}
              changeDate={this.changeDate} handleChangeSelect={this.handleChangeSelect} handleChange={this.handleChange}
              readOnly={item.id > 0}
            />
          </Grid>
        </Grid>
        <Grid item container justify='space-between'>
          <Button onClick={props.handleBack} className={classes.button}>
            <FormattedMessage id="back" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            className={classes.button}
          >
            <FormattedMessage id="next" />
          </Button>
        </Grid>
      </Grid>
    )
  }
}
Contract.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(Contract))
)