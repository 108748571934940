import 'date-fns';
import React from 'react';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickerInline(props) {

    const { label, stateName, changeDate, currentDate, format = 'dd/MM/yyyy', disabled } = props;

    const [selectedDate, setSelectedDate] = React.useState();


    React.useEffect(() => {
        setSelectedDate(currentDate);
      }, [currentDate]);

    const handleDateChange = date => {
        setSelectedDate(date);
        changeDate(stateName, date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={format}
                    label={label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    disabled={disabled}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

MaterialUIPickerInline.propTypes = {
    label: PropTypes.string,
    stateName: PropTypes.string,
    changeDate: PropTypes.func,
    currentDate: PropTypes.string,
    format: PropTypes.string
};
