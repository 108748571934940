import React, { Component, Fragment } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Typography, CircularProgress, Container } from "@material-ui/core";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
    if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    } else if (this.props.userSession && this.props.userSession.user) {
      const email = this.props.userSession.user.email;
      this.setState(prevState => ({
        userLogged: true,
        passwordResetForm: {
          ...prevState.passwordResetForm,
          email
        }
      }));
    }
  }

  getUser(token) {
    this.setState({ loading: true });
    Api.get(`/users/password-recovery/${token}`)
      .then(result => {
        if (result.data.success) {
          const { email } = result.data.data;
          this.setState(prevState => ({
            loading: false,
            passwordResetForm: {
              ...prevState.passwordResetForm,
              email
            }
          }));
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
        });
      });
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (this.validatePassword()) {
      this.setState({ loading: true });
      const { email, password } = this.state.passwordResetForm;
      Api.post("/users/change-password", { email, password })
        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: "Senha cadastrada com sucesso!"
            });
            setTimeout(() => {
              this.props.history.push("/");
            }, 3000);
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
          });
        });
    }
  };

  validatePassword() {
    const { passwordResetForm } = this.state;
    if (passwordResetForm.password !== passwordResetForm.confirmPassword) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Senhas não correspondem!"
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { passwordResetForm, userLogged } = this.state;

    const containerBody = () => {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}> <CompareArrowsIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Redefina sua senha </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={passwordResetForm.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    inputProps={{ maxLength: 50, minLength: 6 }}
                    value={passwordResetForm.password}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmar Senha"
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-confirmPassword"
                    inputProps={{ maxLength: 50, minLength: 6 }}
                    value={passwordResetForm.confirmPassword}
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrar
              </Button>
              {!userLogged && (
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item>
                    <Link href="/" variant="body2"> Já possui conta? Entrar </Link>
                  </Grid>
                </Grid>
              )}
            </form>
          </div>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      );
    };

    if (userLogged) {
      return (
        <div className={classes.root}>
          <HeaderMenu />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: headerMenu.open,
            })}
          >
            <div className={classes.drawerHeader} />

            {containerBody()}
          </main>
        </div>
      );
    } else {
      return (
        <Fragment>
          {containerBody()}
        </Fragment>
      );
    }
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default connect(mapStateToProps)(withStyles(Styles)(PasswordReset));
