import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { QueuePlayNext, DeleteForever, Label } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import ApiCallCenter from "../../services/apiCallCenter";
import ApiFieldService from "../../services/apiFieldService";
import ApiWebhook from "../../services/apiWebhook";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";

import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import * as ServiceHirer from "../../services/hirer.service";
import * as ServiceCustomer from "../../services/customer.service";
import SimpleCheckBox from "../../components/checkbox/check";

class ActiveCustomer extends Component {
  constructor(props) {
    super(props);
    
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      hirerid: "",
      customerid: "",
      activeVertex: false,
      activeCube: false,
      linkCubeVertexLink: false,
    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      HirerList: [],
      CustomerList: [],
      vertexOk: false,
      cubeOk: false,
      webhookOk: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.customerList = this.customerList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.hirerList();
  }

  async hirerList(){
    this.setState({ loading: true });
    var hirer = await ServiceHirer.getHirer();
    
    if(hirer != null){
      if(hirer.data != null){
        this.setState({ loading: false, HirerList: hirer.data });
      }
    }
  }

  async customerList(hirer){
    this.setState({ loading: true });
    var customer = await ServiceCustomer.getCustomerByHirer(hirer.id);

    if(customer != null){
      if(customer.data != null){
        this.setState({ loading: false, CustomerList: customer.data });
      }else{
        this.setState({ loading: false, CustomerList: [] });
      }
    }else{
      this.setState({ loading: false, CustomerList: [] });
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));

  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    if(this.state.item.activeVertex){
      await this.startVertex();
    }

    if(this.state.item.activeCube){
      await this.startCube();
    }

    await this.startWebhook();
  };

  async startCube(){

    let data = new FormData();

    data.append("id", this.state.item.hirerid.id);
    data.append("name", this.state.item.hirerid.name);
    data.append("active", this.state.item.hirerid.active);
    data.append("document", this.state.item.hirerid.document);
    data.append("website", this.state.item.hirerid.website);
    data.append("responsiblename", this.state.item.hirerid.responsiblename);
    data.append("responsiblephone", this.state.item.hirerid.responsiblephone);
    data.append("responsiblemail", this.state.item.hirerid.responsiblemail);
    data.append("phone", this.state.item.hirerid.phone);
    data.append("blocked", this.state.item.hirerid.blocked);
    data.append("blockedate", this.state.item.hirerid.blockedate);
    data.append("activeddate", this.state.item.hirerid.activeddate);
    data.append("inactiveddate", this.state.item.hirerid.inactiveddate);
    data.append("accesskey", this.state.item.hirerid.accesskey);
    data.append("email", this.state.item.hirerid.email);

    let dataCustomer = new FormData();

    dataCustomer.append("id", this.state.item.customerid.id);
    dataCustomer.append("name", this.state.item.customerid.name);
    dataCustomer.append("active", this.state.item.customerid.active);
    dataCustomer.append("document", this.state.item.customerid.document);
    dataCustomer.append("website", this.state.item.customerid.website);
    dataCustomer.append("responsiblename", this.state.item.customerid.responsiblename);
    dataCustomer.append("responsiblephone", this.state.item.customerid.responsiblephone);
    dataCustomer.append("responsiblemail", this.state.item.customerid.responsiblemail);
    dataCustomer.append("phone", this.state.item.customerid.phone);
    dataCustomer.append("blocked", this.state.item.customerid.blocked);
    dataCustomer.append("blockedate", this.state.item.customerid.blockedate);
    dataCustomer.append("activeddate", this.state.item.customerid.activeddate);
    dataCustomer.append("inactiveddate", this.state.item.customerid.inactiveddate);
    dataCustomer.append("accesskey", this.state.item.customerid.accesskey);
    dataCustomer.append("email", this.state.item.customerid.email);

    await ApiFieldService.post("/hirer/saas", data)
      .then((result) => {
        if (result.data.success) {
          dataCustomer.append("hirerid", result.data.data.id);
          
          ApiFieldService.post("/customer/saas", dataCustomer)
            .then((result) => {
              if (result.data.success) {
                //Chamar o field
              } else {
                const intl = this.props.intl;
              
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage:
                    result.data && result.data.errors
                      ? result.data.errors[0]
                      : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(result);
              }
            })
            .catch((err) => {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }) + err.response,
              });
            });
            
        } else {
          const intl = this.props.intl;
         
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });
  }

  async startWebhook(){
    let data = new FormData();

    data.append("customerid", this.state.item.customerid.id);
    data.append("hirerid", this.state.item.hirerid.id);
    data.append("login", this.state.item.customerid.email);
    data.append("email", this.state.item.customerid.email);
    data.append("active", true);

    await ApiWebhook.post("/webhookuserlicences/saas", data)
      .then((result) => {
        if (result.data.success) {
          const intl = this.props.intl;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: this.state.notificationVariant == "error" ? intl.formatMessage({ id: "add.success" }) : this.state.notificationMessage,
          });
        } else {
          const intl = this.props.intl;
         
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });
  }

  async startVertex(){

    let data = new FormData();

    data.append("id", this.state.item.hirerid.id);
    data.append("name", this.state.item.hirerid.name);
    data.append("active", this.state.item.hirerid.active);
    data.append("document", this.state.item.hirerid.document);
    data.append("website", this.state.item.hirerid.website);
    data.append("responsiblename", this.state.item.hirerid.responsiblename);
    data.append("responsiblephone", this.state.item.hirerid.responsiblephone);
    data.append("responsiblemail", this.state.item.hirerid.responsiblemail);
    data.append("phone", this.state.item.hirerid.phone);
    data.append("blocked", this.state.item.hirerid.blocked);
    data.append("blockedate", this.state.item.hirerid.blockedate);
    data.append("activeddate", this.state.item.hirerid.activeddate);
    data.append("inactiveddate", this.state.item.hirerid.inactiveddate);
    data.append("accesskey", this.state.item.hirerid.accesskey);
    data.append("email", this.state.item.hirerid.email);

    let dataCustomer = new FormData();

    dataCustomer.append("id", this.state.item.customerid.id);
    dataCustomer.append("name", this.state.item.customerid.name);
    dataCustomer.append("active", this.state.item.customerid.active);
    dataCustomer.append("document", this.state.item.customerid.document);
    dataCustomer.append("website", this.state.item.customerid.website);
    dataCustomer.append("responsiblename", this.state.item.customerid.responsiblename);
    dataCustomer.append("responsiblephone", this.state.item.customerid.responsiblephone);
    dataCustomer.append("responsiblemail", this.state.item.customerid.responsiblemail);
    dataCustomer.append("phone", this.state.item.customerid.phone);
    dataCustomer.append("blocked", this.state.item.customerid.blocked);
    dataCustomer.append("blockedate", this.state.item.customerid.blockedate);
    dataCustomer.append("activeddate", this.state.item.customerid.activeddate);
    dataCustomer.append("inactiveddate", this.state.item.customerid.inactiveddate);
    dataCustomer.append("accesskey", this.state.item.customerid.accesskey);
    dataCustomer.append("email", this.state.item.customerid.email);

    await ApiCallCenter.post("/hirer/saas", data)
      .then((result) => {
        if (result.data.success) {
          dataCustomer.append("hirerid", result.data.data.id);
          ApiCallCenter.post("/customer/saas", dataCustomer)
            .then((result) => {
              if (result.data.success) {
                //Chamar o field
              } else {
                const intl = this.props.intl;
              
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage:
                    result.data && result.data.errors
                      ? result.data.errors[0]
                      : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(result);
              }
            })
            .catch((err) => {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }) + err.response,
              });
            });
            
        } else {
          const intl = this.props.intl;
         
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
         
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    if(stateName == "hirerid"){
      this.setState({
        CustomerList: []
      });
      await this.customerList(value);
    }

    this.setState({ loading: false });
  }

  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({id:"hirer.name"})}
                      options={this.state.HirerList}
                      stateName="hirerid"
                      changeSelect={this.changeValues}
                      selected={item.hirerid}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({id:"customer.name"})}
                      options={this.state.CustomerList}
                      stateName="customerid"
                      changeSelect={this.changeValues}
                      selected={item.customerid}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "vertex.active" })}
                      name="activeVertex"
                      stateName="activeVertex"
                      changeSelect={this.changeValues}
                      selected={item.activeVertex}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "cube.active" })}
                      name="activeCube"
                      stateName="activeCube"
                      changeSelect={this.changeValues}
                      selected={item.activeCube}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={0} sm={8}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
ActiveCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ActiveCustomer))
);
