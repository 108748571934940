import { Grid, TextField } from "@material-ui/core";
import React from "react";

export default function ApisEdit(props) {
    const { config, handleInputChange, intl } = props

    return (
        <Grid container justify="center">
            <Grid item sm={4}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: "apirecognition" })}
                                autoComplete='fname' variant='outlined' name='apirecognition'
                                onChange={handleInputChange}
                                value={config.apirecognition}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API 2FACE"
                                autoComplete='fname' variant='outlined' name='urlapi2faceservice'
                                onChange={handleInputChange}
                                value={config.urlapi2faceservice}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API File"
                                autoComplete='fname' variant='outlined' name='urlapifile'
                                onChange={handleInputChange}
                                value={config.urlapifile}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Prosody"
                                autoComplete='fname' variant='outlined' name='urlapiprosody'
                                onChange={handleInputChange}
                                value={config.urlapiprosody}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Videobride"
                                autoComplete='fname' variant='outlined' name='urlapivideobridge'
                                onChange={handleInputChange}
                                value={config.urlapivideobridge}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Meet"
                                autoComplete='fname' variant='outlined' name='urlapimeet'
                                onChange={handleInputChange}
                                value={config.urlapimeet}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Messenger"
                                autoComplete='fname' variant='outlined' name='urlapimessenger'
                                onChange={handleInputChange}
                                value={config.urlapimessenger}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Provider"
                                autoComplete='fname' variant='outlined' name='urlapiprovider'
                                onChange={handleInputChange}
                                value={config.urlapiprovider}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="URL API Cofo"
                                autoComplete='fname' variant='outlined' name='urlapicofo'
                                onChange={handleInputChange}
                                value={config.urlapicofo}
                                required
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}