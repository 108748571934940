import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import { Input } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table';
import Api from "../../services/api";
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/requestnumber';
import Footer from '../../components/footer';
import { FormattedMessage,injectIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { saveAs } from 'file-saver';

import Pagination from '@material-ui/lab/Pagination';  
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment'


class RequestNumber extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;


        const { userSession } = this.props;
        const intl = this.props.intl;

        let filters = [
            {name: "Contratante", id: "hirer"},
            {name: "Status", id:"status"},
            {name: "Data", id:"date"},
        ];
        
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            resultList: [],
            file : null,

            totalPages:0,
            page:1,
            pageStart:0,
            pageEnd:0,
            count:0,
            searchValue: '',
            filterSelected: 0,
            filters:filters
        };
        var globalAdm = false;
        this.AdmOrganization = false;
        this.hirerId = userSession.user.id;
        userSession.user.userrole.map((item, key) => {
            if(item.role.roletype ==0){
                globalAdm =true;
            }
            if(item.role.roletype ==1 && globalAdm == false){
                this.AdmOrganization =true;
            }
        });

        this.changeValues = this.changeValues.bind(this);

        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e,page) =>{
        this.setState({ page:page });
        this.getItens(page);
    }

    setPagination(count,page){
        let totalPage = Math.ceil (count/10);
        this.setState({ totalPages:totalPage })
        if(count > 0){
            if(page == 1){
                if(count < 10){
                    this.setState({pageStart:1,pageEnd:count});
            } else{
                this.setState({pageStart:1,pageEnd:10});
            }
                } else{
                let pageStart = ((page - 1) * 10) + 1;
                if(count >= page * 10){
                    this.setState({pageStart:pageStart,pageEnd:page * 10});
                } else{
                    this.setState({pageStart:pageStart,pageEnd:count});
                }
            }
        } else {
            this.setState({totalPages:0,page:1,count:0});
        }                  
    }

    onFieldChange(event) {
        this.setState({ [event.target.name]: event.target.value, page:1 });
    }

    callSearch(){
        if(this.state.filterSelected != 0){
            this.getItens(this.state.page);
        }                        
    }

    async clearFields(){
        await   this.setState({filterSelected: 0, searchValue: '', page:1})
        this.getItens(1);
    }


    async changeValues(stateName, value, text = '') {
        this.setState({filterSelected : value});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    }    

    componentDidMount() {
        this.getItens();
    }
    getObjectRolesIds(list){
        const idList = list.map(item => {
          return item.role.id
      });
      return idList;
     
    }
    getItens(page = 1) {
        this.setState({ loading: true });

        let url = `/registrationphonenumber?page=${page}`;
        
        if(this.state.searchValue != '' && this.state.filterSelected != 0){         
            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
        }
        
        Api.get(url)
            .then(result => {
                if (result.data.success) {
                    let count = result.data.count;
                    this.setState({count:count});
                    this.setPagination(count,page);
                    const intl = this.props.intl;

                    console.log(result.data.data)
                    console.log("result.data.data")

                    const formattedList = result.data.data.map(item => {

                        return {
                            ...item,
                            organizationName: item.instanceorganizationname,
                            phoneNumber: item.instancephonenumber,
                            date: moment(item.createdat).utcOffset("+00:00").format("DD/MM/YYYY HH:mm"),
                            status: item.requeststatus.name,
                            instancehirername: item.instancehirername,
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else{
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                Api.kickoff(err);
            });
    };

    onChangeFile(e) {
        this.setState({file:e.target.files[0]})
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    onFormSubmit = async e => {

        e.preventDefault();
        this.setState({ loading: true });
    
        let data = new FormData();
    
        if(this.state.file != null){

        data.append('file',this.state.file)
        let config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        };

            Api.post('/customers/planilhas', data, config)
            .then(result => {
              if (result.data.success) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({id:"add.success"}),
                    resultList: []
                  });
                  this.getItens();
              }
              else{
    
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
    
                });
                Api.kickoff(result);
    
              }          
            })
            .catch(err => {
              if(err.response && err.response.data.errors){
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
              }
              else{
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response
                });
              }
            });

        } else {
            const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: intl.formatMessage({id:"excel"})
                });
        }
          
            
      };

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        return (
            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                    style={{ marginBottom: 50 }}
                                >
                                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{padding: 10}}>
                        <CssBaseline />
                        <Grid container className={classes.mb20}  spacing={0}>
                        </Grid>
                        <Grid container spacing={6}> 
                            <Grid item xs={12} sm={12}>
                                <Card className="background-title-search">
                                    <CardContent>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">
                                                    Solicitações de registro de mensageria
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({id:"field"})} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected}/>
                                            </Grid>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            <Grid item >
                                                <TextField 
                                                    type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({id:"search"})}
                                                    onChange={this.onFieldChange}
                                                />
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{marginRight:4,marginLeft:8}}
                                                    onClick={this.callSearch} >
                                                    <Search />
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button} >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>                
                        </Grid>
                        <Can I="List" a="Customer">
                        <MyMaterialTable
                            title=""
                            columns={[
                                { title: "Id", field: 'id' },
                                { title: "Contratante", field: 'instancehirername' },
                                { title: "Nome da organização", field: 'organizationName' },
                                { title: "Telefone", field: 'phoneNumber' },
                                { title: "Data de Solicitação", field: 'date' },
                                { title: "Status", field: 'status' },
                            ]}
                            data={this.state.resultList}
                            rowClick={(event, rowData) => {
                                this.props.addCurrent(rowData);
                                this.props.history.push(`/${this.props.match.params.lang}/requestnumbermessaging-edit`);
                            }}
                        />
                        </Can>
                        <Can I='List' a='Customer'>
                                            
                                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                                <Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                                            </Grid>

                                            </Can> 
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
               <Footer /> 
            </div>
        );
    }
}

RequestNumber.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession
});

const mapDispatchToProps = dispatch => bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RequestNumber)));

RequestNumber.contextType = AbilityContext;
