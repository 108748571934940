import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
    TextField,
    Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../../../styles/material-styles";
import { connect } from "react-redux";
import * as componentActions from "../../../../redux/actions/requestnumber";
import { injectIntl } from "react-intl";
import DialogGeneral from "../../../../components/dialog-general";
import Api from "../../../../services/api";






class RequestNumberData extends Component {

    constructor(props) {
        super(props);

        const { requestnumber } = this.props;

        console.log(this.props)
        console.log("props saas aqui")
        this.state = {
            requestnumber: requestnumber.requestnumber,


        };

        this.handleInputChange = this.handleInputChange.bind(this);


    }


    componentDidMount() {
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            requestnumber: {
                ...prevState.requestnumber,
                [name]: value
            }
        }));
    };

    

    /*handleSubmit = async () => {
        
        this.setState({ loading: true, updateProps: false });

        let inputValidations = this.validations()
        const requestNumber = this.state.requestnumber;
        requestNumber.requeststatusid = 3;

        Api.put("/registrationphonenumber", requestNumber)

            .then((result) => {
                if (result.data.success) {
                    const formattedList = result.data.data;
                    const intl = this.props.intl;
                    console.log("result data submit")
                    console.log(result.data.data)
                    if (result.data.data && result.data.data.requeststatus) {
                        console.log("entrei no primeiro")
                        this.props.openConfirmRegister();
                        this.props.updateRequestStatus(result.data.data.requeststatus)
                    }
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "edit.success" }),
                        requestnumber: formattedList,
                        updateProps: true,
                    });


                    //this.componentDidMount();
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            result.data.errors && result.data.errors[0]
                                ? result.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                } else {
                    const intl = this.props.intl;

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                }
            });
    };*/



    render() {
        console.log(this.state)
        console.log("this.state novo")


        const { classes, headerMenu } = this.props;

        const { requestnumber } = this.state;

        console.log(this.state)
        console.log(requestnumber.instanceaccountid)
        console.log("requestnumber.instanceaccountid")

        return (

            <div>
                <DialogGeneral
                    dialogTitle="Atenção!"
                    dialogSubTitle="Essa solicitação será registrada para o cliente, você tem certeza que deseja continuar?"
                    open={this.props.openConfirmRegisterNumberState}
                    openOrCloseModalFunction={this.props.openConfirmRegister}
                    handleConfirmation={() => this.props.handleSubmit(this.state.requestnumber)}

                />
                <Grid container spacing={2} style={{ marginTop: 15 }}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Contratante"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancehirername}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Provedor de mensageria"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancemessageprovidername}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Status"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.requeststatus.name}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Data da solicitação"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.date}
                            InputLabelProps={{ shrink: true }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Nome da organização"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instanceorganizationname}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Número de telefone"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancephonenumber}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Nome do requerente"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancerequestusername}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Email do requerente"
                            inputProps={{ maxLength: 255 }}
                            autoComplete="fname"
                            variant="outlined"
                            value={requestnumber.instancerequestuseremail}
                            disabled

                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="AccountId"
                            name="instanceaccountid"
                            inputProps={{ maxLength: 255 }}
                            variant="outlined"
                            value={requestnumber.instanceaccountid ? requestnumber.instanceaccountid : null}
                            onChange={this.handleInputChange}
                            //disabled={requestnumber.requeststatusid != 1}

                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="PhoneNumberId"
                            inputProps={{ maxLength: 255 }}
                            variant="outlined"
                            value={requestnumber.instancephonenumberid ? requestnumber.instancephonenumberid : null}
                            onChange={this.handleInputChange}
                            name="instancephonenumberid"
                            //disabled={requestnumber.requeststatusid != 1}

                        />
                    </Grid>
                   
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="MessageProviderConfigAccountsid"
                            inputProps={{ maxLength: 255 }}
                            variant="outlined"
                            value={requestnumber.instancemessageproviderconfigaccountsid ? requestnumber.instancemessageproviderconfigaccountsid : null}
                            onChange={this.handleInputChange}
                            name="instancemessageproviderconfigaccountsid"
                            //disabled={requestnumber.requeststatusid != 1}

                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="MessageProviderConfigAuthToken"
                            inputProps={{ maxLength: 255 }}
                            variant="outlined"
                            value={requestnumber.instancemessageproviderconfigauthtoken ? requestnumber.instancemessageproviderconfigauthtoken : null}
                            onChange={this.handleInputChange}
                            name="instancemessageproviderconfigauthtoken"
                            //disabled={requestnumber.requeststatusid != 1}

                        />
                    </Grid>




                </Grid>
            </div>


        );
    }
}
RequestNumberData.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    requestnumber: state.requestnumber
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RequestNumberData))
);