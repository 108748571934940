import Api from './api';


export const getCustomer = async (id = 0) => { 
    let data = [];
    if(id != 0){
      await Api.get('/customers/'+id)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
    }else{
      await Api.get('/customer')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
      });
    }
    
    return data;
};
  

export const getCustomerByHirer = async (id = 0) => { 
  let data = [];
  
  await Api.get('/customers/hirer/'+id)
  .then(result => {
      data = result.data;
      Api.kickoff(result);
    })
    .catch(err => {
      Api.kickoff(err);
      data = err;
  });
  
  return data;
};