import { AbilityBuilder } from '@casl/ability'
import { Ability } from '@casl/ability'


/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item
  }

  return item.__type
}

function getAbilities() {
  var arr = [];
  let login = JSON.parse(localStorage.getItem('persist:root'));
  if(login != null){
    let userSession = JSON.parse(login.userSession);
    //console.log(userSession);
    if(userSession != null && userSession.user != null){
        let userrole = userSession.user.userrole;
        //console.log(userrole);
        if(userrole && userrole.length > 0){
          userrole.map(item => {
            //can(item.item1,item.item2);
              if(item.role){
                if(item.role.permissionroles){
                    if(item.role.permissionroles.length > 0){
                        item.role.permissionroles.map(item => {
                          //console.log(item.permission.name+" : "+item.permission.description);
                          var p = {};
                          p.item1 = item.permission.description;
                          p.item2 = item.permission.name;
                          arr.push(p);
                        
                        });
                    }
                  }
              }
          });
        }
    }
  }
  //console.log(user);
  return (arr);
}

export default AbilityBuilder.define({ subjectName }, can => {
  var m =  getAbilities();
  //console.log(m);
  m.map(item => {
    can(item.item1,item.item2);
  });

  
});

