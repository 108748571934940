import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleCheckBox(props) {
  const { name, label, stateName, changeSelect, selected, required, disabled } = props;

  const classes = useStyles();
  const [checked, setChecked] = React.useState(selected == true ? true : false);
  const [option, setOption] = React.useState(selected == true ? true : false);
  //console.log("selected: "+selected);
  React.useEffect(() => {
    setOption(selected == true ? true : false);
    setChecked(selected == true ? true : false);
  }, [selected]);

  const handleChange = (event) => {
    var value = false;
    var name = event.target.name
    if (event.target.checked) {
      value = true;
    }
    setChecked(event.target.checked);

    setOption(value);
    
    changeSelect(stateName, value);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            label={label}
            name={name}
            value={option}
            variant="outlined"
            checked={checked}
            required={required}
            disabled={disabled}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label={label}
      />
    </div>
  );
}

SimpleCheckBox.propTypes = {
  label: PropTypes.string,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.bool,
};
