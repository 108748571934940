import axios from 'axios';
import Api from "./api";

const UrlApiSaaS = process.env.REACT_APP_API;

export const getPredictURL = async () => {
    try {
        //if (!process.env.REACT_APP_API) return null;
        
        const predictAPIResponse = await axios.get(`${UrlApiSaaS}/config`);
        console.log("predictAPIResponse")
        console.log(predictAPIResponse)
        if (predictAPIResponse.data.success) {
            const predictURL = predictAPIResponse.data.data.apirecognition
            localStorage.setItem("predictURL", JSON.stringify(predictURL))
    
            return predictURL;
          }
        return null
    } catch(err) {
      const data = {data: (err.response && err.response.data ) ? err.response.data: Api.ApiError() };
      if(data.data && data.data.errors){
        if(data.data.errors[0] == "Error: invalid_token"){
          Api.logoff();
        }
      }
  
      return null;
    }
};

export const getPredictAPIToken = async (predictURL) => {
    if (!predictURL) return null;
    if (!process.env.PREDICT_USERNAME || !process.env.PREDICT_PASSWORD) return null;

    try {
        const response = await axios.post(`${predictURL}/users/login`, {}, {
          // TODO remover as senhas daqui!!!
          auth: {
            username: process.env.PREDICT_USERNAME,
            password: process.env.PREDICT_PASSWORD,
          }
        });
        if (response.data && response.data.token) {
            localStorage.setItem("predictToken", JSON.stringify(response.data.token))
            return response.data.token;
        }
        return null;
    } catch(err) {
      const data = {data: (err.response && err.response.data ) ? err.response.data: Api.ApiError() };
      if(data.data && data.data.errors){
        if(data.data.errors[0] == "Error: invalid_token"){
          Api.logoff();
        }
      }
  
      return null;
    }
}

export const predictTrainning = async (imageURL, brand, model, predictURL, token) => {
  
  if (!predictURL) return null;
  if (!process.env.PREDICT_USERNAME || !process.env.PREDICT_PASSWORD) return null;
  
  try {
    const response = await axios({
      url: `${predictURL}/newImageToDataset`,
      data: { Image: imageURL, Brand: brand, Model: model },
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response;
  } catch(err) {
    // const data = {data: (err.response && err.response.data ) ? err.response.data: Api.ApiError() };
    // if(data.data && data.data.errors) {
    //   if(data.data.errors[0] == "Error: invalid_token"){
    //     Api.logoff();
    //   }
    // }
    return err;
  }
}

export const predictPredict = async (imageURL) => {
  if (!imageURL) return null;

  let data = [];

  await Api.post(`predictIA/predict`, {Image: imageURL})
  .then(result => {
    data = result;
  })
  .catch(err => {
    data = {data: (err.response && err.response.data ) ? err.response.data: Api.ApiError() };
    if(data.data && data.data.errors){
      if(data.data.errors[0] == "Error: invalid_token"){
        Api.logoff();
      }
    }
  });

  return data;
  /*
  try {
    const response = await axios({
      url: `${predictURL}/predict`,
      data: { Image: imageURL },
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response;
  } catch(err) {
    // const data = {data: (err.response && err.response.data ) ? err.response.data: Api.ApiError() };
    // if(data.data && data.data.errors) {
    //   if(data.data.errors[0] == "Error: invalid_token"){
    //     Api.logoff();
    //   }
    // }
    return null
  }*/
};

export const savePredictPredictLog = async (data) => {
  if (!data) return null
  try {
    const response = await Api.post("/logpredict", data);
    return response
  } catch(err) {
    return null;
  }
};

export const getModelsTrained = async () => {
  try {
    return await Api.get("/predictIA/modeltraning");
  } catch(err) {
    return null;
  }
};
export const postModelsTrained = async (data) => {
  try {
    return await Api.post("/predictIA/model",data);
  } catch(err) {
    return null;
  }
};

export const checkPredictAccess = async () => {
  try {
    const response = await Api.get("/logpredict/checkpermission");
    if (response && response.data) {
      if (response.data && response.data.success) {
        return response.data;
      }
    }
    return false;
  } catch(err) {
    return err
  }
};

export const getEquipmentsPredicts = async () => {
  try {
    const response = await Api.get("/predictIA/getAllPredictEquipments");
    if (response && response.data) {
      if (response.data && response.data.success) {
        return response;
      }
    }
    return false;
  } catch(err) {
    return err
  }
};

export const getImagensCountPredict = async () => {
    try {
      const response = await Api.get("/predictIA/getCountImagesPredict");
      if (response && response.data) {
        if (response.data && response.data.success) {
          return response;
        }
      }
      return false;
    } catch(err) {
      return err
    }
  };

  export const getRetrainURL = async () => {
    try {
      const response = await Api.get("/predictIA/retrainURL");
      if (response && response.data) {
        if (response.data && response.data.success) {
          return response;
        }
      }
      return false;
    } catch(err) {
      return err
    }
  };
    
