import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../../styles/material-styles";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import HirerConfigForm from "../../../components/hirer-config-form";
import Api from "../../../services/api";

class HirerConfig extends Component {

  constructor(props) {
    super(props)

    let pwd = ""
    const size = 8
    for (let i = 0; i < size; i++) {
      let char = ''
      do {
        const charValue = Math.floor(Math.random() * (126 - 33 + 1)) + 33
        char = String.fromCharCode(charValue)
      } while (char === '"' || char === "'" || char === ';' || char === '`')
      pwd += char
    }

    const hirer = this.props.props.state.hirerData
    const workspace = hirer.name.split(" ")[0].toLowerCase()
    const domain = { id: 1, name: "mexx.ai" }
    const dbPort = "5432"
    const contract = this.props.props.state.contractData

    let checkedApplications = []
    if (Object.entries(contract).length > 0) {
      contract.contractplanperiodicities.map(cpp => {
        if (cpp.planperiodicity.checked) {
          checkedApplications.push(cpp.planperiodicity.applicationid)
        }
      })
    }

    this.initialState = Object.entries(this.props.props.state.hirerConfigData).length > 0 ?
      this.props.props.state.hirerConfigData
      :
      {
        hirer: hirer,
        email: "",
        workspace: workspace,
        urlApiCube: workspace + ".2do.api." + domain.name,
        urlFrontCube: workspace + ".2do." + domain.name,
        urlApiVertex: workspace + ".2see.api." + domain.name,
        urlFrontVertex: workspace + ".2see." + domain.name,
        urlApiCone: workspace + ".2talk.api." + domain.name,
        urlFrontCone: workspace + ".2talk." + domain.name,
        urlApiCubeCalledService: workspace + ".calledservice.api." + domain.name,
        urlFrontCubeCalledService: workspace + ".calledservice." + domain.name,
        urlApiCubeReports: workspace + ".reports.api." + domain.name,
        urlApi2TalkService: workspace + ".2talk.api." + domain.name,
        domainid: 0,
        cloudProviderConfigId: 0,
        containerConfig: [],
        dbUserName: "",
        dbName: "",
        dbPassword: pwd,
        dbPort: dbPort,
        dbHost: "",
        stringConnection: `Server=;Port=${dbPort}DataBase=;Uid=;Pwd=${pwd};Integrated Security=true;Pooling=false;CommandTimeout=120;`,
      }

    this.state = {
      item: this.initialState,
      domainOptions: [],
      applications: [],
      cloudProviders: [],
      containerImages: [],
      domain: {},
      cloudProvider: {},
      cloudProviderConfig: {},
      checkedApplications: checkedApplications,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      file: null,
      error: false,
      errorMessage: {}
    }

    this.closeNotification = this.closeNotification.bind(this)
    this.changeSimpleAutocompleteValues = this.changeSimpleAutocompleteValues.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleContainerConfigChange = this.handleContainerConfigChange.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    let applications, domains, cloudProviders, containerImages, containerConfigs, errors = []
    const contract = this.props.props.state.contractData
    await Api.get("/applications")
      .then(result => {
        if (result.data.success) {
          applications = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        errors.push(err.response.data.errors[0])
      })

    await Api.get("/domain")
      .then(result => {
        if (result.data.success) {
          domains = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        errors.push(err.response.data.errors[0])
      })

    await Api.get("/cloudprovider/withconfigs")
      .then(result => {
        if (result.data.success) {
          cloudProviders = result.data.data
        }
        else {
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })
      .catch(err => {
        errors.push(err.response.data.errors[0])
      })

    await Api.get("/containerimage")
      .then(result => {
        if (result.data.success) {
          containerImages = result.data.data
        }
        else {
          console.log(result.data.errors[0])
          if (result.data.errors) {
            errors.push(result.data.errors[0])
          }
          else {
            errors.push(this.props.intl.formatMessage({ id: "process.error" }))
          }
        }
      })

      await Api.get("/defaultcontainerconfig/withimages")
        .then(result => {
          if (result.data.success) {
            containerConfigs = result.data.data
          }
          else {
            console.log(result.data.errors[0])
            if (result.data.errors) {
              errors.push(result.data.errors[0])
            }
            else {
              errors.push(this.props.intl.formatMessage({ id: "process.error" }))
            }
          }
        })
      .catch(err => {
        errors.push(err.response.data.errors[0])
      })

    if (errors.length <= 0) {
      let containerConfig = []
      if (Object.entries(contract).length > 0) {
        contract.contractplanperiodicities.map(cpp => {
          if (cpp.planperiodicity.checked) {
            let container = {}
            switch (cpp.planperiodicity.applicationid) {
              case 1:
                container = containerConfigs.find(c => c.containerimage.applicationid == 1)
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                container = containerConfigs.find(c => c.containerimage.applicationid == 6)
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                container = containerConfigs.find(c => c.containerimage.applicationid == 9)
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                break
              case 2:
                container = containerConfigs.find(c => c.containerimage.applicationid == 2)
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                break
              case 3:
                container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 1))
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 2))
                containerConfig.push({
                  ...container,
                  applicationid: container.containerimage.applicationid
                })
                break;
            }
          }
        })
      }
      this.setState((prevState) => ({
        applications: applications,
        domainOptions: domains,
        cloudProviders: cloudProviders,
        containerImages: containerImages,
        cloudProvider: cloudProviders[0],
        domain: domains[0],
        cloudProviderConfig: cloudProviders[0].cloudproviderconfigs[0],
        item: {
          ...prevState.item,
          domainid: domains[0].id,
          cloudProviderConfigId: cloudProviders[0].cloudproviderconfigs[0].id,
          containerConfig: containerConfig
        }
      }))
    } else {
      this.setError(errors[0])
    }
  }

  setError(error) {
    this.props.props.setError(error)
  }

  handleNext() {
    let hirerConfig = JSON.parse(JSON.stringify(this.state.item))
    const cleanHirerConfig = {}
    for (const key in hirerConfig) {
      if (hirerConfig.hasOwnProperty(key)) {
        cleanHirerConfig[key] = hirerConfig[key] === "" ? null : hirerConfig[key];
      }
    }
    if (hirerConfig.workspace != "" && hirerConfig.stringConnection != "" && hirerConfig.dbUserName != "" && hirerConfig.dbPassword != "") {
      this.props.props.changeValues("hirerConfigData", cleanHirerConfig)
      console.log(cleanHirerConfig)
      this.props.props.changeValues("domain", this.state.domain)
      this.props.props.changeValues("cloudProviderConfig", this.state.cloudProviderConfig)
      this.props.props.handleNext()
    } else {
      this.props.props.setError(this.props.intl.formatMessage({ id: "error.required" }))
    }
  }

  handleInputChange(e) {
    const name = e.target.name
    const value = e.target.value

    switch (name) {
      case "workspace":
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            urlApiCube: value + ".2do.api." + prevState.domain.name,
            urlFrontCube: value + ".2do." + prevState.domain.name,
            urlApiVertex: value + ".2see." + prevState.domain.name,
            urlFrontVertex: value + ".2see." + prevState.domain.name,
            urlApiCone: value + ".2talk.api." + prevState.domain.name,
            urlFrontCone: value + ".2talk." + prevState.domain.name,
            urlApiCubeCalledService: value + ".calledservice.api." + prevState.domain.name,
            urlFrontCubeCalledService: value + ".calledservice." + prevState.domain.name,
            urlApiCubeReports: value + ".reports.api." + prevState.domain.name,
            urlApi2TalkService: value + ".2talk.api." + prevState.domain.name,
          }
        }))
        break

      case "dbUserName":
        var stringConnection = this.state.item.stringConnection.split(";")
        const i = stringConnection.findIndex(s => s.includes("DataBase"))
        const l = stringConnection.findIndex(s => s.includes("Uid"))
        stringConnection[i] = "DataBase=" + value
        stringConnection[l] = "Uid=" + value
        stringConnection = stringConnection.join(";")
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            dbName: value,
            stringConnection: stringConnection,
          }
        }))
        break

      case "dbName":
        var stringConnection = this.state.item.stringConnection.split(";")
        const k = stringConnection.findIndex(s => s.includes("DataBase"))
        stringConnection[k] = "DataBase=" + value
        stringConnection = stringConnection.join(";")
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            stringConnection: stringConnection,
          }
        }))
        break

      case "dbPassword":
        var stringConnection = this.state.item.stringConnection.split(";")
        const j = stringConnection.findIndex(s => s.includes("Pwd"))
        stringConnection[j] = "Pwd=" + value
        stringConnection = stringConnection.join(";")
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            stringConnection: stringConnection,
          }
        }))
        break

      case "dbPort":
        var stringConnection = this.state.item.stringConnection.split(";")
        const m = stringConnection.findIndex(s => s.includes("Port"))
        stringConnection[m] = "Port=" + value
        stringConnection = stringConnection.join(";")
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            stringConnection: stringConnection,
          }
        }))
        break

      case "dbHost":
        var stringConnection = this.state.item.stringConnection.split(";")
        const n = stringConnection.findIndex(s => s.includes("Server"))
        stringConnection[n] = "Server=" + value
        stringConnection = stringConnection.join(";")
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value,
            stringConnection: stringConnection,
          }
        }))
        break

      default:
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }))
        break
    }
  }

  handleContainerConfigChange(key, value, index) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        containerConfig: prevState.item.containerConfig.map((c, i) => {
          if (i == index) {
            return {
              ...prevState.item.containerConfig[i],
              [key]: value
            }
          } else {
            return c
          }
        })
      }
    }))
  }

  handleChangeSelect(name, value) {
    switch (name) {
      case "domain":
        const domain = this.state.domainOptions.find(sd => sd.id == value)
        this.setState((prevState) => ({
          domain: domain,
          item: {
            ...prevState.item,
            urlApiCube: prevState.item.workspace + ".2do.api." + domain.name,
            urlFrontCube: prevState.item.workspace + ".2do." + domain.name,
            urlApiVertex: prevState.item.workspace + ".2see." + domain.name,
            urlFrontVertex: prevState.item.workspace + ".2see." + domain.name,
            urlApiCone: prevState.item.workspace + ".2talk.api." + domain.name,
            urlFrontCone: prevState.item.workspace + ".2talk." + domain.name,
            urlApiCubeCalledService: prevState.item.workspace + ".calledservice.api." + domain.name,
            urlFrontCubeCalledService: prevState.item.workspace + ".calledservice." + domain.name,
            urlApiCubeReports: prevState.item.workspace + ".reports.api." + domain.name,
            urlApi2TalkService: prevState.item.workspace + ".2talk.api." + domain.name,
            domainid: domain.id,
          }
        }))
        break

      case "cloudProvider":
        const provider = this.state.cloudProviders.find(p => p.id == value)

        this.setState((prevState) => ({
          cloudProvider: provider,
          item: {
            ...prevState.item,
            cloudProviderConfig: provider.cloudproviderconfigs[0] ?? {},
            cloudProviderConfigId: provider.cloudproviderconfigs[0] ? provider.cloudproviderconfigs[0].id : "",
          }
        }))
        break

      case "cloudProviderConfig":
        const providerConfig = this.state.cloudProvider.cloudproviderconfigs.find(pc => pc.id == value)

        this.setState((prevState) => ({
          cloudProviderConfig: providerConfig,
          item: {
            ...prevState.item,
            cloudProviderConfigId: providerConfig.id
          }
        }))
    }
  }

  changeSimpleAutocompleteValues(name, value) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  render() {
    const { props, intl, classes } = this.props
    const { item, domainOptions, applications, checkedApplications, cloudProviders, cloudProvider, containerImages } = this.state

    return (
      <Grid container spacing={4}>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item>
            <HirerConfigForm
              hirerConfig={item} optionsList={[item.hirer]} intl={intl} selected={true} cloudProvider={cloudProvider}
              domainOptions={domainOptions} applications={applications} checkedApplications={checkedApplications} cloudProviders={cloudProviders}
              handleInputChange={this.handleInputChange} changeSimpleAutocompleteValues={this.changeSimpleAutocompleteValues}
              handleChangeSelect={this.handleChangeSelect} handleContainerConfigChange={this.handleContainerConfigChange}
              containerImages={containerImages}
            />
          </Grid>
        </Grid>
        <Grid item container justify='space-between'>
          <Button onClick={props.handleBack} className={classes.button}>
            <FormattedMessage id="back" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            className={classes.button}
          >
            <FormattedMessage id="next" />
          </Button>
        </Grid>
      </Grid>
    )
  }
}
HirerConfig.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(HirerConfig))
)