import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import moment from "moment";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: "",
        token: this.props.match.params.token,
        active: true,
      },
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location.state === "reload") {
  //     this.componentDidMount();
  //   }
  // }

  componentDidMount() {
    if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    }
  }

  getUser(token) {
    console.log(token);
    this.setState({ loading: true });
    Api.get(`/users/resetPassword/${token}`)
      .then((result) => {
        if (result.data.success) {
          console.log(result.data.data);
          console.log("-------------------");
          const { email, phone } = result.data.data;
          this.setState((prevState) => ({
            loading: false,
            passwordResetForm: {
              ...prevState.passwordResetForm,
              email
            },
          }));
        } else {
          console.log(result);
          document.getElementById("submit").classList.add("Mui-disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : "Erro ao processar a solicitação.",
        });
        document.getElementById("submit").classList.add("Mui-disabled");
        console.log("entrei aqui");
      });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        [name]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.validatePassword()) {
      this.setState((prevState) => ({
        loading: true,
        passwordResetForm: {
          ...prevState.passwordResetForm
        },
      }));

      let formdata = new FormData();
      for (let key in this.state.passwordResetForm) {
        if (
          key
            .toString()
            .toLowerCase()
            .indexOf("_date") > -1 ||
          key
            .toString()
            .toLowerCase()
            .indexOf("date_") > -1
        ) {
          formdata.append(
            key,
            moment(this.state.passwordResetForm[key]).format("YYYY/MM/DD HH:mm:ss")
          );
        } else {
          formdata.append(key, this.state.passwordResetForm[key]);
        }
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          dataType: "json",
        },
      };

      //const { email, password, token } = this.state.passwordResetForm;

      Api.post("/users/setPassword", formdata, config)
        .then((result) => {
          if (result.data.success) {
            this.setState((prevState) => ({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: "Senha cadastrada com sucesso!",
              passwordResetForm: {
                ...prevState.passwordResetForm
              },
            }));
            setTimeout(() => {
              this.props.history.push("/");
            }, 3000);
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : "Erro ao processar a solicitação.",
          });
        });
    }
  };

  validatePassword() {
    const { passwordResetForm } = this.state;
    if (passwordResetForm.password !== passwordResetForm.confirmPassword) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Senhas não correspondem!",
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes } = this.props;
    const { passwordResetForm, userLogged } = this.state;
    console.log(passwordResetForm);
    console.log(classes);
    console.log(userLogged);

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {" "}
            <CompareArrowsIcon />{" "}
          </Avatar>
          <Typography component="h1" variant="h5">
            {" "}
            Redefina sua senha{" "}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={passwordResetForm.email}
              disabled
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={{ maxLength: 10, minLength: 6 }}
              value={passwordResetForm.password}
              onChange={this.handleInputChange}
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirmar Senha"
              type="password"
              id="confirmPassword"
              autoComplete="current-confirmPassword"
              inputProps={{ maxLength: 10, minLength: 6 }}
              value={passwordResetForm.confirmPassword}
              onChange={this.handleInputChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              id="submit"
            >
              Confirmar
            </Button>
          </form>
        </div>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userSession: state.userSession,
});

export default connect(mapStateToProps)(withStyles(Styles)(PasswordReset));
