export default function requestnumber(state = [], action) {
    console.log("state aqui")
    console.log(state)
    console.log(action)
    switch (action.type) {
        case 'ADD_CURRENT_REQUESTNUMBER':
            return {
                ...state,
                requestnumber: action.requestnumber,
            }
        case 'UPDATE_REQUEST_STATUS':
            console.log("entrei aqui 2")
            return {
                ...state,
                requestnumber: {
                    ...state.requestnumber,
                    requeststatus: action.requeststatus
                }
            }
        default:
            return state;
    }
}