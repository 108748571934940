import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DraftsIcon from '@material-ui/icons/Drafts';
import { ExitToApp } from '@material-ui/icons';
import * as userSessionActions from '../../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import './menu.css';

const UserRoles = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const intl = props.intl;
    let history = useHistory();
    //console.log(props.user.userrole);
    //console.log(props.class);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let sortedList = props.user.userrole.map(
        (item, index) => <MenuItem key={index} onClick={handleClose}>{item.role.name}</MenuItem>);  
    //console.log(sortedList);
    //if(sortedList.length  <= 1){
    //    sortedList=null;
    //}
    

    const logOut = () => {
      handleClose();
      userSessionActions.removeUserSession();
      history.push("/");
    };

    const roles =  props.user.userrole.map((item,index) => {
      return (
      <MenuItem key={index + 1} onClick={handleClose} className="menu-item">
              <Typography variant="inherit">                  
              {item.role.name}
                </Typography>
            </MenuItem>

    )
  })
  
    return (
        <div>
            <MenuItem key={-1} onClick={handleClose} className="menu-item">
              <ListItemIcon>
                <DraftsIcon fontSize="default" />
              </ListItemIcon>              
              {props.user.email}
            </MenuItem>
            {roles}
            
        </div>
      );

}


class UserInfoMobile extends Component {
    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
     
    }
    constructor(props) {
        super(props);

    }
    render() {
        const { classes, userSession } = this.props;
        return (
            <div >
                <UserRoles intl={this.props.intl} user={userSession.user} class={classes.title}/>
            </div>
        );
    }
  }

  const mapStateToProps = state => ({
    userSession: state.userSession
  });
  
  export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(UserInfoMobile)));
  