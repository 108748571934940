import { Button, Grid, Typography } from "@material-ui/core"
import React from "react"
import { FormattedMessage } from "react-intl"

export default function Confirmation(props) {

    const { hirerData, contractData, hirerConfigData, plans, periodicities, applications } = props.props.state
    const { intl, classes } = props.props
    const cpp = contractData.contractplanperiodicities

    return (
        <Grid container item sm={12} spacing={4} style={{ marginTop: "20px", textAlign: "start" }}>
            <Grid container item sm={12} className={classes.attachmentsBox}>
                <Grid item sm={3}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "name" })}</Typography>
                    <Typography>{hirerData.name ?? ""}</Typography>
                </Grid>
                <Grid item sm={3}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "hirer.document" })}</Typography>
                    <Typography>{hirerData.document ?? ""}</Typography>
                </Grid>
                <Grid item sm={3}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "phone" })}</Typography>
                    <Typography>{hirerData.phone ?? ""}</Typography>
                </Grid>
                <Grid item sm={3}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "email" })}</Typography>
                    <Typography>{hirerData.email ?? ""}</Typography>
                </Grid>
            </Grid>

            <Grid container item sm={12} className={classes.attachmentsBox}>
                <Grid item sm={4}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "hirer.responsible.name" })}</Typography>
                    <Typography>{hirerData.responsiblename ?? ""}</Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "hirer.responsible.phone" })}</Typography>
                    <Typography>{hirerData.responsiblephone ?? ""}</Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "hirer.responsible.mail" })}</Typography>
                    <Typography>{hirerData.responsiblemail ?? ""}</Typography>
                </Grid>
            </Grid>

            <Grid container item sm={12} className={classes.attachmentsBox}>
                {
                    !hirerData.id > 0 &&
                    <Grid item sm={8}>
                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "access.key" })}</Typography>
                        <Typography noWrap >{hirerData.accesskey ?? ""}</Typography>
                    </Grid>
                }
                <Grid item sm={4}>
                    <Typography style={{ fontWeight: "bold" }}>Workspace</Typography>
                    <Typography>{hirerConfigData.workspace ?? ""}</Typography>
                </Grid>
            </Grid>
            {
                applications.map((app, i) => {
                    return (
                        cpp[i].planperiodicity.checked &&
                        <Grid container item sm={12} className={classes.attachmentsBox} key={app.id}>
                            <Grid item sm={2} container alignItems="center">
                                <Typography style={{ fontWeight: "bold" }}>{app.name}</Typography>
                            </Grid>
                            <Grid container item sm={10}>
                                <Grid container item sm={3}>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "plan" })}</Typography>
                                        <Typography>{plans.find(plan => plan.id == cpp[i].planperiodicity.planid).name ?? ""}</Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "period" })}</Typography>
                                        <Typography>{periodicities.find(plan => plan.id == cpp[i].planperiodicity.periodicityid).name ?? ""}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={3}>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "price" })}</Typography>
                                        <Typography>R$ {cpp[i].planvalue ?? ""}</Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "disk.limit" })}</Typography>
                                        <Typography>{cpp[i].disklimitusage ?? ""} GB</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={3}>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "agent.limit" })}</Typography>
                                        <Typography>{cpp[i].agentlimitquantity ?? ""}</Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "agent.value" })}</Typography>
                                        <Typography>R$ {cpp[i].additionalvalueagent ?? ""}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={3}>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "attendance.limit" })}</Typography>
                                        <Typography>{cpp[i].attendancelimitpermonth ?? ""}</Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "attendance.value" })}</Typography>
                                        <Typography>R$ {cpp[i].attendancevalue ?? ""}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Grid container item sm={12} className={classes.attachmentsBox}>
                <Grid item sm={2} container alignItems="center">
                    <Typography style={{ fontWeight: "bold" }}>URLs</Typography>
                </Grid>
                <Grid container item sm={10}>
                    <Grid container item sm={4}>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API 2do</Typography>
                            <Typography>{hirerConfigData.urlApiCube}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>Front 2do</Typography>
                            <Typography>{hirerConfigData.urlFrontCube}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API CalledService</Typography>
                            <Typography>{hirerConfigData.urlApiCubeCalledService}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item sm={4}>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API 2see</Typography>
                            <Typography>{hirerConfigData.urlApiVertex}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API 2see</Typography>
                            <Typography>{hirerConfigData.urlFrontVertex}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>Front CalledService</Typography>
                            <Typography>{hirerConfigData.urlFrontCubeCalledService}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item sm={4}>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API 2talk</Typography>
                            <Typography>{hirerConfigData.urlApiCone}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API 2talk</Typography>
                            <Typography>{hirerConfigData.urlFrontCone}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography style={{ fontWeight: "bold" }}>API Reports</Typography>
                            <Typography>{hirerConfigData.urlApiCubeReports}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} className={classes.attachmentsBox}>
                <Typography style={{ fontWeight: "bold" }}>String Connection</Typography>
                <Typography>{hirerConfigData.stringConnection ?? ""}</Typography>
            </Grid>
            <Grid item container justify='space-between'>
                <Button onClick={props.props.handleBack} className={classes.button}>
                    <FormattedMessage id="back" />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.props.handleNext}
                    className={classes.button}
                >
                    <FormattedMessage id="finnish" />
                </Button>
            </Grid>
        </Grid>
    )
}