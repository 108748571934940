export default function hirers(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT_HIRER':
            return {
                ...state,
                hirer: action.hirer,
            }
        default:
            return state;
    }
}