import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@material-ui/core';
import { QueuePlayNext, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import * as Service from '../../services/config.service';
import * as componentActions from '../../redux/actions/configs';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import NavTabs from '../../components/tab/tab';
import GeneralEdit from './components/general-config';
import ApisEdit from './components/apis';
import Email from './components/email';

function getRandomNUmber() {
  const min = 1;
  const max = 10000;
  const rand = min + Math.random() * (max - min);
  return rand;
}

class ConfigEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, configs } = this.props;
    console.log(configs.config)

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    const defaultvalue = { id: 0, name: '' };
    this.timezoneid = configs.config.timezone;

    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: configs.config.id,
      timezone: defaultvalue,
      formatdate: configs.config.formatdate == null ? defaultvalue : { id: getRandomNUmber(), name: configs.config.formatdate },
      decimalseparator: configs.config.decimalseparator == null ? '' : configs.config.decimalseparator,
      thousandseparator: configs.config.thousandseparator == null ? '' : configs.config.thousandseparator,
      frommail: configs.config.frommail == null ? '' : configs.config.frommail,
      hostmail: configs.config.hostmail == null ? '' : configs.config.hostmail,
      portmail: configs.config.portmail == null ? '' : configs.config.portmail,
      usermail: configs.config.usermail == null ? '' : configs.config.usermail,
      passwordmail: "",
      passwordrecognition: "",
      enablessl: configs.config.enablessl == null ? false : configs.config.enablessl,
      mailtest: "",
      apirecognition: configs.config.apirecognition == null ? '' : configs.config.apirecognition,
      urlapi2faceservice: configs.config.urlapi2faceservice == null ? '' : configs.config.urlapi2faceservice,
      urlapifile: configs.config.urlapifile == null ? '' : configs.config.urlapifile,
      urlapiprosody: configs.config.urlapiprosody == null ? '' : configs.config.urlapiprosody,
      urlapivideobridge: configs.config.urlapivideobridge == null ? '' : configs.config.urlapivideobridge,
      urlapimeet: configs.config.urlapimeet == null ? '' : configs.config.urlapimeet,
      urlapimessenger: configs.config.urlapimessenger == null ? '' : configs.config.urlapimessenger,
      urlapiprovider: configs.config.urlapiprovider == null ? '' : configs.config.urlapiprovider,
      urlapicofo: configs.config.urlapicofo == null ? '' : configs.config.urlapicofo,
    };
    this.state = {
      config: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      TimeZoneList: [],
      FormatDateList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getTimeZones();
    //console.log(this.state.config.timezoneid);
    await this.getTimeZone(this.timezoneid);
    await this.getFormatDates();
  };
  async getTimeZone(id) {
    this.setState({ loading: true });
    var result = await Service.getTimeZone(id);
    if (result.success) {
      //console.log(result.data);
      //this.setState({ loading: false, timezone: result.data });

      this.setState(prevState => ({
        config: {
          ...prevState.config,
          ['timezone']: result.data
        }
      }));

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getTimeZones() {
    this.setState({ loading: true });
    var result = await Service.getTimeZones();
    if (result.success) {
      //console.log(result.data);
      this.setState({ loading: false, TimeZoneList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
  async getFormatDates() {
    this.setState({ loading: true });
    var result = await Service.getFormatDates();
    //console.log(result.data);
    if (result.success) {
      this.setState({ loading: false, FormatDateList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [name]: value
      }
    }));
  };

  handleClick = async (e) => {
    this.setState({ loading: true });
    if (this.state.config.mailtest.length > 0) {
      let result = await Service.testMail(this.state.config.mailtest);
      if (result && result.data && result.data.success == true) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Email Enviado"
        });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Insira o email de destino",
      })
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


    if (this.isClient) {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
        }
      }));
    } else {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
        }
      }));
    }


    for (let key in this.state.config) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
        data.append(key, moment(this.state.config[key]).format('YYYY/MM/DD HH:mm:ss'));
      }
      else if (key.toString().toLowerCase().indexOf('timezone') > -1) {
        data.append(key, this.state.config[key].id);
      }
      else if (key.toString().toLowerCase().indexOf('formatdate') > -1) {
        data.append(key, this.state.config[key].name);
      }
      else if (this.state.config[key] != '') {
        data.append(key, this.state.config[key]);
      }
    }

    this.state.files.forEach(file => data.append('filesUpload', file));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    Api.put('/config', data, config)

      .then(result => {
        if (result.data.success) {
          const formattedList = result.data.data;
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: []
          });
          //console.log(formattedList);
          this.props.addCurrent(formattedList);
          this.timezoneid = formattedList.timezone;
          this.componentDidMount();

        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, configs } = this.props;
    const { config } = this.state;
    const intl = this.props.intl;
    console.log(this.state.TimeZoneList)
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginTop: 30 }}>
            <CssBaseline />
            <Grid container direction='column' alignItems='center'>
              <Grid container item sm={12} spacing={6}>
                <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
              </Grid>
              <Grid item sm={2}>
                <Avatar className={classes.avatar}>
                  <QueuePlayNext />
                </Avatar>
              </Grid>
              <Grid item sm={2}>
                <Typography component='h1' variant='h5'>
                  {<FormattedMessage id="edit.item" />}
                  <Title />
                </Typography>
              </Grid>
              <Grid item sm={12} style={{ width: '100%' }}>
                <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <NavTabs
                    components={[
                      <GeneralEdit
                        classes={classes} intl={intl} displayname="Geral" config={config}
                        handleSubmit={this.handleSubmit} handleInputChange={this.handleInputChange}
                        changeValues={this.changeValues} timezonelist={this.state.TimeZoneList}
                        formatdatelist={this.state.FormatDateList}
                      />,
                      <Email
                        classes={classes} displayname="Email" config={config} handleClick={this.handleClick}
                        handleInputChange={this.handleInputChange} newchangeValues={this.newchangeValues}
                      />,
                      <ApisEdit displayname="Apis" intl={intl} config={config} handleInputChange={this.handleInputChange}/>
                    ]}
                  />
                  <Grid container justify='center'>
                    <Grid container item sm={4}>
                      <Grid item sm={4} style={{ paddingInline: 10}}>
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
ConfigEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  configs: state.configs
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ConfigEdit)));
