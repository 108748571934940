import React, { Component } from "react";
import MyMaterialTable from "../../../components/table"
import PropTypes from "prop-types";
import * as Service from "../../../services/hirerConfig.service"
import * as componentActions from "../../../redux/actions/hirer-config"
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, CircularProgress, Grid, withStyles } from "@material-ui/core";
import { Styles } from "../../../styles/material-styles";
import { bindActionCreators } from "redux";
import { Overlay } from "../../../styles/global";
import CustomizedSnackbars from "../../../components/material-snackbars";
import hirerConfig from "../../enviroment/components/hirer-config";
import { AbilityContext } from "../../../config/ability-context";

class HirerConfigsComponent extends Component {
    constructor(props) {
        super(props)
        const { intl, hirer } = this.props

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            hirer: hirer,
            hirerConfigs: []
        }

        this.goToEdit = this.goToEdit.bind(this)
        this.goToCreate = this.goToCreate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
    }

    componentDidMount() {
        this.fetchData()
    }

    async fetchData() {
        this.setState({ loading: true })
        const result = await Service.getByHirerId(this.state.hirer.id)
        this.setState({
            loading: false,
            hirerConfigs: result,
        })
    }

    goToEdit = async (id) => {
        this.props.setLoading()
        const result = await Service.getHirerConfigById(id)
        if (result.success) {
            this.props.addCurrent(result.data)
            this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirer-config-edit` })
        } else {
            this.props.setError(result.errors[0])
        }
    }

    goToCreate() {
        this.props.addCurrent({ hirer: this.state.hirer })
        this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirer-config-registration` })
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    async handleDelete(data, resolve) {
        const { intl } = this.props
        if (this.context.can("Delete", "HirerConfig") == true && data.hireractive == false) {
            this.props.setLoading()
            const result = await Service.deleteHirerConfig(data.id)

            if (result.success) {
                this.setState((prevState) => ({
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                    hirerConfigs: prevState.hirerConfigs.filter(hirerConfig => hirerConfig.id != data.id)
                }))
                this.props.finishLoading()
                resolve()
            } else {
                this.props.setError(result.errors[0] ?? intl({ id: "process.error" }))
                resolve()
            }
        } else {
            this.props.setError(intl.formatMessage({ id: "operation.notAllowed" }))
            resolve()
        }
    }

    render() {
        const { intl } = this.props
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.goToCreate}
                        >
                            <FormattedMessage id={"add.item"} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <MyMaterialTable
                            title="ContractsTable"
                            columns={[
                                { title: "ID", field: "id" },
                                { title: "Url Api 2DO", field: "urlapicube" },
                                { title: "Url Api 2SEE", field: "urlapivertex" },
                                { title: "Url Api 2TALK", field: "urlapicone" },
                            ]}
                            data={this.state.hirerConfigs}
                            deleteItem={this.handleDelete}
                            rowClick={(event, rowData) => this.goToEdit(rowData.id)}
                        />
                    </Grid>
                </Grid>
                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}
                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    isOpen={this.state.openNotification}
                    message={this.state.notificationMessage}
                    toClose={this.closeNotification}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    hirer: state.hirers.hirer,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HirerConfigsComponent)))

HirerConfigsComponent.contextType = AbilityContext