import React, { Component } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../../styles/material-styles";
import * as Service from "../../../services/hirer.service"
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import HirerForm from "../../../components/hirer-form";
import SimpleSelect from "../../../components/select/simple";
import SimpleAutoComplete from "../../../components/auto-complete/autocomplete";

class Hirer extends Component {

  constructor(props) {
    super(props)
    
    const { intl } = this.props

    let accesskey = ""
    const size = 55
    for (let i = 0; i < size; i++) {
      let char = ''
      do {
        const charValue = Math.floor(Math.random() * (126 - 33 + 1)) + 33
        char = String.fromCharCode(charValue)
      } while (char === '"' || char === "'" || char === ';' || char === '`')
      accesskey += char
    }

    this.initialState = Object.entries(this.props.props.state.hirerData).length > 0 ?
      this.props.props.state.hirerData
      :
      {
        id: 0,
        name: intl.formatMessage({ id: "new.hirer" }),
        document: "",
        phone: "",
        email: "",
        responsiblename: "",
        responsiblephone: "",
        responsiblemail: "",
        active: false,
        blocked: false,
        accesskey: accesskey,
      }

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      file: null,
      error: false,
      errorMessage: {},
      selectedHirer: this.props.props.state.hirerid,
      hirers: [{ id: 0, name: intl.formatMessage({ id: "new.hirer" }), document: "", email: "", accesskey: accesskey }]
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }

  handleNext() {
    const hirer = this.state.item
    if (hirer.id > 0 || (hirer.name != "" && hirer.document != "" && hirer.email != "")) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (emailRegex.test(hirer.email)) {
        this.props.props.changeValues("hirerData", hirer)
        this.props.props.handleNext()
      } else {
        this.props.props.setError(this.props.intl.formatMessage({ id: "bad.email" }))
      }
    } else {
      this.props.props.setError(this.props.intl.formatMessage({ id: "error.required" }))
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let result = await Service.getAllHirer()

    if (result.success) {
      this.setState((prevState) => ({
        loading: false,
        hirers: [...prevState.hirers, ...result.data]
      }))
    } else {
      this.setState({ loading: false })
      this.props.props.setError(result.errors ? result.errors[0] : this.props.intl.formatMessage({ id: "process.error" }))
    }
  }

  handleInputChange = (key, value) => {

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [key]: value
      }
    }))

  }

  handleChangeSelect = (stateName, value) => {
    this.props.props.changeValues("contractData", {})
    this.props.props.changeValues("contractid", 0)
    this.props.props.changeValues("hirerid", value.id)
    this.setState({
      item: value,
      selectedHirer: value.id,
    })
  }

  render() {
    const { intl, classes } = this.props
    const { item, selectedHirer, hirers } = this.state

    return (
      <Grid container spacing={4}>
        <Accordion expanded={selectedHirer == 0}>
          <AccordionSummary>
            <Grid container>
              <Grid item sm={12}>
                <SimpleAutoComplete
                  options={hirers}
                  selected={item}
                  stateName="hirer"
                  changeSelect={this.handleChangeSelect}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <HirerForm
              hirer={item} intl={intl} classes={classes}
              handleInputChange={this.handleInputChange}
            />
          </AccordionDetails>
        </Accordion>
        <Grid item container justify='flex-end'>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            className={classes.button}
          >
            <FormattedMessage id="next" />
          </Button>
        </Grid>
      </Grid>
    )
  }
}
Hirer.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(Hirer))
)