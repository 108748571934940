import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import HeaderMenu from "../../components/header-menu";
import * as componentActions from '../../redux/actions/hirer-config';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { CssBaseline, Grid, Container, withStyles, Button, Typography, CircularProgress, Avatar } from "@material-ui/core";
import { Styles } from "../../styles/material-styles";
import { FormattedMessage, injectIntl } from "react-intl";
import { Settings } from "@material-ui/icons";
import * as ConfigService from "../../services/hirerConfig.service";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import { browserHistory } from "../../helpers/history";
import HirerConfigForm from "../../components/hirer-config-form";
import Api from "../../services/api";

class HirerConfigEdit extends Component {
    constructor(props) {
        super(props)
        const { hirerconfig } = this.props.hirerconfig
        const domain = { id: 1, name: "mexx.ai" }

        this.initialState = {
            id: hirerconfig.id,
            hirerName: hirerconfig.hirer.name,
            hirerId: hirerconfig.hirerid,
            email: hirerconfig.email ?? "",
            workspace: hirerconfig.workspace ?? "",
            urlApiCube: hirerconfig.urlapicube ?? "",
            urlFrontCube: hirerconfig.urlfrontcube ?? "",
            urlApiVertex: hirerconfig.urlapivertex ?? "",
            urlFrontVertex: hirerconfig.urlfrontvertex ?? "",
            urlApiCone: hirerconfig.urlapicone ?? "",
            urlFrontCone: hirerconfig.urlfrontcone ?? "",
            urlApiCubeCalledService: hirerconfig.urlapicubecalledservice ?? "",
            urlFrontCubeCalledService: hirerconfig.urlfrontcubecalledservice ?? "",
            urlApiCubeReports: hirerconfig.urlapicubereports ?? "",
            urlapi2talkservice: hirerconfig.urlapi2talkservice ?? "",
            stringConnection: hirerconfig.stringconnection ?? "",
            domainid: hirerconfig.domainid ?? domain.id,
            cloudProviderConfigId: hirerconfig.cloudproviderconfigid ?? 0,
            containerConfig: hirerconfig.containerConfig ?? [],
        }

        this.state = {
            domainOptions: [],
            applications: [],
            cloudProviders: [],
            containerImages: [],
            domain: {},
            cloudProvider: {},
            cloudProviderConfig: {},
            checkedApplications: [1, 2, 3],
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            item: this.initialState
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    componentDidMount() {
        this.fetchData()
    }
    async fetchData() {
        this.setState({ loading: true })
        let applications, domains, cloudProviders, containerImages, containerConfigs, errors = []

        await Api.get("/applications")
            .then(result => {
                if (result.data.success) {
                    applications = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/domain")
            .then(result => {
                if (result.data.success) {
                    domains = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/cloudprovider/withconfigs")
            .then(result => {
                if (result.data.success) {
                    cloudProviders = result.data.data
                }
                else {
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        await Api.get("/containerimage")
            .then(result => {
                if (result.data.success) {
                    containerImages = result.data.data
                }
                else {
                    console.log(result.data.errors[0])
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })

        await Api.get("/defaultcontainerconfig/withimages")
            .then(result => {
                if (result.data.success) {
                    containerConfigs = result.data.data
                }
                else {
                    console.log(result.data.errors[0])
                    if (result.data.errors) {
                        errors.push(result.data.errors[0])
                    }
                    else {
                        errors.push(this.props.intl.formatMessage({ id: "process.error" }))
                    }
                }
            })
            .catch(err => {
                errors.push(err.response.data.errors[0])
            })

        if (errors.length <= 0) {
            let containerConfig = []
            applications.map(app => {
                let container = {}
                switch (app) {
                    case 1:
                        container = containerConfigs.find(c => c.containerimage.applicationid == 1)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => c.containerimage.applicationid == 6)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => c.containerimage.applicationid == 9)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break
                    case 2:
                        container = containerConfigs.find(c => c.containerimage.applicationid == 2)
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break
                    case 3:
                        container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 1))
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        container = containerConfigs.find(c => (c.containerimage.applicationid == 3 && c.containerimage.module == 2))
                        containerConfig.push({
                            ...container,
                            applicationid: container.containerimage.applicationid
                        })
                        break;
                }
            })
            this.setState((prevState) => ({
                loading: false,
                applications: applications,
                domainOptions: domains,
                cloudProviders: cloudProviders,
                containerImages: containerImages,
                cloudProvider: cloudProviders[0],
                domain: domains[0],
                cloudProviderConfig: cloudProviders[0].cloudproviderconfigs[0],
                item: {
                    ...prevState.item,
                    domainid: domains[0].id,
                    cloudProviderConfigId: cloudProviders[0].cloudproviderconfigs[0].id,
                    containerConfig: containerConfig
                }
            }))
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: errors[0]
            })
        }
    }

    handleInputChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }))
    }

    async handleSubmit() {
        const intl = this.props.intl
        this.setState({ loading: true })
        let data = new FormData()
        for (let key in this.state.item) {
            data.append(key, this.state.item[key])
        }
        try {
            const result = await ConfigService.updateHirerConfig(data)
            if (result.success) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "edit.success" })
                });
                this.props.addCurrent({ ...result.data, hirerName: this.state.item.hirerName })
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.errors && result.errors[0]
                            ? result.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
            });
        }
    }

    render() {
        const { classes, headerMenu } = this.props
        const intl = this.props.intl
        const { item, domainOptions, applications, checkedApplications, cloudProviders, cloudProvider, containerImages, optionsList } = this.state

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={browserHistory.goBack}
                                    >
                                        {<FormattedMessage id="back" />}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <Settings />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {<FormattedMessage id="hirerConfig.edit" />} - {item.id}
                            </Typography>
                            <form name="hirerConfigForm" className={classes.form}>
                                <HirerConfigForm
                                    hirerConfig={item} intl={intl} applications={applications} optionsList={optionsList} selected={true}
                                    domainOptions={domainOptions} cloudProvider={cloudProvider} checkedApplications={checkedApplications} cloudProviders={cloudProviders}
                                    handleInputChange={this.handleInputChange} containerImages={containerImages}
                                    edit
                                />
                                <Grid container spacing={4} justify="flex-end">
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={this.handleSubmit}
                                        >
                                            <FormattedMessage id="update" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            isOpen={this.state.openNotification}
                            message={this.state.notificationMessage}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}
HirerConfigEdit.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    hirerconfig: state.hirerconfig
})

const mapDispatchToProps = (dispatch) => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HirerConfigEdit)))