import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { QueuePlayNext } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as componentActions from "../../redux/actions/requestnumber";
import { FormattedMessage, injectIntl } from "react-intl";
import TransitionsModal from '../../components/modal/modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog } from '@material-ui/core';
import CompanyData from "./components/company-data";
import RequestNumberData from "./components/request-number-data";
import { styled } from '@mui/system';
import { Tabs, Tab, Paper } from '@mui/material';
import * as RegistrationNumberRequest from '../../services/registrationNumberRequest.';
import DialogGeneral from "../../components/dialog-general";
import moment from 'moment'


const MyPaper = styled(Paper)({
  backgroundColor: (theme) => theme.palette.background.paper,
});

const MyTabs = styled(Tabs)({
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.primary.contrastText,
});

class RequestNumberEdit extends Component {
  constructor(props) {
    super(props);
    const { userSession, requestnumber } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.state = {
      requestnumber: requestnumber.requestnumber,
      hirerList: [],
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      error: false,
      errorMessage: {},
      openCancelRegisterNumber: false,
      openConfirmRegisterNumber: false,
      tabSelected: 0,
      updateProps: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.changeSimpleAutocompleteValues = this.changeSimpleAutocompleteValues.bind(this);
    this.getFile = this.getFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
  }

  handleInputChange(e) {
    const intl = this.props.intl;
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      requestnumber: {
        ...prevState.requestnumber,
        [name]: value,
      },
    }));

  }

  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  };

  async getFile(fileName, filerepositorymethod, hirerid) {
    //const intl = this.props.intl;
    this.setState({ loading: true });

    let url = await RegistrationNumberRequest.getFile(fileName, filerepositorymethod, hirerid);
    this.setState({ loading: false });

    if (url && url.data && url.data.data && url.data.success) {
      window.open(`${url.data.data}`, '_blank');
    }
    else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: "Arquivo não encontrado"
      });

    }

  };

  handleSubmitCancelRegistration = async () => {


    const requestNumber = this.state.requestnumber;

    if (requestNumber.reason == "") {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "É necessário colocar o motivo",
      });
      return;
    }

    this.openCancelRegister();


    this.setState({ loading: true, updateProps: false });

    requestNumber.requeststatusid = 2;

    Api.put("/registrationphonenumber", requestNumber)

      .then((result) => {
        if (result.data.success) {
          const formattedList = result.data.data;
          console.log(formattedList)
          console.log('formattedList')
          const intl = this.props.intl;
          if (result.data.data && result.data.data.requeststatus) {
            console.log("entrei no primeiro")
            this.props.updateRequestStatus(result.data.data.requeststatus)
          }
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            requestnumber: formattedList,
            updateProps: true
          });
          //this.componentDidMount();
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.data &&
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  validations(data) {
    console.log(data)
    if (!data.instanceaccountid) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo AccountId precisa ser preenchido",
      });

      return false

    }
    if (!data.instancephonenumberid) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo PhoneNumberId precisa ser preenchido",
      });

      return false

    }

    if (!data.instancemessageproviderconfigaccountsid) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo MessageProviderConfigAccountsid precisa ser preenchido",
      });

      return false

    }

    if (!data.instancemessageproviderconfigauthtoken) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo MessageProviderConfigAuthToken precisa ser preenchido",
      });

      return false

    }

    return true

  }

  handleSubmit = async (data) => {

    this.setState({ loading: true });


    let inputValidations = this.validations(data)
    if (inputValidations) {

      this.setState({  updateProps: false });
      //const requestNumber = this.state.requestnumber;
      data.requeststatusid = 3;

      Api.put("/registrationphonenumber", data)

        .then((result) => {
          if (result.data.success) {
            const formattedList = result.data.data;
            const intl = this.props.intl;
            console.log("result data submit")
            console.log(result.data.data)
            if (result.data.data && result.data.data.requeststatus) {
              console.log("entrei no primeiro")
              this.props.updateRequestStatus(result.data.data.requeststatus)
              formattedList.date = moment(result.data.data.createdat).utcOffset("+00:00").format("DD/MM/YYYY HH:mm")
              this.props.addCurrent(formattedList)
              
              this.openConfirmRegister();
            }
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
              requestnumber: formattedList,
              updateProps: true,
            });


            //this.componentDidMount();
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: result.data &&
                result.data.errors && result.data.errors[0]
                  ? result.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          } else {
            const intl = this.props.intl;

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          }
        });
    }

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        [stateName]: value,
      },
    }));
    this.setState({ loading: false });
  }

  async changeSimpleAutocompleteValues(e, v) {
    this.setState({ loading: true });
    const name = e;
    const value = v.id;

    this.setState(prevState => ({
      customer: {
        ...prevState.customer,
        [name]: value
      }
    }));

    this.setState({ loading: false });
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  openCancelRegister = () => {
    this.setState({ openCancelRegisterNumber: !this.state.openCancelRegisterNumber });
  }

  openConfirmRegister = () => {
    this.setState({ openConfirmRegisterNumber: !this.state.openConfirmRegisterNumber });
  }


  render() {
    const { classes, headerMenu } = this.props;
    const { requestnumber } = this.state;
    console.log("props atualizado aqui")
    console.log(this.props)

    const intl = this.props.intl;
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          })}
          style={{ marginBottom: 50 }}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />} Solicitações de registros de mensageria
                <Title />
              </Typography>



              <div style={{ marginTop: '20px', marginBottom: '40px' }}>
                <MyPaper square>
                  <MyTabs
                    value={this.state.tabSelected}
                    onChange={this.handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    centered
                  >
                    <Tab label="Dados do Registro" />
                    <Tab label="Dados da Empresa" />
                  </MyTabs>
                </MyPaper>
                {this.state.tabSelected === 0 && this.state.updateProps && <RequestNumberData openConfirmRegister={this.openConfirmRegister} openConfirmRegisterNumberState={this.state.openConfirmRegisterNumber} handleSubmit={this.handleSubmit} />}
                {this.state.tabSelected === 1 && <CompanyData getFile={this.getFile} />}


              </div>

              <Grid container spacing={4}>
                <Grid container spacing={4} justify="flex-end">
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={3}>
                    <Button
                      onClick={this.openCancelRegister}
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: '#cb1d1d', color:  'white' }}
                      //disabled={requestnumber.requeststatusid != 1}
                    >
                      Cancelar registro
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      //disabled={requestnumber.requeststatusid != 1}
                      onClick={this.openConfirmRegister}
                    >
                      Confirmar registro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/*<DialogGeneral
                dialogTitle="Atenção!"
                dialogSubTitle="Essa solicitação será registrada para o cliente, você tem certeza que deseja continuar?"
                open={this.state.openConfirmRegisterNumber}
                openOrCloseModalFunction={this.openConfirmRegister}
                handleConfirmation={this.handleSubmit}

        />*/}
              <Dialog fullWidth maxWidth="md" open={this.state.openCancelRegisterNumber} onClose={this.openCancelRegister} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">
                  Atenção!
                </DialogTitle>
                <DialogContent style={{ minWidth: "400px" }}>
                  Essa solicitação será cancelada para o cliente, é necessário colocar o motivo:

                  <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} sm={12} >
                      <TextField
                        fullWidth
                        label="Motivo"
                        inputProps={{ maxLength: 255 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="reason"
                        onChange={this.handleInputChange}
                        multiline
                        rows={4}
                        required
                      />
                    </Grid>

                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.openCancelRegister} color="primary" >
                    {intl.formatMessage({ id: "cancel" })}
                  </Button>
                  <Button onClick={this.handleSubmitCancelRegistration}
                    variant="contained"
                    color="primary">
                    Salvar
                  </Button>
                </DialogActions>

              </Dialog>




            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

RequestNumberEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  requestnumber: state.requestnumber
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RequestNumberEdit))
);
