import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@material-ui/core';
                import { withStyles } from '@material-ui/core/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@material-ui/icons/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Footer from '../../components/footer';
                import { FormattedMessage,injectIntl } from 'react-intl';
                import Card from '@material-ui/core/Card';
                import CardContent from '@material-ui/core/CardContent';
                import Typography from '@material-ui/core/Typography';
                import Pagination from '@material-ui/lab/Pagination';  
                import Search from '@material-ui/icons/Search';
                import TextField from '@material-ui/core/TextField';
                import ClearIcon from '@material-ui/icons/Clear';

 
                    import * as componentActions from '../../redux/actions/configs';
                    class Configs extends Component {

                    constructor(props) {
                        super(props);

                        this.typingTimeout = null;

                        const { userSession } = this.props;
                        this.state = {
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],

                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: ''
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.clearFields = this.clearFields.bind(this);
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        this.getItens(page);
                    }
                
                    setPagination(count,page){
                                            
                                            let totalPage = Math.ceil (count/10);
                                            this.setState({ totalPages:totalPage })
                                            if(count > 0){
                                                if(page == 1){
                                                    if(count < 10){
                                                        this.setState({pageStart:1,pageEnd:count});
                                                    } else{
                                                        this.setState({pageStart:1,pageEnd:10});
                                                    }
                                                } else{
                                                    let pageStart = ((page - 1) * 10) + 1;
                                                    
                                                    
                                                    
                                                    if(count >= page * 10){
                                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                                    } else{
                                                        this.setState({pageStart:pageStart,pageEnd:count});
                                                    }
                                                }
                                            } else {
                                                this.setState({totalPages:0,page:1,count:0});
                                            }
                                            
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });
                
                    }
                
                    callSearch(){
                        if(this.state.searchValue != ''){
                            this.getItens(this.state.page);
                        }                        
                    }
                
                    async clearFields(){
                        await   this.setState({searchValue: '', page:1})
                        this.getItens(1);
                    }
                   

                    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                        this.getItens(1);
                    }

                    getItens(page) {
                        let url = `/config/all`;
        
                      

                        this.setState({ loading: true });
                                            Api.get(url)
                            
                                    .then(result => {
                                        //alert(JSON.stringify(result.data.success));
                                        //alert(JSON.stringify(result.data));
                                        if (result.data.success) {

                                            let count = result.data.data.length;
                                            this.setState({count:count});
                                            this.setPagination(count,page);

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,

                                                }
                                            });
                                           // alert(JSON.stringify(formattedList));
                                                    this.setState({ loading: false, resultList: formattedList });
                                        }
                                        else{
                                            const intl = this.props.intl;
                                            this.setState({
                                                loading: false, openNotification: true, notificationVariant: 'error',
                                                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                            });
                                            Api.kickoff(result);
                                        }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        return (
                            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />

                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                        <CssBaseline />

                                        <Can I='List' a='Config'>    
                                       
                                        <MyMaterialTable
                                                         title=""
                                                                   rowClick={(event, rowData) => {
                                                                   let abl = this.context.can('Edit', 'Config');
                                                                       if (abl == false)
                                                                       {
                                                                           this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                                                                           return false;
                                                                       }
                                                                       this.props.addCurrent(rowData);
                                                          this.props.history.push(`/${this.props.match.params.lang}/config-edit`);
                                                               }}
                                            columns={[
                                                       { title: intl.formatMessage({id:"timezone"}), field: 'timezone' },
                                                       { title: intl.formatMessage({id:"date.format"}), field: 'formatdate' },
                                                       { title: intl.formatMessage({id:"decimal.separator"}), field: 'decimalseparator' },
                                                       { title: intl.formatMessage({id:"thousand.separator"}), field: 'thousandseparator' },
                                                       { title: intl.formatMessage({id:"apirecognition"}), field: 'apirecognition' },
                                                       

                                            ]}
                                            data={this.state.resultList}
                                            
                                        />
                                         </Can>  
                                        <Can I='List' a='Config'>
                                         
                                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                                {/*<Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>*/}
                                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                                            </Grid>

                                            </Can>  

                                        {this.state.loading && (
                                            <Overlay>
                                                <CircularProgress color='secondary' />
                                            </Overlay>
                                        )}
                      

                                        <CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />
                                    </Container>
                                </main>
                                <Footer />   
                            </div>
                        );
                    }
                }
Configs.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Configs)));Configs.contextType = AbilityContext;
