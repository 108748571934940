import Api from "./api";

const url = `/hirerconfig`

export const getHirersPaginate = async (page = 1) => {
    let urlPages = url + `/paginate?page=${page}`

    let data = []
    await Api.get(urlPages)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const getByHirerId = async (id) => {
    let urlHirerId = url + `/hirerId`
    let data = []
    const hirerId = {
        id: id
    }

    await Api.post(urlHirerId, hirerId)
        .then(result => {
            data = result.data.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const getHirerConfigById = async (id) => {
    let urlById = url + `/${id}`
    let data = []
    await Api.get(urlById)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const createHirerConfig = async (form) => {
    let data = []
    await Api.post(url, form)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const updateHirerConfig = async (form) => {
    let data = []
    await Api.put(url, form)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const deleteHirerConfig = async (hirerConfigId) => {
    let data = []
    const deleteUrl = url + `/${hirerConfigId}`
    await Api.delete(deleteUrl)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}

export const createEnvironment = async (environment) => {
    let data = []
    const addUrl = url + `/environment`
    await Api.post(addUrl, environment)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response ? err.response.data : err;
        })
    Api.kickoff(data)
    return data
}