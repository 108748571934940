import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch,InputLabel, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container, CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import Logo from '../../components/logo/logo';
import Box from '@material-ui/core/Box';
//import {AsyncStorage} from 'react';

const defaultProps = {
  borderColor: 'text.secondary',
  bgcolor: "text.secondary",
  color:"secondary.contrastText",
  m: 1,
  border: 1,
  style: { width: '15rem', height: '15rem' },
};


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInForm: {
        email: "",
        password: ""
      },
      rememberMe:false,
      loading: false,
      openNotification: false,
      notificationMessage: "",
    };
    
    this.headers = {
      'Content-Type': 'application/json',
    };
  

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.rememberUser = this.rememberUser.bind(this);
    this.getRememberedUser = this.getRememberedUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    async componentDidMount() {
    const userlogin = await this.getRememberedUser();
    //console.log(userlogin);
    //this.setState({ 
       //signInForm: { ['email']: userlogin ? userlogin :  "", },
       //rememberMe: userlogin ? true : false });
       this.setState(prevState => ({
        signInForm: {
          ...prevState.signInForm,
          ['email']: userlogin ? userlogin :  ""
        },
        rememberMe: userlogin ? true : false
      }));

      //console.log(this.state.rememberMe);

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    const { email, password } = this.state.signInForm;
    
    Api.post("/users/login", { email, password }, {
      headers: this.headers
    })
      .then(result => {
        console.log(result)
        console.log("result")
        if (result.data.success) {
          //result.data.data.language=this.props.match.url;
          this.props.addUserSession(result.data.data);
          localStorage.setItem('accesstokensaas', result.data.data.accesstoken);
          localStorage.setItem('reload', true);

          var l = this.props.match.params.lang;
          document.location.href=`/${l}/home`
          //document.location.href="/calleds";

        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: result.data.errors ? result.data.errors[0] : "Erro ao processar a solicitação."
          });
        }
      })
      .catch(err => {
        console.log(err)
        console.log("resultERR")
        if(err.response && err.response.data && err.response.data.errors){
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
          });
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: "Erro ao processar a solicitação."
          });

        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }
  
  toggleRememberMe = (event,value) => {
    console.log(value);
    this.setState({ rememberMe: value })
      if (value === true) {
    //user wants to be remembered.
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  }

  rememberUser = async () => {
        try {
          console.log(this.state.signInForm.email);
          await localStorage.setItem('field-service-cmetch', this.state.signInForm.email);
        } catch (error) {
          // Error saving data
          console.log(error);
        }
  };
  getRememberedUser = async () => {
        try {
          const userlogin = await localStorage.getItem('field-service-cmetch');
          if (userlogin !== null) {
            // We have username!!
            return userlogin;
          }
        } catch (error) {
          // Error retrieving data
          console.log(error);
        }
  };
  forgetUser = async () => {
      try {
        await localStorage.removeItem('field-service-cmetch');
      } catch (error) {
       // Error removing
       console.log(error);
      }
  };

  render() {
    const { classes } = this.props;
    const { signInForm } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Logo key="groups-fieldservice-awrar6aw15" />
          <Typography variant="h3" gutterBottom style={{color: "rgb(100 157 69)"}} >
            {Api.applicationName()}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={signInForm.email}
              onChange={this.handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={{ maxLength: 50, minLength: 6 }}
              value={signInForm.password}
              onChange={this.handleInputChange}
            />

            <Switch
              value={this.state.rememberMe}
              checked={this.state.rememberMe}
              onChange={this.toggleRememberMe}
              />Lembre de mim

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/pt/forgot-password/" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant="error"
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);

export default connect(null, mapDispatchToProps)(withStyles(Styles)(Login));
