import React from "react";
import SimpleAutoComplete from "../auto-complete/autocomplete";
import { Grid, TextField, Typography } from "@material-ui/core";
import SimpleSelect from "../select/simple";
import ContainerConfigs from "../container-configs";

export default function HirerConfigForm(props) {

    const { hirerConfig, intl, optionsList, edit, checkedApplications, domainOptions, applications, cloudProviders, cloudProvider,
        containerImages,
        changeSimpleAutocompleteValues, handleInputChange, handleChangeSelect, handleContainerConfigChange } = props

    return (
        <Grid container spacing={5}>
            {edit ?
                <Grid item xs={12} sm={12}>
                    <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "hirer.name" })}
                        name='hirer'
                        variant="outlined"
                        value={hirerConfig.hirerName ?? ''}
                        disabled
                    />
                </Grid>
                :
                <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                        label={intl.formatMessage({ id: "hirer.name" })}
                        options={optionsList}
                        selected={hirerConfig.hirer}
                        name='hirer'
                        stateName='hirer'
                        changeSelect={changeSimpleAutocompleteValues}
                        required
                        disabled={props.selected}
                    />
                </Grid>
            }
            <Grid item xs={12} sm={5}>
                <TextField
                    type="email"
                    fullWidth
                    label="E-mail"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="email"
                    value={hirerConfig.email ?? ''}
                    onChange={handleInputChange}
                />
            </Grid>

            <Grid item xs={12} sm={5}>
                <TextField
                    fullWidth
                    label="Workspace"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="workspace"
                    value={hirerConfig.workspace ?? ''}
                    onChange={handleInputChange}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <SimpleSelect
                    label="Domain"
                    stateName="domain"
                    options={domainOptions}
                    selected={hirerConfig.domainid}
                    changeSelect={handleChangeSelect}
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField
                    fullWidth
                    label="Server"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="dbHost"
                    value={hirerConfig.dbHost ?? ""}
                    onChange={handleInputChange}
                    required={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    type="text"
                    fullWidth
                    label="DataBase User Name"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="dbUserName"
                    value={hirerConfig.dbUserName ?? ""}
                    onChange={handleInputChange}
                    required={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    type="text"
                    fullWidth
                    label="DataBase Name"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="dbName"
                    value={hirerConfig.dbName ?? ""}
                    onChange={handleInputChange}
                    required={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField
                    fullWidth
                    label="DataBase Password"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="dbPassword"
                    value={hirerConfig.dbPassword ?? ""}
                    onChange={handleInputChange}
                    required={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField
                    fullWidth
                    label="DataBase Port"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="dbPort"
                    value={hirerConfig.dbPort ?? ""}
                    onChange={handleInputChange}
                    required={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <TextField
                    fullWidth
                    label="String Connection"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="stringConnection"
                    value={hirerConfig.stringConnection ?? ''}
                    onChange={handleInputChange}
                    required
                />
            </Grid>

            <Grid item sm={12}>
                <Typography component="h1" variant="h6" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "provider.configs" })}
                </Typography>
            </Grid>

            <Grid item container sm={12} justify="space-around">
                <Grid item sm={4}>
                    <SimpleSelect
                        label="Cloud"
                        stateName="cloudProvider"
                        options={cloudProviders}
                        selected={cloudProvider ? cloudProvider.id : 0}
                        changeSelect={handleChangeSelect}
                    />
                </Grid>
                <Grid item sm={4}>
                    <SimpleSelect
                        label="Config"
                        stateName="cloudProviderConfig"
                        options={cloudProvider.cloudproviderconfigs ?? []}
                        selected={hirerConfig.cloudProviderConfigId}
                        changeSelect={handleChangeSelect}
                    />
                </Grid>
            </Grid>

            <Grid container item sm={12} spacing={4} justify="center">
                {
                    checkedApplications.includes(1) &&
                    <Grid container xs={12} item sm={4} spacing={2}>
                        <Grid item sm={12}>
                            <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>2DO</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Api 2DO"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlApiCube"
                                value={hirerConfig.urlApiCube ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Front 2DO"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlFrontCube"
                                value={hirerConfig.urlFrontCube ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                    </Grid>
                }

                {
                    checkedApplications.includes(2) &&
                    <Grid container xs={12} item sm={4} spacing={2}>
                        <Grid item sm={12}>
                            <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>2TALK</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Api 2TALK"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlApiCone"
                                value={hirerConfig.urlApiCone ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Front 2TALK"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlFrontCone"
                                value={hirerConfig.urlFrontCone ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                    </Grid>
                }

                {
                    checkedApplications.includes(3) &&
                    <Grid container xs={12} item sm={4} spacing={2}>
                        <Grid item sm={12}>
                            <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>2SEE</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Api 2SEE"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlApiVertex"
                                value={hirerConfig.urlApiVertex ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Front 2SEE"
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                name="urlFrontVertex"
                                value={hirerConfig.urlFrontVertex ?? ''}
                                onChange={handleInputChange}
                                disabled={!edit}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>

            <Grid item sm={12}>
                <Typography component="h1" variant="h6" style={{ textAlign: "center" }}>Micro Serviços</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    label="Api Called Service"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="urlApiCubeCalledService"
                    value={hirerConfig.urlApiCubeCalledService ?? ''}
                    onChange={handleInputChange}
                    disabled={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    label="Front Called Service"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="urlFrontCubeCalledService"
                    value={hirerConfig.urlFrontCubeCalledService ?? ''}
                    onChange={handleInputChange}
                    disabled={!edit}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    label="Api Reports"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="urlApiCubeReports"
                    value={hirerConfig.urlApiCubeReports ?? ''}
                    onChange={handleInputChange}
                    disabled={!edit}
                />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Api 2TALK Service"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    name="urlApi2TalkService"
                    value={hirerConfig.urlApi2TalkService ?? ''}
                    onChange={handleInputChange}
                    disabled={!edit}
                />
            </Grid> */}

            <Grid item sm={12}>
                <Typography component="h1" variant="h6" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "container.configs" })}
                </Typography>
            </Grid>
            <ContainerConfigs
                containers={hirerConfig.containerConfig} applications={applications}
                handleContainerConfigChange={handleContainerConfigChange} containerImages={containerImages}
            />
        </Grid>
    )
}