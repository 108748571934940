import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Menu, QueuePlayNext, ChevronLeft, Home } from '@material-ui/icons';
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Container, Grid, CssBaseline, Button, ButtonGroup } from '@material-ui/core';
import UserMenu from '../user-menu';
import GroupMenu from '../group-menu';
import GroupMenu2 from '../group-menu/index-2';
import UserMenuMobile from '../user-menu-mobile';
import UserInfo from '../user-info';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as headerMenuActions from '../../redux/actions/header-menu';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'

import Api from '../../services/api';
import LogoSmall from '../logo/logo-small';
import LogoSmall2 from '../logo/logo-small-2';
import Logo from '../logo/logo';
import { browserHistory } from '../../helpers/history';
import { withRouter } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CachedIcon from '@material-ui/icons/Cached';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Person from '@material-ui/icons/Person';
import Receipt from '@material-ui/icons/Receipt';
import TransitionsModal from '../modal/modal';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import AppMenu from '../appmenu/appmenu'
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

function ListItemLink(props) {
  const { icon, primary, to, state } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={{
      pathname: to,
      state: state
    }} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}


ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  state: PropTypes.string,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }),
)
const drawerWidth = 240;
function click(i) {
  //console.log(i);
}

class HeaderMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      subsopen: [false, false, false, false],
      matches: window.matchMedia("(max-width: 660px)").matches,
      style: { paddingLeft: '30px', color: '#ffffff', fontSize: '20px' },
      styleMobile: { paddingLeft: '15px', color: '#ffffff', fontSize: '15px' },
      modalopen: false,
      modalgroup: false,
      WindowSize: window.innerWidth,
      marginLeft: window.innerWidth > 540 ? ((window.innerWidth - 540) * 0.8) : ((window.innerWidth - 310) * 0.8),
      GroupList: [],
      variantDot: '',
      listNotification: [],
      qtdNotification: 0,
      openSteps: false,
      orgId: 0
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalGroup = this.openModalGroup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
  }

  calculate() {
    if (this.state.WindowSize > 540) {
      this.setState({ marginLeft: ((this.state.WindowSize - 540) * 0.8) });
    } else {

      this.setState({ marginLeft: ((this.state.WindowSize - 310) * 0.8) });
    }


  }
  openModal() {
    this.setState({ modalopen: true });

  }
  async closeModal() {
    this.setState({ modalopen: false });

  }

  openModalGroup() {
    this.setState({ modalgroup: true });

  }
  async closeModalGroup() {
    this.setState({ modalgroup: false });

  }


  handleClick = (i) => {

  }
  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth })

    this.calculate();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const handler = (e) => {
      this.setState({ matches: e.matches })
    };
    window.matchMedia("(max-width: 660px)").addListener(handler);

  }

  componentWillUnmount() {
    window.addEventListener("resize", null);
  }

  handleDrawerOpen = () => {
    this.props.location.state = "";
    this.props.toggle(true);

  };
  handleReload = () => {

    const { router, params, match, location, history, routes } = this.props

    const current = location.pathname;
    this.props.location.state = "reload";
    this.props.reload(true);

  }
  handleDrawerClose = () => {
    this.props.location.state = "";
    this.props.toggle(false);
  };

  render() {
    const { classes, headerMenu, userSession, history, location } = this.props;

    const appMenuItems = [];

    if (userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    /*
        GlobalAdministrator = 0,
        Administrator = 1,
        Technical = 2,
        Client = 3,
        Coordenator = 4,    
    */
    //console.log(userSession);
    this.isAdm = false;
    this.isClient = true;
    this.isAdmOrg = false;
    this.isAdmGlobal = false;
    this.isAdmGroup = false;
    this.isTec = false;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 6 || item.role.roletype == 4) {
        this.isAdm = true;
        if (item.role.roletype == 0) {
          this.isAdmGlobal = true;
        }
      }
      if (item.role.roletype == 1 && item.role.roletype != 0 && !this.isAdmGlobal) {
        this.isAdmOrg = true;
      }
      if (item.role.roletype == 2) {
        this.isTec = true;
      }
    });


    appMenuItems.push(
      {
        name: this.props.intl.formatMessage({ id: "home" }),
        link: `/${this.props.match.params.lang}/home`,
        Icon: Home,
      });

    //if (this.context.can('List', 'Sales')) {

    //}

    appMenuItems.push({ link: 'divider' });



    if (this.context.can('List', 'Hirer')) {
      var access = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.hirers" }),
          Icon: Receipt
        });
      appMenuItems[access - 1].items = [];
    }

    if (this.context.can('List', 'Hirer')) {
      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.hirers" }),
          link: `/${this.props.match.params.lang}/hirers`,
          //Icon: AccountBoxIcon,
          state: "hirers",
        });

      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.configs" }),
          link: `/${this.props.match.params.lang}/hirers-config`,
          state: "hirerconfig",
        })

        appMenuItems[access - 1].items.push(
          {
            name: this.props.intl.formatMessage({ id: "environment.add" }),
            link: `/${this.props.match.params.lang}/environment-registration`,
            state: "environment",
          })
    }

    if (this.context.can('List', 'Customer')) {
      var access = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.customers" }),
          Icon: Person
        });
      appMenuItems[access - 1].items = [];
    }

    if (this.context.can('List', 'Customer')) {
      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.customers" }),
          link: `/${this.props.match.params.lang}/customers`,
          //Icon: AccountBoxIcon,
          state: "customers",
        });

      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.newcustomer" }),
          link: `/${this.props.match.params.lang}/active-customer`,
          //Icon: AddIcon,
        });
    }

    if (this.context.can('List', 'User')) {
      var access = appMenuItems.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.access" }),
          Icon: GroupAddIcon
        });
      appMenuItems[access - 1].items = [];
    }

    if (this.context.can('List', 'User')) {
      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.users" }),
          link: `/${this.props.match.params.lang}/users`,
          //Icon: AccountBoxIcon,
          state: "users",
        });
    }
    if (this.context.can('List', 'Role')) {
      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.roles" }),
          link: `/${this.props.match.params.lang}/roles`,
          //Icon: SupervisedUserCircleIcon,
          state: "roles",
        });
    }

    if (this.context.can('List', 'Permission')) {
      appMenuItems[access - 1].items.push(
        {
          name: this.props.intl.formatMessage({ id: "menu.permissions" }),
          link: `/${this.props.match.params.lang}/permissions`,
          //Icon: FunctionsIcon,
          state: "permissions",
        });
    }

    var apipredict = [];


    apipredict.push({
      name: "Solicitações de Retreino",
      link: "predict-retraining"
    });

    apipredict.push({
      name: "Modelos Treinados",
      link: "predict-training"
    });

    apipredict.push({
      name: "Equipamentos Registrados",
      link: "predict-equipment"
    });
    apipredict.push({
      name: "Quantitativo de Imagens",
      link: "predict-image"
    });

    apipredict.push({
      name: "URL Retreinamento",
      link: "predict-url"
    });


    var predict = appMenuItems.push(
      {
        name: "API de Reconhecimento",
        Icon: ImageSearchIcon,
        //children: apipredict,
      });

    appMenuItems[predict - 1].items = apipredict;



    if (this.context.can('List', 'Server')) {
      if (this.context.can('List', 'Config')) {
        var config = appMenuItems.push(
          {
            name: "Configurações",
            Icon: SettingsIcon
          });
        appMenuItems[config - 1].items = [];

        appMenuItems[config - 1].items.push(
          {
            name: "Configuração",
            link: `/${this.props.match.params.lang}/configs`,
          });

        appMenuItems[config - 1].items.push(
          {
            name: "Servidores",
            link: `/${this.props.match.params.lang}/servers`,
            //Icon: FunctionsIcon,
            //state: "permissions",
          });
      }
    }

    var talkMenu = appMenuItems.push(
      {
        name: "2TALK",
        Icon: QuestionAnswerIcon
      });

    appMenuItems[talkMenu - 1].items = [];

    appMenuItems[talkMenu - 1].items.push(
      {
        name: "Solicitações de registros mensageria",
        link: `/${this.props.match.params.lang}/requestnumbermessaging`,
      });

    /*
    
          if (this.context.can('List', 'Config')) {
    
            var configs = appMenuItems.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.configs" }),
                Icon: SettingsApplicationsIcon
              });
            appMenuItems[configs - 1].items = [];
    
            appMenuItems[configs - 1].items.push(
              {
                name: this.props.intl.formatMessage({ id: "menu.configs" }),
                link: `/${this.props.match.params.lang}/configs`,
                //Icon: SettingsApplicationsIcon,
                state: "configs",
              });
          }
          */

    if (userSession.user == null) {
      Api.logoff();
    }
    //console.log(userSession);
    const intl = this.props.intl;

    var organizationName = <FormattedMessage id="Administração" />;

    const MenuGroup = (item) => (
      <GroupMenu group={item} />
    )
    const MenuGroup2 = (item) => (
      <GroupMenu2 group={item} />
    )
    /*
  const handleSteps = (id) => (
    //this.setState({ openSteps: true, orgId: id })
    
  )*/

    return (
      <div>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: headerMenu.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, headerMenu.open && classes.hide)}

            >
              <Menu />
            </IconButton>
            <LogoSmall2 />
            {!this.state.matches && <Typography variant="h6" className={classes.title}>
              <div className={classes.title} style={this.state.style}>

                {organizationName}

              </div>
            </Typography>}
            {this.state.matches && <span style={{ marginLeft: '10px' }}><UserMenuMobile organizationName={organizationName} /></span>}

            <IconButton color="inherit"
              aria-label="open drawer"
              onClick={this.handleReload}
              edge="start"
            >
              <CachedIcon />
            </IconButton>

            <TransitionsModal isopen={this.state.modalgroup} handleOpen={this.openModalGroup} handleClose={this.closeModalGroup}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <Card className="background-title-search">
                    <CardContent>

                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} md={12}>
                          <Typography variant="h5">
                            {<FormattedMessage id="group.par" />}
                          </Typography>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} style={{ fontSize: '15px' }}>
                  {organizationName}
                </Grid>
              </Grid>
            </TransitionsModal>

            {!this.state.matches && <UserMenu />}
            {!this.state.matches && <UserInfo />}
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={headerMenu.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <LogoSmall /><ChevronLeft />
            </IconButton>
          </div>
          <Divider />

          <AppMenu appMenuItems={appMenuItems} />

        </Drawer>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attNotification: PropTypes.array
};



const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(headerMenuActions, dispatch);



export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HeaderMenu))));
HeaderMenu.contextType = AbilityContext;