import React from "react"
import { Grid, TextField } from "@material-ui/core"
import NavTabs from "../tab/tab"
import SimpleSelect from "../select/simple"

const modules = {
    1: "API",
    2: "FRONT"
}
export default function ContainerConfigs(props) {
    const { containers, applications, handleContainerConfigChange, containerImages } = props
    
    if (applications.length <= 0) {
        return (
            <div></div>
        )
    } else {
        return (
            <Grid item sm={12}>
                <NavTabs
                    full
                    components={containers.map((c, i) => {
                        const module = c.containerimage.module
                        const app = applications.find(app => app.id == c.containerimage.applicationid).name
                        return (
                            <Grid container displayname={app + ' - ' + modules[module]} spacing={2}>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Request Memory"
                                        type="number"
                                        name="requestMemory"
                                        value={c.requestmemory}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Request Cpu"
                                        type="number"
                                        name="requestCpu"
                                        value={c.requestcpu}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Limit Memory"
                                        type="number"
                                        name="limitMemory"
                                        value={c.limitmemory}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Limit Cpu"
                                        type="number"
                                        name="limitCpu"
                                        value={c.limitcpu}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Min Replicas"
                                        type="number"
                                        name="minReplicas"
                                        value={c.minreplicas}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Max Replicas"
                                        type="number"
                                        name="maxReplicas"
                                        value={c.maxreplicas}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Limit Cpu Utilization"
                                        type="number"
                                        name="limitCpuUtilization"
                                        value={c.limitcpuutilization}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        label="Limit Memory Utilization"
                                        type="number"
                                        name="limitMemoryUtilization"
                                        value={c.limitmemoryutilization}
                                        onChange={e => handleContainerConfigChange(e.target.name, e.target.value, i)}
                                    />
                                </Grid>

                                <Grid item sm={12}>
                                    <SimpleSelect
                                        label="Image"
                                        options={containerImages.filter(ci => ci.applicationid == c.containerimage.applicationid)}
                                        selected={c.containerimageid}
                                        stateName="containerImageId"
                                        changeSelect={(name, value) => handleContainerConfigChange(name, value, i)}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                />
            </Grid>
        )
    }
}