export function addCurrent(requestnumber) {
    return {
        type: 'ADD_CURRENT_REQUESTNUMBER',
        requestnumber,
    }
}

export function updateRequestStatus(requeststatus) {
    console.log("entrei aqui 1")
    return {
        type: 'UPDATE_REQUEST_STATUS',
        requeststatus,
    }
}