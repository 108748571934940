export default function contracts(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT_CONTRACT':
            return {
                ...state,
                contract: action.contract,
            }
        default:
            return state;
    }
}