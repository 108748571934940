import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';

console.log(process.env.REACT_APP_API);
console.log(process.env);
const Api = axios.create({
    baseURL: process.env.REACT_APP_API,
    
});

//axios.defaults.baseURL = 'http://localhost:1010/'
//axios.defaults.headers.common = {'Authorization': `bearer ${token}`}

Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
Api.defaults.headers.post['Accept']='application/json';
Api.defaults.headers.post['Content-Type'] ='application/json';

let token = localStorage.getItem('accesstokensaas');

if(token == null){
}
Api.applicationName = function (){
  return "SaaS";
}

Api.logoff = function (){
  userSessionActions.removeUserSession();
  document.location.href='/?sessão expirada';
}

Api.kickoff = function (result){
  //console.log("entrei aqui no kickoff")
  
  if(result == null){
    return;
  }
  if((result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
 
    if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
   
      userSessionActions.removeUserSession();
      document.location.href='/?sessão expirada';
    }
  } 
  if((result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
 
    if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
    
      userSessionActions.removeUserSession();
      document.location.href='/?sessão expirada';
    }
  }
  
  if(result.errors && result.errors[0] ){
      if(result.errors[0] === "Error: invalid_token")
      {
      
        userSessionActions.removeUserSession();
        document.location.href='/?sessão expirada';
      }
  }
  
 
  if(result.data && result.data.errors && result.data.errors[0])
  {
    if((result.data.errors[0] === "Error: invalid_token"))
    {
     
      userSessionActions.removeUserSession();
      document.location.href='/?sessão expirada';
     
    }
  }
 

}
//console.log(token);
Api.defaults.headers.common['Authorization']='Bearer '+token;

export default Api;
