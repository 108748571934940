import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles/material-styles";
import { Typography, Container, Grid, CssBaseline, Button, ButtonGroup } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSmall2 from '../logo/logo-small-2';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { Can } from '../../config/ability-context';
import { AbilityContext } from '../../config/ability-context';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import CustomizedSnackbars from "../../components/material-snackbars";

var isLoggedIn=true;

const fabButton = {
  position: 'absolute',
  zIndex: 1,
  top: -35,
  left: '75%',
  right: 0,
  margin: '0 auto'
};

const chatConfigStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: '50%',
  right: 0,
  margin: '0 auto'
};

const ChatListButtonStyle = {
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: '62%',
  right: 0,
  margin: '0 auto'
};


const footerStyle = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    textAlign:"center",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
  };

  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  const footerStyleMobile = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
    
  };

  const phantomStyle = {
    display: "block",
    padding: "50px",
    height: "60px",
    width: "100%",
    marginTop:"200px",

  };

  const styles = {
    container: isRowBased => ({
      top:'300%' ,
      backgroundColor: "#f1f3f4",
      fontSize: "20px",
      color: "white",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "10px",
      position: "absolute",
      left: "0",
      bottom: "0",
      height: "40px",
      width: "100%",
      zIndex: 0,      
      flexDirection: isRowBased ? 'row' : 'column',
    })
  };


  function FooterContent({ children }) {
    const matches = useMediaQuery('(max-width:530px)');

    if(isLoggedIn){
      return (
        <div>
                  <div>{children}</div>
        </div>
      );
    }
    else{
     return (<div></div>);
    }
    
  }
  

class Footer extends Component {

    constructor(props) {
      super(props);
      if(props.userSession == null){
        isLoggedIn=false;
      }

      this.state = {
        modalopen:false,
        openNotification: false,
        notificationVariant: "error",
        notificationMessage: "",

        matches: window.matchMedia("(max-width: 599px)").matches
      };
      this.QRCode = this.QRCode.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.closeNotification = this.closeNotification.bind(this);

    
    }
    closeNotification() {
      this.setState({ openNotification: false });
    }

    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    componentDidMount() {
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(max-width: 599px)").addListener(handler);
    }
  
    QRCode(){
      this.openModal();
    }
  
    openModal(){
      this.setState({  modalopen:true });
  
    }
    async closeModal(){
        this.setState({  modalopen:false });
  
    }
  
    render() {
        const { classes, userSession } = this.props;
        this.isClient = true;
        var link = `/${this.props.match.params.lang}/called-registration`;
        if(userSession == null){
           isLoggedIn=false;
        }
        else{
          userSession.user.userrole.map((item, key) => {
                  if(item.role.roletype !=3){
                      this.isClient = false;
                  }
              }
          );
        }
        if(this.isClient){
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }

        var version = process.env.REACT_APP_VERSION;
        if (version == null || version == "") {
            version="1.0.0";
        }    
        return (
          
            <FooterContent >
              {this.state.matches && 
              <div style={footerStyleMobile}>
              <Container component='main' maxWidth='xl'>
                  <CssBaseline />
                  <div className={classes.paper} style={{ marginTop: 0 }}>
                  <Grid container xs style={{ paddingTop: 0, ...flex }}>
                      <Grid item style={{ paddingTop: 0, ...flex }}>
                          <Typography variant="h6" className={classes.title}>
                              <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                  CMTECH SaaS <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                              </div>
                          </Typography>
                          <ButtonGroup>
                          <Can I="Open" a="Called">
                            <Tooltip title="Novo Chamado" aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={fabButton}>
                                  <IconButton color="inherit" component={Link} {...{ to: link }}>
                                    <AddIcon />
                                  </IconButton>    
                              </Fab>
                            </Tooltip>
                          </Can>  

                          </ButtonGroup>


                      </Grid>
                  </Grid>    


                  </div>
 

              </Container>  
        </div>} {!this.state.matches && 
         <>
              <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
              />
                 <div style={{...footerStyle, position: "fixed", zIndex: 2, padding: 0}}>
              <Container component='main' maxWidth='xl'>
                  <CssBaseline />
                  <div className={classes.paper} style={{ marginTop: 0, paddingTop: 0, height: "25px" }}>
                  <Grid container style={{ paddingTop: 0, ...flex }}>
                  <Grid item xs style={{ paddingTop: 0, ...flex }}>
                          <Typography variant="h6" className={classes.title}>
                          <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                  CMTECH SaaS <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                              </div>
                          </Typography>
                          <ButtonGroup>
                          <Can I="Open" a="Called">
                            <Tooltip title="Novo Chamado" aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={fabButton}>
                                  <IconButton color="inherit" component={Link} {...{ to: link }}>
                                    <AddIcon />
                                  </IconButton>    
                              </Fab>
                            </Tooltip>
                          </Can>  

                          </ButtonGroup>


                      </Grid>
                  </Grid>    


                  </div>


              </Container>  
        </div>
        </> 
        }
              

            </FooterContent>


        );
      }
    }
    
    Footer.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    
    
    
    const mapStateToProps = state => ({
      Footer: state.Footer,
      userSession: state.userSession,
    });
    
    
    export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(Footer))));
    Footer.contextType = AbilityContext;