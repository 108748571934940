import { combineReducers } from 'redux';
import headerMenu from './header-menu';
import userSession from './user-session';
import users from './users';
import hirers from './hirers';
import hirerconfig from './hirer-config'
import contracts from './contracts'
import roles from './roles';
import permissions from './permissions';
import configs from './configs';
import customers from './customers';
import servers from './servers';
import retrains from './retrains';
import requestnumber from './requestnumber';

export default combineReducers({
    headerMenu,
    userSession,
    users,
    roles,
    permissions,
    configs,
    hirers,
    hirerconfig,
    contracts,
    customers,
    servers,
    retrains,
    requestnumber
});
