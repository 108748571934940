import Api from './api';


export const getOrganizations = async (id = 0) => { 
    let data = [];
    if(id != 0){
      await Api.get('/users/techinical/organization/'+id)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
    }else{
      await Api.get('/organizations')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
      });
    }
    
    return data;
  };

  export const getAllTec = async () => { 
    let data = [];
    await Api.get('/users/techinical')
    .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err;
    });
    
    return data;
  };

  export const getGroups = async () => {
    let data = [];
    await Api.get('/groups')
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getDepartaments = async (ids) => {
    let data = [];
    await Api.get(`/users/departaments/all?ids=${ids}`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getOrganizationsByGroup = async (ids) => {
    let data = [];
    await Api.get(`/users/organizations/all?ids=${ids}`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getDepartamentList = async (orgId) => { 
    let data = [];
    await Api.get(`/organizationdepartaments/organization/${orgId}`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  

export const getRoles = async () => { 
    let data = [];
    await Api.get('/users/roles')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getAddresses = async () => { 
    let data = [];
    await Api.get('/addresses')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getRolesByUser = async (id) => { 
    let data = [];
    await Api.get('/roles/user/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getWorkHours = async (id) => { 
    let data = [];
    await Api.get('/workhours')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getWorkHoursList = async (id) => { 
    let data = [];
    await Api.get('/workhours/list')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  