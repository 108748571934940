import React, {  } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@material-ui/core";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { injectIntl } from 'react-intl';




function DialogGeneral(props) {

  const intl = props.intl;

  

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.dialogSubTitle}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openOrCloseModalFunction()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={() => { props.handleConfirmation() }} 
                      variant="contained"
                      color="primary">
           Salvar
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(DialogGeneral);