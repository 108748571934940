import { Accordion, AccordionDetails, AccordionSummary, Grid, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import SimpleSelect from "../select/simple";
import MaterialUIPickerInline from "../date-pickers/inline-no-today";
import SimpleCheckBox from "../checkbox/check";

function stringToDate(dataString) {
    var parts = dataString.split('/')
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10) - 1 // No objeto Date, os meses vão de 0 a 11
    var year = parseInt(parts[2], 10)
    const date = new Date(year, month, day)
    return date
}

export default function ContractForm(props) {

    const { contract, intl, readOnly, handleChange, handleChangeStatus, changeDate, handleChangeSelect } = props

    let { startdate, billingstartdate, enddate } = contract
    startdate = stringToDate(startdate).toDateString()
    enddate = stringToDate(enddate).toDateString()
    billingstartdate = stringToDate(billingstartdate).toDateString()
    const cpp = contract.contractplanperiodicities
    const selectedApplications = cpp.map(cpp => (cpp.planperiodicity.checked))

    return (
        <Grid container spacing={2}>
            <Grid container item sm={12} spacing={2} justify="center">
                <Grid item sm={2}>
                    <SimpleSelect
                        label={intl.formatMessage({ id: "menu.statuses" })}
                        options={props.contractstatuses}
                        selected={contract.contractstatusid}
                        changeSelect={(stateName, value) => handleChangeStatus(value)}
                        disabled={!props.edit}
                    />
                </Grid>
                <Grid item sm={2}>
                    <MaterialUIPickerInline
                        label={intl.formatMessage({ id: "start.date" })}
                        currentDate={startdate}
                        stateName="startdate"
                        changeDate={changeDate}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item sm={2}>
                    <MaterialUIPickerInline
                        label={intl.formatMessage({ id: "end.date" })}
                        currentDate={enddate}
                        stateName="enddate"
                        changeDate={changeDate}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item sm={2}>
                    <MaterialUIPickerInline
                        label={intl.formatMessage({ id: "start.billing" })}
                        currentDate={billingstartdate}
                        stateName="billingstartdate"
                        changeDate={changeDate}
                        disabled={readOnly}
                    />
                </Grid>
            </Grid>
            {props.applications.map((application, i) => {
                return (
                    <Grid container item key={application.id} sm={12} justify="center">
                        <Accordion expanded={selectedApplications[i]}>
                            <AccordionSummary>
                                <Grid item>
                                    <SimpleCheckBox
                                        label={application.name}
                                        selected={cpp[i] ? cpp[i].planperiodicity.checked : false}
                                        changeSelect={(e, v) => handleChangeSelect(i, "checked", v)}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container item spacing={10} justify="space-between">
                                    <Grid container item sm={3} spacing={2}>
                                        <Grid item sm={12}>
                                            <SimpleSelect
                                                label={intl.formatMessage({ id: "plan" })}
                                                options={props.plans}
                                                selected={cpp[i] ? cpp[i].planperiodicity.planid : props.plans[0].id}
                                                changeSelect={(e, v) => handleChangeSelect(i, "planid", v)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <SimpleSelect
                                                label={intl.formatMessage({ id: "period" })}
                                                options={props.periodicities}
                                                selected={cpp[i] ? cpp[i].planperiodicity.periodicityid : props.periodicities[0].id}
                                                changeSelect={(e, v) => handleChangeSelect(i, "periodicityid", v)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={3} spacing={2}>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "price" })}
                                                value={cpp[i] ? cpp[i].planvalue : ""}
                                                type="number"
                                                InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "planvalue", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "disk.limit" })}
                                                value={cpp[i] ? cpp[i].disklimitusage : ""}
                                                type="number"
                                                InputProps={{ endAdornment: <InputAdornment position="end">GB</InputAdornment> }}
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "disklimitusage", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={3} spacing={2}>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "agent.limit" })}
                                                value={cpp[i] ? cpp[i].agentlimitquantity : ""}
                                                type="number"
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "agentlimitquantity", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "agent.value" })}
                                                value={cpp[i] ? cpp[i].additionalvalueagent : ""}
                                                type="number"
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "additionalvalueagent", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={3} spacing={2}>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "attendance.limit" })}
                                                value={cpp[i] ? cpp[i].attendancelimitpermonth : ""}
                                                type="number"
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "attendancelimitpermonth", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={intl.formatMessage({ id: "attendance.value" })}
                                                value={cpp[i] ? cpp[i].attendancevalue : ""}
                                                type="number"
                                                required={selectedApplications[i]}
                                                onChange={e => handleChange(i, "attendancevalue", e.target.value)}
                                                disabled={readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )
            })
            }
        </Grid>
    )
}