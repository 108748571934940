import React, { Component } from "react";
import clsx from "clsx";
import HeaderMenu from "../../components/header-menu";
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Styles } from "../../styles/material-styles";
import SimpleCheckBox from "../../components/checkbox/check";
import { browserHistory } from "../../helpers/history";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import * as Service from "../../services/hirer.service"
import HirerForm from "../../components/hirer-form";

class hirerRegistration extends Component {
    constructor(props) {
        super(props)

        let accessKey = ""
        const size = 55
        for (let i = 0; i < size; i++) {
            let char = ''
            do {
                const charValue = Math.floor(Math.random() * (126 - 33 + 1)) + 33
                char = String.fromCharCode(charValue)
            } while (char === '"' || char === "'" || char === ';' || char === '`')
            accessKey += char
        }
        this.intialState = {
            name: "",
            document: "",
            phone: "",
            email: "",
            responsiblename: "",
            responsiblephone: "",
            responsiblemail: "",
            website: "",
            active: false,
            blocked: false,
            accesskey: accessKey,
        }

        this.state = {
            item: this.intialState,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
        }

        this.closeNotification = this.closeNotification.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(key, value) {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [key]: value,
            }
        }))
    }

    closeNotification() {
        this.setState({
            openNotification: false
        })
    }

    async handleSubmit(e) {
        e.preventDefault()
        this.setState({ loading: true })
        const result = await Service.createHirer(this.state.item)

        if (result.success) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationMessage: this.props.intl.formatMessage({ id: "add.success" }),
                notificationVariant: "success",
            })
            window.location.reload()
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationMessage: result.errors ? result.errors[0] : this.props.intl.formatMessage({ id: "process.error" }),
                notificationVariant: "error"
            })
        }
    }

    render() {
        const { intl, classes, headerMenu } = this.props
        const { item } = this.state
        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container component="main" maxWidth="md">
                        <CssBaseline />
                        <Button
                            variant="outlined"
                            onClick={browserHistory.goBack}
                            color="secondary"
                        >
                            <FormattedMessage id="back" />
                        </Button>

                        <form className={classes.form} onSubmit={this.handleSubmit}>
                            <Grid container item spacing={3}>
                                <Grid container item sm={12} alignItems="center" direction="column">
                                    <Avatar className={classes.avatar} />
                                    <Typography component="h1" variant="h5">
                                        <FormattedMessage id="new.hirer" />
                                    </Typography>
                                </Grid>
                                <HirerForm
                                    hirer={item} intl={intl} classes={classes}
                                    handleInputChange={this.handleInputChange}
                                />

                                <Grid container justify="flex-end" item>
                                    <Grid item sm={3}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            <FormattedMessage id="add.item" />
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </form>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userSession: state.userSession,
    headerMenu: state.headerMenu,
})

export default injectIntl(
    connect(mapStateToProps)(withStyles(Styles)(hirerRegistration))
)