import React, { Component } from "react";
import HeaderMenu from "../../components/header-menu";
import PropTypes from "prop-types"
import * as componentActions from '../../redux/actions/hirer-config';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Container, Grid, withStyles, CircularProgress, CssBaseline } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import MyMaterialTable from '../../components/table';
import { Styles } from "../../styles/material-styles";
import { AbilityContext, Can } from "../../config/ability-context";
import { Pagination } from "@material-ui/lab";
import * as Service from "../../services/hirerConfig.service";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
class HirersConfigs extends Component {
    constructor(props) {
        super(props)

        this.typingTimeout = null

        const { userSession } = this.props

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            resultList: [],

            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
        }

        var globalAdm = false
        this.AdmOrganization = false
        this.HirerId = userSession.user.id
        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype == 0) {
                globalAdm = true
            }
            if (item.role.roletype == 1 && globalAdm == false) {
                this.AdmOrganization = true
            }
        })
        this.delete = this.delete.bind(this)
        this.getItens = this.getItens.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
        this.goToAdd = this.goToAdd.bind(this)
        this.getHirerConfig = this.getHirerConfig.bind(this)
    }

    closeNotification() {
        this.setState({ openNotification: false })
    }

    onChangePagination = (e, page) => {
        this.setState({ page: page })
        this.getItens(page)
    }

    goToAdd() {
        this.props.addCurrent({ hirer: null })
        this.props.history.push({ pathname: `/${this.props.match.params.lang}/hirer-config-registration` })
    }

    setPagination(count, page) {
        const totalPage = Math.ceil(count / 10)
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }
    }

    async delete(data, resolve) {
        const intl = this.props.intl
        this.setState({ loading: true })
        if (this.context.can("Delete", "HirerConfig") == false || data.hireractive == true) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }),
                notificationVariant: "error"
            })
            resolve()
            return
        }
        try {
            const result = await Service.deleteHirerConfig(data.id)
            if (result.success) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                    resultList: this.state.resultList.filter(listItem => listItem.id != data.id)
                })
                this.getItens(this.state.page)
                resolve()
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.data.errors && result.data.errors[0]
                            ? result.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                })
                resolve()
            }
        } catch (err) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
            })
            resolve()
        }
    }

    async getItens(page = 1) {
        this.setState({ loading: true })
        try {
            let data = await Service.getHirersPaginate(page)
            this.setState({ resultList: data.data, count: data.count })
            this.setPagination(data.count, page)

        } catch (err) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationMessage: this.props.intl.formatMessage({ id: "process.error" }),
                notificationVariant: "error"
            })
        }
        this.setState({ loading: false })
    }

    getHirerConfig = async (id) => {
        this.setState({ loading: true })
        const result = await Service.getHirerConfigById(id)
        if (result.success) {
            this.props.addCurrent(result.data)
            this.props.history.push({
                pathname: `/${this.props.match.params.lang}/hirer-config-edit`
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationMessage: result.errors[0],
                notificationVariant: "error"
            })
        }
    }

    componentDidMount() {
        this.getItens()
    }

    render() {
        const { classes, headerMenu } = this.props
        const intl = this.props.intl

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open
                    })}
                    style={{ marginBottom: 50 }}
                >
                    <div className={classes.drawerHeader} />
                    <Container component="main" maxWidth="xl" style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid item xs={12}>
                            <Can I="Add" a="Config">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        style={{ marginRight: 5, marginBottom: 5 }}
                                        onClick={this.goToAdd}
                                    >
                                        {<FormattedMessage id="new" />}
                                    </Button>
                            </Can>
                        </Grid>
                        <Can I="List" a="HirerConfig">
                            <MyMaterialTable
                                title="HirerConfigTable"
                                deleteItem={this.delete}
                                columns={[
                                    { title: "ID", field: "id" },
                                    { title: intl.formatMessage({ id: "hirer.name" }), field: "hirername" },
                                    { title: "Url Api 2DO", field: "urlapicube" },
                                    { title: "Url Api 2SEE", field: "urlapivertex" },
                                    { title: "Url Api 2TALK", field: "urlapicone" },
                                ]}
                                data={this.state.resultList}
                                rowClick={async (event, rowData) => {
                                    if (this.context.can("Edit", "HirerConfig") == true) {
                                        this.getHirerConfig(rowData.id)
                                    } else {
                                        this.setState({
                                            openNotification: true,
                                            notificationVariant: "error",
                                            notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
                                        })
                                    }
                                }}
                            />
                        </Can>
                        <Can I="List" a="HirerConfig">
                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePagination} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            isOpen={this.state.openNotification}
                            message={this.state.notificationMessage}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}

HirersConfigs.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    hirerconfig: state.hirerconfig
})

const mapDispatchToProps = dispatch => bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HirersConfigs)))

HirersConfigs.contextType = AbilityContext