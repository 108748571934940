import Api from "./api";
const url = `/contracts/`

export const getContractsFromHirer = async (id) => {
    let data = []
    await Api.get(url + `hirer/` + id, { hirerIdViewModel: { id: id } })
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response.data
        })
    Api.kickoff(data)
    return data
}

export const getContractById = async (id) => {
    let data = []
    await Api.get(url + `withrelations/` + id)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response.data
        })
    Api.kickoff(data)
    return data
}

export const createContract = async (contract) => {
    let data = []
    await Api.post(url + `withrelations`, contract)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response.data
        })
    Api.kickoff(data)
    return data
}

export const updateContract = async (contract) => {
    let data = []
    await Api.put(url + `withrelations`, contract)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response.data
        })
    Api.kickoff(data)
    return data
}

export const deleteContract = async (contractId) => {
    var data = []
    await Api.delete(url + `${contractId}`)
        .then(result => {
            data = result.data
        })
        .catch((err) => {
            data = err.response.data
            Api.kickoff(err)
        })
    Api.kickoff(data)
    return data
}